.chipsSection{
    width: 100%;
    /* border-width: 2;
    border-style: solid; */
    color: black;
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
    flex-wrap: wrap;
    align-items: flex-start;
}

.chipsSectionInternal{
    width: 96%;
    /* border-width: 2;
    border-style: solid;
    border-color: blue; */
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.chipsCrossSection{
    /* border-width: 2;
    border-style: solid;
    border-color: red; */
    display: flex;
    background-color: #ffde57;
    border-radius: 5px;
}

.chipsCrossSection:hover{
    background-color: #ffaa44;
}

.aChip{
    background-color: #ffde57;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 6px;
    padding-left: 6px;
    border-radius: 10px;
    width: fit-content;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: small;
    display: flex;
    align-items: center;
}

.chipCross{
    margin: 6px;
}

.chipCrossMain{
    margin: 6px;
    /* width: 24px;
    height: 24px; */
}

.tooltip {
    position: relative;
    display: inline-block;
    /* border: 1px dotted black; */
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 60px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 2px 0;
    font-size: 12px;
    opacity: 0.7;
    margin-top: 50px;
  
    /* Position the tooltip */
    position: absolute;
    bottom: -1;
    right: 0;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }