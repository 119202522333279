.searchinputRounded{
    border-radius: 4px !important;
    border: solid 0px #808285 !important;
    background-color: #ffffff;
    width: 209px;
    height: 29px;
    margin-left: 11px !important;
}
.searchinputRounded>div>fieldset.PrivateNotchedOutline-root-18{
    border-style: none !important;
}
.searchinputRounded>div>input{
    height: 15px;
    margin: 0 0 0 -8px;
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.07;
    letter-spacing: normal;
    color: #5a585c;
}