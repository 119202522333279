.deleteAllButton {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding-top: 2px;
    background-color: #ffcc00;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  }