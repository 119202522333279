
    .custominputFields {
        display: flex;
        flex-direction: column;
        width: 176px !important;
        margin-left: 10px !important;
        color: #808285 !important;
    }
    .custominputFields>p{
        width: 254px;
        height: 18px;
        margin: 0 26px 5px 0;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #808285;
    }
    