.salesandfinancesorttext {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  text-transform: capitalize !important;
}

.salesandfinancevalues {
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #808285;
  margin-top: 10px;
}

.collapsedSalesAndFinanceValues {
  min-width: 99px;
  text-align: right;
  font-size: 13px;
  color: #808285;
}

.salesandfinanceValuesWithMargin {
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #808285;
}

.salesandfinancevaluesgreen {
  margin-top: 10px;
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #0d8746;
  margin-top: 10px;
}

.salesandfinancevaluesred {
  margin-top: 10px;
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #e90133;
}

.salesandfinancevaluesblue {
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #006bbd;
  margin-top: 8px;
}

.salesandfinancetotalFinal {
  background-color: #f3f2f3;
  border-radius: 3px;
  min-width: 80px;
  font-size: 13px;
  padding-right: 10px;
  padding-left: 5px;
  margin-left: 7px;
  z-index: 1;
  color: #434244;
}

.salesandfinancetotal {
  background-color: #f3f2f3;
  border-radius: 3px;
  min-width: 80px;
  font-size: 13px;
  padding-right: 10px;
  padding-left: 5px;
  margin-left: 7px;
  z-index: 1;
  color: #808285;
}

.collapseSalesAndFinanceTotal {
  background-color: #f3f2f3;
  width: 89px;
  font-size: 13px;
  color: #808285;
  border-radius: 3px;
  padding-right: 10px;
  margin-left: 2px;
}

.marginOverall {
  margin-left: 70px;
  margin-right: -111px !important;
  display: flex;
  overflow: auto;
  width:898px;
  height: 20px;
}
.acivitymarginOverall{
  margin-left: 98px;
  margin-right: -111px !important;
  display: flex;
  overflow: auto;
  width:898px;
  height: 20px;
}
.readOnlymarginOverall{
  margin-left: 31px;
  margin-right: -172px !important;
  display: flex;
  overflow: auto;
  width: 1000px;
  height: 20px;
}

.noMarginOverall {
  margin-left: 70px;
  margin-right: -111px !important;
  display: flex;
  overflow: auto;
  width:898px;
  height: 20px;
}
.readOnlynoMarginOverall{
  margin-right: -111px !important;
  display: flex;
  overflow: auto;
  width: 850px;
  height: 20px;
  margin-left: 3px;
}

.rowFlex {
  display: flex;
}
.allBurowFlex{
  display: flex;
}

.noMarginOverall::-webkit-scrollbar {
  display: none;
}
.readOnlynoMarginOverall::-webkit-scrollbar {
  display: none;
}

.marginOverall::-webkit-scrollbar {
  display: none;
}
.acivitymarginOverall::-webkit-scrollbar {
  display: none;
}

.readOnlymarginOverall::-webkit-scrollbar {
  display: none;
}

.marginNegative {
  margin-left: -30px;
}

.greencolor {
  color: #0d8746;
}

.redcolor {
  color: #e90133;
}

.salesandfinancevaluesTotal {
  display: flex;
}

.boldStyle {
  font-weight: bold;
}

.salesandfinance_value_total {
  min-width: 90px;
  text-align: right;
  font-size: 13px;
}

.applymargintotal {
  margin-right: 10px;
}

.salesandfinanceValuesMargin {
  margin-top: -36px;
  display: inline-flex;
  width: 900px;
  text-align: right;
  position: absolute;
  height: 115px;
}

.salesandfinanceValuesOverallMargin {
  margin-top: -30px;
  display: inline-flex;
  width: 900px;
  text-align: right;
  position: absolute;
  height: 98px;
}

.collapsedSalesAndFinanceValuesOverallMargin {
  display: inline-flex;
  width: 900px;
  text-align: right;
  position: absolute;
  height: 98px;
  margin-top: 2px;
  margin-left: 41px;
}

.salesandfinanceBelowMargin {
  display: inline-flex;
  width: 900px;
  text-align: right;
}

.collapsedSalesAndFinanceBelowMargin {
  display: inline-flex;
  width: 900px;
  text-align: right;
}

.salesandfinanceBelowOverallMargin {
  display: inline-flex;
  width: 900px;
  text-align: right;
  height: 28px;
}

.salesandfinancesorttextleftMargin {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  margin-left: 10px;
  text-transform: capitalize !important;
}

.salesandfinancesort {
  border-bottom: solid 2px #f3f2f3;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-start;
  position: sticky;
  top: 0;
  z-index: 100;
}

.coreBUsalesandfinancesort{
  border-bottom: solid 2px #f3f2f3;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 100;
}

.alignSalesAndFinance {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding-right: 10px;
  width: 1174px;
}
.alignAllBuSalesAndFinance{
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  /* padding-right: 10px; */
  /* width: 1183px; */
  width: 100%;
}

.salesandfinanceApplyMargin {
  display: flex;
  flex-direction: row;
}

.salesandfinanceMinWidth {
  justify-content: flex-end;
  display: flex;
  width: 84px;
  margin-left: 12px;
  border-radius: 3px;
}
.salesandfinanceAllBuMinWidth{
  justify-content: flex-end;
  display: flex;
  padding-right: 18px;
  width: 84px;
  margin-left: 19px;
  border-radius: 3px;
  padding-right: 8px;
}

.applyMonthMargin {
  display: inline-flex;
  width: 898px;
  overflow: scroll;
  margin-right: 5%;
}

.applyMonthMargin::-webkit-scrollbar {
  height: 10px;
  width: 0px;
}

.applyMonthMargin::-webkit-scrollbar-thumb {
  background: #808285;
  border-radius: 5px;
}

.applySalesAndFinanceMonthMargin {
  display: inline-flex;
  /* width: 85%; */
  width: 850px;
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  overflow: scroll;
}

.applySalesAndFinanceMonthMargin::-webkit-scrollbar {
  height: 10px;
  width: 0px;
}

.applySalesAndFinanceMonthMargin::-webkit-scrollbar-thumb {
  background: #808285;
  border-radius: 5px;
}

.salesandfinanceValuesMargin::-webkit-scrollbar {
  display: none;
}

.salesandfinanceValuesOverallMargin::-webkit-scrollbar {
  display: none;
}

.collapsedSalesAndFinanceValuesOverallMargin::-webkit-scrollbar {
  display: none;
}

.salesandfinanceBelowMargin::-webkit-scrollbar {
  display: none;
}

.salesandfinanceBelowOverallMargin::-webkit-scrollbar {
  display: none;
}

.labelAlignment {
  display: flex;
  width: 14%;
  margin-right: 33px;
}

.salesAndFinanceLabelAlignment {
  display: flex;
  width: 206px;
  margin-left: 17px;
}

.monthYearWidth {
  display: flex;
  min-width: 99px;
  justify-content: flex-end;
}

.headerExpandIcon {
  width: 20px;
}

.salesandfinancedataalignment {
  width: 1065px;
  margin-top: 15px;
  margin-bottom: 30px;
}
.applyFreezMonthColor{
  color:#800080 !important;
}

.width100percent{
  width: 100%;
}