.customIcons {
    justify-content: space-around;
    display: flex;
    padding-top: 10px;
}

.ag-theme-alpine .ag-ltr .ag-cell {
    border-radius: 0px !important;
    text-transform: uppercase !important;
}

.ag-theme-alpine .ag-cell-inline-editing {
    box-shadow: 0 0 0 0 transparent;
}

.ag-input-field-input {
    border: solid 1px;
    padding: 5px;
    border-radius: 0px;
    box-shadow: 0px 0px 0px 0px !important;
    width: 93%;
    height: 32px !important;
    min-height: 32px;
    margin: 5px;
    background-color: #f6f5f6;
}

.ag-theme-alpine .ag-ltr input[class^='ag-']:not([type]), .ag-theme-alpine .ag-ltr input[class^='ag-'][type='text'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='number'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='tel'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='date'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='datetime-local'], .ag-theme-alpine .ag-ltr textarea[class^='ag-'] {
    margin: 3px;
    margin-left: 5px !important;
    text-align: right;
    border: solid 1px lightgray !important;
}

.ag-root-wrapper {
    border: 0px !important;
}

.ag-header {
    height: 40px !important;
    min-height: 40px !important;
    border-bottom: solid 2px !important;
    border-bottom-color: lightgray !important;
}

.ag-header-viewport {
    background-color: white;
}

.ag-pinned-right-header {
    background-color: white;
}

.ag-header-cell {
    padding-bottom: 10px !important;
}

.ag-header-cell-resize {
    /* height: 0% !important */
}

.ag-right-aligned-header .ag-header-label-icon { 
    margin-left: 6px !important;
    margin-right: 0px !important;
}

 .ag-right-aligned-header .ag-header-cell-label { 
    flex-direction: row !important;
} 

.ag-right-aligned-header .ag-header-cell-text{
    flex-grow: 1;
    text-align: right;
}

.ag-header-row:after {
    right: -1px !important;
}

.ag-floating-bottom-viewport {
    background: #ffed9b !important;
}

.ag-floating-bottom {
    background: #ffed9b !important;
}

.ag-row-pinned {
    background: #fff3bc !important;
}

.ag-theme-alpine .ag-cell-inline-editing {
    background: #f6f5f6 !important;
    box-shadow: 0 1px 4px 1px transparent !important;
    border-color: transparent !important;
    border: 0px !important;
    border-radius: 0px !important;
    padding-right: 2px !important;
}

.ag-row-pinned>.ag-cell-inline-editing {
    background: #f6f5f6 !important;
}

.ag-theme-alpine .ag-row {
    border: 1px solid;
    border-color: lightgray;
    font-size: 13px;
}

.ag-icon {
    color: grey;
}

#grid-theme-wrapper.app-theme .ag-theme-alpine .ag-row-selected {
    background-color: #f6f5f6;
}

/* div.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-focus {
    background-color: whitesmoke !important;
} */

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
    color: whitesmoke !important;
    background-color: black;
    width: 13px !important;
    height: 13px !important;
    line-height: 13px !important;
    border: 1px solid darkgray;
}

.ag-theme-alpine .ag-checkbox-input-wrapper {
    background-color: white;
    font-size: 14px;
    width: 14px;
    height: 14px;
    line-height: 14px;
}

.ag-theme-alpine .ag-row-odd {
    background-color: white;
}

.ag-theme-alpine .ag-row-hover {
    background: #f6f5f6;
}

.copyRow {
    background-color: #e2f4ff !important;
}

/* small table row icon hover effect */

/* .ag-pinned-right-cols-container img {
    opacity: .7;
}

.ag-pinned-right-cols-container img:hover {
    opacity: 1;
    cursor: pointer;
} */

/*  move text in date picker up a little */

.MuiFormControl-marginNormal .MuiInputBase-input {
    padding-top: 5px !important;
}

/* add table input focus state */

.ag-theme-alpine input[class^=ag-]:not([type]):focus, .ag-theme-alpine input[class^=ag-][type=date]:focus, .ag-theme-alpine input[class^=ag-][type=datetime-local]:focus, .ag-theme-alpine input[class^=ag-][type=number]:focus, .ag-theme-alpine input[class^=ag-][type=tel]:focus, .ag-theme-alpine input[class^=ag-][type=text]:focus, .ag-theme-alpine textarea[class^=ag-]:focus, .MuiAutocomplete-inputRoot[class*=MuiInput-root] .MuiAutocomplete-input:focus, .ag-cell-focus .MuiFormControl-marginNormal {
    outline: none;
    border-bottom: 1.5px solid #4c97d0 !important;
    /* -webkit-box-shadow: inset 0 1px 5px 4px #e2f4ff !important; */
    /* box-shadow: inset 0 1px 5px 4px #e2f4ff !important; */
    text-transform: uppercase !important;
}

.validRow, .errorCell {
    background-color: transparent !important;
}

.validRow::after, .errorCell::after {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 0;
    border-color: transparent #E90133 transparent transparent;
} 

 .validRow::before, .errorCell::before {
    content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    display: block;
    border: 1.5px solid #E90133;
    border-radius: 3px;
} 

.validRow::after {
    border-right-color: #006bbd;
}

.validRow::before {
    border-color: #006bbd;
} 

/* Add New row (highlight medium blue left border) */

.ag-center-cols-clipper .copyRow::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 7px;
    background-color: #006bbd;
}

.ag-center-cols-clipper .errorRow::before{
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 7px;
    background-color: #eb7000;
}

.ag-center-cols-clipper .errorCellBorder::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 7px;
    background-color: #eb7000;
}

.errorRow {
    background-color: #ffe2c9 !important;
}

div.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height ag-cell-first-right-pinned ag-cell-value ag-cell-focus {
    border-left: 1px solid transparent !important;
}

.ag-theme-alpine .ag-ltr .ag-cell.typehotstyle{
    width: 75px !important;
    height: 36px !important;
    border-radius: 5px !important;
    background-color: #fce3b8 !important;
    margin: 2px 1px 2px 1px;
    padding: -1px 0px 0px 0px;
    text-align: center;
    padding-top: -1px;
    line-height: 32px;
}
.ag-theme-alpine .ag-ltr .ag-cell.typecoldstyle{
    width: 75px !important;
    height: 36px !important;
    border-radius: 5px !important;
    margin: 2px 1px 2px 1px;
    padding: -1px 0px 0px 0px;
    text-align: center;
    padding-top: -1px;
    line-height: 32px;
    background-color: #c7e5f8 !important;
}
.ag-theme-alpine .ag-ltr .ag-cell.blankvaluestyle{
    width: 75px !important;
    height: 36px !important;
    border-radius: 5px !important;
    margin: 2px 1px 2px 1px;
    padding: -1px 0px 0px 0px;
    text-align: center;
    padding-top: -1px;
    line-height: 32px;
    background-color: #80828594 !important;
}
.ag-cell-focus {
    border: 1px solid #006bbd !important;
}

.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input {
    /* border: 1px solid transparent; */
    border-radius: 3px;
    padding-right: 15px !important;
    margin-left: 5px !important;
    width: 98px !important;
    background: white;
    min-width: 90px;
    height: 14px;
    margin-top: 0px !important;
    margin-right: 2px !important;
    color: black !important;
    font-size: 13px !important;
}

/*.ag-theme-alpine .ag-ltr input[class^='ag-']:not([type]), .ag-theme-alpine .ag-ltr input[class^='ag-'][type='text'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='number'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='tel'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='date'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='datetime-local'], .ag-theme-alpine .ag-ltr textarea[class^='ag-'] {
    margin: 5px;
    border: solid 1px lightgray !important;
} */

.ag-theme-alpine input[class^='ag-']:not([type]), .ag-theme-alpine input[class^='ag-'][type='text'], .ag-theme-alpine input[class^='ag-'][type='number'], .ag-theme-alpine input[class^='ag-'][type='tel'], .ag-theme-alpine input[class^='ag-'][type='date'], .ag-theme-alpine input[class^='ag-'][type='datetime-local'], .ag-theme-alpine textarea[class^='ag-'] {
    min-height: 24px;
    border-radius: 3px;
} 

/* div.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-first-right-pinned.ag-cell-value.ag-cell-focus {
    border-left: 10px solid transparent !important;

} */