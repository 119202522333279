.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input {
    border: 1px solid lightgray;
    border-radius: 3px;
    padding-right: 21px !important;
    width: 98px !important;
    background: white;
    min-width: 90px;
    height: 12px;
    margin-top: 1px !important;
    margin-right: 2px !important;
    color: black !important;
    font-size: 13px !important;
}

.MuiAutocomplete-option {
    font-size: 13px !important;
    color: black !important;
    padding: 5px !important;
    text-transform: uppercase !important;
}

.MuiAutocomplete-popupIndicatorOpen {
    padding-left: 8px !important;
    padding-right: 8px !important;
    padding-top: 3px !important;
}

.MuiAutocomplete-popupIndicator {
    padding: 2px;
    margin-right: -2px;
    position: relative;
    display: flex;
}

.revenuedropdown>div.MuiSelect-select.MuiSelect-select{
    border-radius: 4px !important;
    border: solid 1.4px #808285 !important;
    background-color: #ffffff !important;
    width: 100% !important;
}

.revenuemenus>.MuiInputBase-root>.MuiSelect-select{
    text-align: left;
}

.revenuemenus>.MuiInputBase-root>.MuiSelect-selectMenu{
  width: 180px !important;
}
.revenuedesc{
    width: 203px;
    height: 20px;
    margin: 3px 15px 0 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    color: #808285;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .rfvalue{
    width: 203px;
    height: 20px;
    margin: 3px 15px 0 0;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    color: #808285;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .MuiMenu-paper{
    height: 200px;
  }
  .revenueoptions {
    min-height: 34px !important;
    display: block !important;
    text-align: left !important;
}

.revenuemenus>div.MuiInput-underline{
  padding-right: 20px;
  
}