.planinputsheader{
    width: 300px;
    height: 38px;
    /* margin: 30px 400px 30px 28px; */
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: -1px;
    color: #000000;
    /* margin-top: -16px; */
    /* padding-top: 8px; */
}
.planstartrun{
    display: flex;
}
.planinputsmargin{
    /* margin-left: 17px; */
    /* margin-top: 20px; */
    /* border: 1px solid red; */
}
.planflexitems{
    display: flex !important;
    justify-content: space-between;
    margin: 10px;
}
.planstartrun>button.MuiButton-text{
    width: 108px;
    height: 25px;
    margin: 0 2px 0 2px;
    padding: 14px 2px 15px 2px !important;
    border-radius: 4px;
    background-color: #ffcc00;
    font-stretch: normal;
    font-style: normal;
    /* color: #434244; */
    font-size: 14px;
}
.copiedfrom{
    width: 160px;
    height: 38px;
    margin: -4px 0px 0px 0px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.38;
    letter-spacing: -0.44px;
    color: #58595b;
}
.masterplanimg{
    width: 17px;
    height: 22px;
    margin: -6px 3px -6px 7px;
    cursor: pointer;
}
.plandetailcopyimg{
    width: 17px;
    height: 22px;
    margin: 6px 9px 0px 5px;
    cursor: pointer;
}
.plandetailcopyimgdisable{
    width: 17px;
    height: 22px;
    margin: 6px 9px 0px 5px;
    cursor: default;
}
.plandetailmoreimg{
    width: 13px;
    height: 19px;
    margin: 7px 11px 0px 5px;
    cursor: pointer;
}
.runblur{
    filter: blur(0.4px);
}
.removepopup{
    cursor: pointer;
  }
.removetext{
    cursor: pointer;
}
.flexContainer>ul{
    width: 100%;
    list-style-type: none;
}
.flexContainer>ul>li{
    width: 100%;
    /* border: 1px solid black; */
    /* background: #ffcc00; */
    padding:2px;
    padding-left:5px;
    border-radius: 5px;
    margin-bottom: 3px;
}
.flexContainer>ul>li:hover{
    width: 100%;
    /* border: 1px solid black; */
    /* background: #000000;
    color: #ffffff; */
    padding:2px;
    padding-left:5px;
    border-radius: 5px;
    margin-bottom: 3px;
}
.flexContainer>ul>li>span{
    display: inline-block;
    width: 90%;
    text-align: left;
}
.flexContainer>ul>li>input{
    text-align: right;
}
.flexContainer>ul>li>ul>li{
    width: 90%;
    margin-bottom: 3px;
    /* border: 1px solid red; */
}
.flexContainer>ul>li>ul>li>span{
    display: inline-block;
    width: 60%;
    text-align: left;
}
.flexContainer>ul>li>ul>li>input{
    text-align: right;
}

.flexContainer_ul_li_normal{
    background: #ffcc00;
    color: #000000;
    border-radius: 5px;
}

.flexContainer_ul_li_normal:hover{
    background: #000000;
    color: #ffffff;
    border-radius: 5px;
}

.flexContainer_ul_li_disabled{
    background: #f1ecd8;
    color: #000000;
    border-radius: 5px;
}

.flexContainer_ul_li_disabled:hover{
    background: #f1ecd8;
    color: #000000;
    border-radius: 5px;
}

/* *..* */
.navList {
    /* min-width: 90px; */
    /* width: 8%; */
}
.routeDimensions {
    /* width: initial !important;
    min-width: 90%; */
}
.planinputsmargin {
    margin-left: 5px;
}
.allplansmargin {
    margin-left: 0px;
}
.planinputsmargin {
    height: 100%;
    /* min-height: 1150px; */
    /* max-height: 1150px; */
}

/* @media screen and (max-width: 2600px) {
    .planinputsmargin  #grid-theme-wrapper>div>div{
        height: 900px !important;
    }
    .planinputsmargin .planassumptionbgcolor {
            height: 1100px !important;
    } 
    .planinputsmargin {
            min-height: 1450px;
            max-height: 1450px;
    } 
}
@media screen and (max-width: 1500px) {
    .planinputsmargin  #grid-theme-wrapper>div>div{
        height: 800px !important;
    }
    .planinputsmargin #grid-theme-wrapper>div>div>div {
        height: 800px !important;
    }
    .planinputsmargin .planassumptionbgcolor {
        height: 1000px !important;
    }
    .planinputsmargin {
        min-height: 1400px;
        max-height: 1400px;
        height: 700px;
    }

} */

.planinputsmargin #grid-theme-wrapper>div>div {
    height: 90% !important;
    /* border: 1px solid red; */
}
.planinputsmargin #grid-theme-wrapper>div>div>div {
    height: 100% !important;
}