.applygreybackgroundforSF {
    background-color: #f3f2f3;
    /* width: 70px;
    margin-left: 6px; */
    border-radius: 3px;
    padding-right: 3px;
    display: flex;
    flex-direction: column;
    width: 99px;
    min-width: 99px;
    /* padding-left: 19px; */
}

.cellComponentForSF {
    display: flex;
    flex-direction: column;
    width: 99px;
    min-width: 99px;
    padding-right: 3px;
}

.rowStyle {
    display: flex;
    /* height: 110px; */
}
.filteredRecord{
    background-color:#ffffe0;
}
.selectedForFilter{
    background-color:#d0f3fc;
    font-weight: bold;
}