.newrangeinput{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.newrangemargin{
    margin-left: 8px;
}
.newandtext{
    height: 19.5px;
    margin: 8px 11px 8.5px 6px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #434244;
}
.newrangeinput>div+div>div.MuiOutlinedInput-root{
    width: 170px !important;
    margin-top: 0px !important;
}
.newrangeinput>span.displayflx{
    display: flex;
}
.newrangeinput>div+div>div>input.MuiInputBase-input{
    padding: 8px 2px 8px 3px !important;
    text-align: left;
}
.displayflx>label+div>div.MuiOutlinedInput-root{
    width: 170px !important;
    margin-top: 0px !important;
}
.displayflx>label+div>div>input.MuiInputBase-input{
    padding: 8px 2px 8px 3px !important;
    text-align: left;
}
.newdropdownFilter>p{
    width: 254px;
    height: 18px;
    margin: 0 26px 5px 0;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #808285;
}