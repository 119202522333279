.salesfinheader{
    width: 300px;
    height: 38px;
    /* margin: 30px 555px 30px 28px; */
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: -1px;
    color: #000000;
    /* margin-top: -16px; */
    padding-top: 8px;
    margin-left: 24px;
}
.salesfinrun{
    display: flex;
}
.salesfinmargin{
    /* margin-left: 17px; */
    margin-top: 20px;
}
.salesfinflexitems{
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    /* margin: 10px; */
}
.runblur{
    filter: blur(0.4px);
}
.width100percent{
    width: 100%;
}
.adjustmentrun{
    display: flex;
}
.adjustmentmargin{
    margin-left: 17px;
    margin-top: 20px;
}
.adjustmentflexitems{
    display: flex !important;
    justify-content: space-between;
    margin: 10px;
}
.adjustmentrun>button.MuiButton-text{
    width: 180px;
    height: 25px;
    margin: 0 22px 0 0px;
    padding: 14px 2px 15px 2px !important;
    border-radius: 4px;
    background-color: #ffcc00;
    font-stretch: normal;
    font-style: normal;
    /* color: #434244; */
    font-size: 13px;
}
.runblur{
    filter: blur(0.4px);
}
.adjustmentheader{
    width: 350px;
    height: 38px;
    margin: 30px 200px 30px 28px;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: -1px;
    color: #000000;
    margin-top: -16px;
    padding-top: 8px;
}
/* // */

/* .mainSalesfinHeader, .alignSalesfins {
    width: auto !important; 
}
.salesfinmargin {
    margin-left: 0px;
}
.salesfinrun {
    width: 25%;
}
.salesfinsFilterStyle {
    min-width: 85%;
    justify-content: flex-start;
}
.AllBumainSalesfinHeader {
    width: 92%;
}
.alignAllBuSalesfins {
    width: 91%;
    padding-right: 12px;
}

@media screen and (max-width: 1500px) {
    .salesfinmargin {
        height: 750px;
    }
} */