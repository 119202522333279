.indent {
  margin-left: 1.75em;
  margin-bottom: 4px;
}
.accordions{
  min-width: 1045px;
}
.no-padding {
  padding: 0px !important;
  margin: 0px !important;
}
.accordionview{
  border-radius: 4px;
  box-shadow: 1px 1px 2px 0 rgba(67, 66, 68, 0.15);
  background-color: #ffffff;
}
.contenticons{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: solid 2px #f3f2f3 !important;
    
}
.contenticons>span:nth-child(2)>img:nth-child(1){
  width: 19px;
  height: 20px;
  margin: 14px 0 19px 129px;
  object-fit: contain;
}
.heading{
  width: 100%;
  height: 16px;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  color: #434244;
}
.headerstyle{
  border-bottom: solid 2px #f3f2f3;
  width: 100%;
  padding-bottom: 4px;
}
.MuiCollapse-container .heading {
  font-weight: normal;
}

.accordions.MuiAccordionSummary-root{
  min-height: 0px !important;
}
.MuiAccordionSummary-root{
  min-height: 0px !important;
  padding: 0 12px 0 7px !important;
}
.MuiAccordion-rounded{
  border-radius: 0px !important;
}
.ui.accordion .title .dropdown.icon:before, .ui.accordion .accordion .title .dropdown.icon:before{
 content:url("../../assets/images/controls-expand-down-arrow.svg") !important;
 float: left !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.MuiAccordionSummary-content{
  order: 2;
  margin: 6px 11px !important;
}
.accordiondetails.MuiAccordionDetails-root{
  display: block !important;
  padding: 0px 20px 1px !important;
}

.modalgroup{
  margin: 4px 10px 8px 0;
  border-radius: 2px;
  background-color: #f3f2f3;
  padding: 8px 8px;
}
.modalatext{
  display: flex;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  margin: 0 0 5px;
  line-height: 1.08;
}
.carcode{
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #434244;
  margin: 0 0 5px;
  line-height: 1.08;
  
}
.contentpanel{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  width: 82%;
}
.constraintcontentpanel{
  position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row !important;
    font-size: 12px;
    min-width: 90%;
}
.datecontent{
  font-size: 12px;
  margin: 0 8px 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #434244;
}
.datetext{
  display: flex;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #434244;
  text-transform: uppercase;
}
.zone{
  display: flex;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #434244;
  text-transform: capitalize;
}
.divideline{
  border-right:solid 1px #e5e5e5;
  height: 30px;
  margin-left: 10px;
}
.volumestyle{
  margin-left: 12px;
}
.volume{
  display: flex;
}
.volumetext{
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
}
.mileage{
  margin: 0 0px 1px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #434244;
  line-height: 1.6;
}
.manufacturericon{
  height: 14px;
  margin: 3px 3px -1px 0px;
  object-fit: contain;
}
.modalname{
  margin: 1px 0 1px -1px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #434244;
}
.programtype{
  width: 33px;
  height: 14px;
  margin: 2px 0 0 8px;
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 0.93;
  letter-spacing: normal;
  color: #434244;
  text-transform: uppercase;
}
.spicode{
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 0.93;
  letter-spacing: normal;
  color: #434244;
}
.accordionsort{
  border-bottom: solid 2px #f3f2f3;
  background-color: #ffffff;
  padding: 0px 664px 4px 29px;
}
.sorttext{
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: normal;
  color: #000000;
}
.sorticon{
  width: 9px;
  height: 13px !important;
  margin: 3px 0 -3px 10px;
  object-fit: contain;
}

/* constraintcontent */
.constraintpanel{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 12px;
  width: 100%;
  padding-left: 9px;
  margin-top: 7px;
}
.contentdatefield{
  height: 13px;
  margin: 3px 44px 4px -66px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #434244;
}
.pooltext{
  min-width: 19%;
  width: 18%;
  height: 13px;
  margin: 3px 1px 4px 19px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #434244;
  text-align: left;
}
.minval{
  min-width: 66px;
  height: 13px;
  margin: 3px 27px 4px -7px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #434244;
  text-align: right;
}
.maxvalue{
  min-width: 66px;
  height: 13px;
  margin: 3px 43px 4px -7px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #434244;
  text-align: right;
}
.attributes{
  min-width: 250px;
  margin-top: -2px;
  text-align: left;
}
.programattribute{
  min-width: 54px;
  height: 15px;
  padding: 4px 6px 8px;
  opacity: 0.7;
  border-radius: 12px;
  background-color: rgba(187, 226, 250, 0.7);
  text-align: center;
  margin: -3px 0 -22px 15px;
}
.blnkprogramattribute{
  min-width: 54px;
  height: 15px;
  padding: 4px 6px 8px;
  text-align: center;
  margin: -3px 0 -22px 0;
}
.spittribute{
  min-width: 54px;
  height: 15px;
  padding: 4px 6px 8px;
  opacity: 0.7;
  border-radius: 12px;
  background-color: rgba(187, 226, 250, 0.7);
  text-align: center;
  margin: -3px 0 -22px 7px;
}
.blnkspittribute{
  min-width: 54px;
  height: 15px;
  padding: 4px 6px 8px;
  text-align: center;
  margin: -3px 0 -22px 7px;
}
.manattribute{
  min-width: 54px;
  height: 15px;
  padding: 4px 6px 8px;
  opacity: 0.7;
  border-radius: 12px;
  background-color: rgba(187, 226, 250, 0.7);
  text-align: center;
  margin: -3px 0 -22px 7px;
}
.nomanattributeattribut{
  min-width: 54px;
  height: 15px;
  padding: 4px 6px 8px;
  text-align: center;
  margin: -3px 0 -22px 7px;
}
.milesattribute{
  min-width: 54px;
  height: 15px;
  padding: 4px 6px 8px;
  opacity: 0.7;
  border-radius: 12px;
  background-color: rgba(187, 226, 250, 0.7);
  text-align: center;
  margin: -3px 0 -22px 7px;
}
.nomilesattribute{
  min-width: 54px;
  height: 15px;
  padding: 4px 6px 8px;
  text-align: center;
  margin: -3px 0 -22px 7px;
}
.constraintcontents{
  height: 37px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 2px #f3f2f3 !important;
}
.constraintdatate{
  min-width: 120px;
  text-align: right;
}

.att{
  margin-right: -35%;
  display: flex;
}
.constraintdelete{
  margin-left: 6%;
}

.constraintedit{
  margin-left: 5%;
}

.minFieldUI {
  width : 30% !important;
  min-width: 65px !important;
  height: 25px !important;
  min-height: 25px !important;
  margin-top: -7px !important;
}

.minFieldUI .MuiInputBase-input, .minFieldUISuccess .MuiInputBase-input, .minFieldUIError .MuiInputBase-input {
  padding: 8px 8px 8px 12px !important;
  font-size: 13px !important;
  margin-top: -4px;
}

.constraintcontentpanel>div>div.MuiFormControl-root {
  width: 77px !important;
}

.minFieldUI .MuiOutlinedInput-input, .minFieldUISuccess .MuiOutlinedInput-input, .minFieldUIError .MuiOutlinedInput-input {
  /* padding: 18.5px 14px; */
}

.minFieldUISuccess::before, .minFieldUIError::before {
  content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    display: block;
    border: 1.5px solid #E90133;
    border-radius: 3px;
    width: 30% !important;
    min-width: 65px !important;
    height: 24px !important;
    min-height: 24px !important;
    margin-top: -4px !important;
    margin-left: -4px;
    z-index: 1;
}

.minFieldUISuccess::after, .minFieldUIError::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 12px 0;
  border-color: transparent #E90133 transparent transparent;
  z-index: 1;
} 

.minFieldUISuccess, .minFieldUIError {
  background-color: transparent !important;
  min-width: 65px !important;
  width: 30% !important;
  height: 25px !important;
  min-height: 25px !important;
  border-color: transparent !important;
  border: 0 !important;
  margin-top: -7px !important;
}

.minFieldUISuccess::after {
  border-right-color: #006bbd;
}

.minFieldUISuccess::before {
  border-color: #006bbd;
} 

.cargroupstyle{
  width: 110px;
  height: 13px;
  margin: 1.5px 0 1.5px 23.5px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #808285;
  text-transform: capitalize !important;
}
.smooth-goto { 
  scroll-behavior: smooth; 
} 
.typeicons{
  display: flex;
}
.typeiconstyle{
  height: 14px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  color: #434244;
  margin: 1px 6px 1px 1px;
}

.MuiAccordion-root:after {
  position: absolute;
  content: "";
  top: 34px;
  left: 26px;
  background-color: #cccdd0;
  width: 2px;
  bottom: 10px;
}
.MuiAccordionSummary-expandIcon .MuiIconButton-label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;    
  background-color: #dfdfe1;
  border-radius: 4px;
  width: 16px;
  height: 16px;    
}
.MuiAccordionSummary-expandIcon .MuiSvgIcon-root {
  padding-top: 0px!important;
  margin-top: 0!important;
  padding-right: 0px;
}
