.flexContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
  }

.filterMain {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 24px;
}

.filterMainForRange {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 24px;
    width: 100%;
}

.filterMargin {
    margin:-1px 0px 0px 10px;
  }