.ticker{
    padding-left: 10px;
    font-weight: bold;
    color: black;
    /* border: 1px solid black; */
}
.ticker_inverted{
    padding-left: 10px;
    font-weight: bold;
    color:#ffcc00
    /* border: 1px solid black; */
}