.toplevelfilteroptionscontainer{
    background-color: white;
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
  }
 .toplevelfilteroptionscontainer .MuiButtonBase-root{
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #434244;
    font-family: customFontFamily !important;
    height: 28px;
    align-items: center;
 }
 
 .inactiveButton{
    background-color: transparent !important;
 }
 .activeButton{
    color: #000000 !important;
    border-radius: 4px !important;
    background-color: #ffed9b !important;
 }

 .toplevelfilteroptionscontainer .MuiButtonBase-root>.MuiButton-label span{
   text-transform: uppercase;
    padding-right: 6px;
    padding-left: 6px;
 }
  