.newSearchInput{
    border-radius: 4px !important;
    border: solid 0px #808285 !important;
    background-color: #ffffff;
    width: 209px;
    margin-left: 10px !important;
    height: 29px;
}
.newSearchInput>div>fieldset.PrivateNotchedOutline-root-18{
    border-style: none !important;
}
.newSearchInput>div>input[aria-invalid='true']~fieldset.MuiOutlinedInput-notchedOutline{
    border-radius: 4px !important;
    border-style: solid  !important;
    border-color: red !important;
}
.newSearchInput>div>input{
    height: 15px;
    margin: 0 0 0 -8px;
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.07;
    letter-spacing: normal;
    color: #5a585c;
}

.newSearchInput>.MuiFormHelperText-contained{
    display: none;
}
.newSearchInput>div:has(input[aria-invalid='true'])~.MuiFormHelperText-contained{
    display:inline-block;
    color: red;
    width: max-content;
}

.MuiOutlinedInput-input {
    /* padding: 18.5px 14px; !important */
  }