.bg-blue-300 {
  background-color: #93c5fd;
}
.bg-purple-300 {
  background-color: #c4b5fd;
}
.bg-pink-300 {
  background-color: #f9a8d4;
}
.bg-transparent {
  background-color: transparent;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.inline-flex {
  display: inline-flex;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-auto {
  flex: 1 1 auto;
}
.flex-initial {
  flex: 0 1 auto;
}
.flex-none {
  flex: none;
}
.fixed {
  position: fixed;
}
.p-0 {
  padding: 0px;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.pr-0 {
  padding-right: 0px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pl-0 {
  padding-left: 0px;
}
.p-3 {
  padding: 12px;
}
.px-3 {
  padding-left: 12px;
  padding-right: 12px;
}
.py-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.pt-3 {
  padding-top: 12px;
}
.pr-3 {
  padding-right: 12px;
}
.pb-3 {
  padding-bottom: 12px;
}
.pl-3 {
  padding-left: 12px;
}
.ml-15 {
  margin-left: 15px;
}
.-mr-10 {
  margin-right: -25px;
}
.mr-25 {
  margin-right: 10px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-90 {
  margin-right: 95%;
}
.ml-14 {
  margin-left: 9%;
}
.ml-144 {
  margin-left: 110px;
}
.mr-144 {
  margin-right: 80px;
}
.m-0 {
  margin: 0px;
}
.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}
.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.mr-0 {
  margin-right: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.ml-0 {
  margin-left: 0px;
}
.my-6 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.my-9 {
  margin-top: 27px;
  margin-bottom: 27px;
}
.m-3 {
  margin: 12px;
}
.mx-3 {
  margin-left: 12px;
  margin-right: 12px;
}
.my-3 {
  margin-top: 12px;
  margin-bottom: 12px;
}
.mt-1 {
  margin-top: 6px;
}
.mt-3 {
  margin-top: 12px;
}
.mr-3 {
  margin-right: 12px;
}
.mb-3 {
  margin-bottom: 12px;
}
.ml-1 {
  margin-left: 6px;
}
.ml-3 {
  margin-left: 12px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-155 {
    margin-left: 110px;
  }
.mt-20 {
  margin-top: 65px;
}
.w-full {
  width: 100%;
}
.mr-100 {
  margin-right: 100px;
}
.w-screen {
  width: 100vw;
}
.w-min {
  width: min-content;
}
.w-max {
  width: max-content;
}
.w-fit {
  width: fit-content;
}
.w-1104 {
  width: 1104px;
}
.w-10 {
  width: 12%;
}
.w-85 {
  width: 92%;
}
.w-10 {
  width: 10%;
}
.h-10 {
  height: 10%;
}
.h-85 {
  height: 85%;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}
.h-min {
  height: min-content;
}
.h-max {
  height: max-content;
}
.h-fit {
  height: fit-content;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-clip {
  overflow: clip;
}
.overflow-visible {
  overflow: visible;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-x-clip {
  overflow-x: clip;
}
.overflow-y-clip {
  overflow-y: clip;
}
.overflow-x-visible {
  overflow-x: visible;
}
.overflow-y-visible {
  overflow-y: visible;
}
.overflow-x-scroll {
  overflow-x: scroll;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.overscroll-auto {
  overscroll-behavior: auto;
}
.overscroll-y-auto {
  overscroll-behavior-y: auto;
}
.overscroll-x-auto {
  overscroll-behavior-x: auto;
}
.overscroll-contain {
  overscroll-behavior: contain;
}
.overscroll-y-contain {
  overscroll-behavior-y: contain;
}
.overscroll-x-contain {
  overscroll-behavior-x: contain;
}
.overscroll-none {
  overscroll-behavior: none;
}
.overscroll-y-none {
  overscroll-behavior-y: none;
}
.overscroll-x-none {
  overscroll-behavior-x: none;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.text-xs {
  font-size: 12px;
}
.text-sm {
  font-size: 14px;
}
