.mainpanel {
  background-color:#f3f2f3;
  display:flex
}
.navList {
  list-style-type: none;
  display: flex;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  height:100%;
  justify-content: center;
}

.navList-sidebarminimized {
  list-style-type: none;
  display: flex;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  height:100%;
  justify-content: center;
}

a:-webkit-any-link{
  text-decoration: none;
  color: #434244;
  padding-bottom: 7px;
  padding-top: 7px;
  display: block;
}

.liststyle{
  margin-bottom: 25%;
  text-align: center;
  width: 100%;
  align-items: center;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.navlabel {
  text-decoration:none;
  display: block;
  height: 16px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #434244;
}

.icon {
  height: 20px;
  margin: 0 26px 5px 25px;
  padding: 0 1px 1px;
}

.icon-sidebarminimized {
  height: 20px;
  margin: 0 5px 5px 5px;
  padding: 0 1px 1px;
}

.active {
  background-color: #dfdfe1;
  border-radius: 4px;
  display: block;
}
.activeselected {
  background-color: #dfdfe1;
  border-radius: 4px;
  display: block;
}
.navList>div{
  width: 90px;
}

.navList-sidebarminimized>div{
  width: 40px;
}

.innerDiv{
  width: 100%;
}
.sidebar{
  display: flex;
  align-items: flex-start;
}