.saveConstraints {
    display: flex;
    justify-content: flex-end;
    width: 10% !important;
}

.saveButtonStyle {
    min-width: -3%;
    margin-right: 10px;
    border: solid 1px #ffcc00;
    border-radius: 5px;
    height: 25px;
    display: flex;
    flex-wrap: wrap;
    margin-top: -4px;
    text-align: left;
    font-size: 12px;
    background-color: #ffcc00;
    box-shadow: 0 0 50px 0 rgb(0 0 0 / 10%);
}


.cancelButtonStyle {
    border: solid 1px #808285;
    border-radius: 5px;
    height: 25px;
    width: 60px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin-top: -4px;
}

/* .customTooltip > .tooltip {
    background-color: '#cccdd00' !important;
    font-size: 12px !important;
    color: #808285 !important;
} */

.imgOpacity {
    opacity: 0.5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  text-align: right;
  padding-right: 5px;
}

.minMaxFields {
    min-width: 177px;
    display: flex;
    width: 160px;
}