.adjustmentinputfield>div>div>div>fieldset.MuiOutlinedInput-notchedOutline{
    border: none !important;
}
.adjustmentinputfield>div>div>div>input{
  color: #808285;
  border-radius: 3px !important;
  border: solid 1.5px #cccdd0 !important;
  background-color: #fff !important;
  text-align: right !important;
  padding: 6px 0 6px 0 !important;
  margin: 0 -10px 0 10px !important;
  /* overriding default padding */
}
.adjustmentinputdisablefield>div>div>div>input{
    color: #800080;
    border: none !important;
  /* border-radius: 3px !important;
  border: solid 1.5px #cccdd0 !important; */
  /* background-color:#f3f2f3 !important; */
  text-align: right !important;
  padding: 6px 0 6px 0 !important;
  margin: 0 -12px 0 11px;
  }
.editablefield{
    margin: 0px 0px -3px 16px;
    font-size: 13px;
    color: #808285;
}
.adjustmentinputfield>div>div>div{
    min-width: 10px !important;
}
.errorMsgStyle>div>div>div>input{
  color: #808285;
  border-radius: 3px !important;
  border: solid 1.5px #E90133 !important;
  background-color: #fff !important;
  text-align: right !important;
  padding: 6px 0 6px 0 !important;
  /* overriding default padding */
}
.errorMsgStyle>div>div>div::after{    
    content: "";
    position: absolute;
    top:1px;
    right: 1px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 0;
    border-color: transparent #E90133 transparent transparent;
}
.errorMsgStyle>div>div>div::before{    
    content: "";
    position: absolute;
    top:1px;
    right: 1px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 0;
    border-color: transparent #E90133 transparent transparent;
}
.errorMsgStyle>div>div>div>fieldset.MuiOutlinedInput-notchedOutline{
    border: none !important;
}
.errorMsgStyle>div>div>div{
    min-width: 88px !important;
}