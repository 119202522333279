.plandetailsminimized{
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: fit-content;
    min-width: 20%;
    max-width: 50%;
    background-color: #ffffff;
    border: 2px solid #c2bdc2;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1rem;
}
.plandetailsminimized_withcopiedfrom{
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
  min-width: 20%;
  max-width: 30%;
  background-color: #ffffff;
  border: 2px solid #c2bdc2;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1rem;
}
.descriptionelements{
    display: block;
    flex-wrap: wrap;
    overflow: hidden;
    max-height: 46px;
}
.rfelements{
  display: block;
  flex-wrap: wrap;
  overflow: hidden;
  height: 23px;
}

@media screen and (min-width: 1900px) {
  .plandetailsminimized{
    min-width: 25%;
    max-width: 65%;
  }
  .plandetailsminimized_withcopiedfrom{
    min-width: 25%;
    max-width: 57%;
  }
}