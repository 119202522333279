.tablecontainer {
  width: 1102px;
  height: auto;
  margin: 1px 31px 49px 27px;
  padding: 0 0 0px;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 0 rgb(67 66 68 / 15%) !important;
  background-color: #ffffff;
}

td.MuiTableCell-root.MuiTableCell-body.planidlink {
  width: 43px;
  height: 16px;
  margin: 4px 26px 13px 19px;
  font-family: customFontFamily !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.14 !important;
  letter-spacing: normal !important;
}

td.MuiTableCell-root.MuiTableCell-body.planidlink {
  cursor: pointer;
  color: #006bbd !important;
}

.cursorpoint {
  cursor: pointer;
}

thead.planshead > tr > th.numbertyle {
  height: 14px !important;
  margin: 0 293px 16px 56px !important;
  font-family: customFontFamily !important;
  font-size: 14px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  text-align: right !important;
  padding: 12px 76px 12px 4px !important;
}

thead.planshead > tr > th.textstyle {
  text-align: left !important;
}

thead.planshead > tr > th.desctextstyle {
  text-align: left !important;
  padding-left: 7px;
}

thead.planshead > tr > th.initaltextstyle {
  padding-left: 29px;
}

thead.planshead > tr > th.planidlink {
  height: 14px !important;
  margin: 0 293px 16px 56px !important;
  font-family: customFontFamily !important;
  font-size: 14px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  padding: 12px 9px 12px 34px !important;
}

thead.planshead > tr > th.MuiTableCell-head {
  line-height: 0.88 !important;
  font-family: customFontFamilyRegular !important;
}

tbody.plansbody > tr > td.MuiTableCell-body {
  /* color: #434244 !important; */
  font-family: customFontFamily !important;
}

tbody.plansbody > tr > td:nth-child(2).MuiTableCell-root {
  width: 176px;
  height: 16px;
  margin: 3px 198px 14px 26px;
  padding: 7px !important;
  font-family: customFontFamily !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
}

.new {
  width: 84px;
  height: 20px;
  padding: 3px 9.9px 5px 10px;
  border-radius: 12px;
  background-color: #ffcc00;
}

.ready {
  width: 84px;
  height: 20px;
  padding: 3px 9.9px 5px 10px;
  border-radius: 12px;
  background-color: #ebe6e7;
}

.run {
  width: 74px;
  height: 20px;
  padding: 2px 8px 6px 10px;
  border-radius: 12px;
  background-color: #ece4f4;
}

.complete {
  width: 93px;
  height: 20px;
  padding: 3px 9px 5px 10px;
  border-radius: 14px;
  background-color: #e5fdf1;
}

.fail {
  width: 60px;
  height: 20px;
  padding: 3px 9px 5px 10px;
  border-radius: 14px;
  background-color: #fee6d8;
}

.publish {
  width: 84px;
  height: 20px;
  padding: 3px 9.9px 5px 10px;
  border-radius: 12px;
  background-color: #e2f4ff;
}

.planspopover > div:nth-child(3).MuiPaper-rounded {
  border-radius: 5px !important;
}

.planspopover > div:nth-child(3).MuiPaper-elevation8 {
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1) !important;
}

.planspopover > div:nth-child(3).MuiPaper-root {
  background-color: #ffffff !important;
}

.planspopover > div:nth-child(3).MuiPopover-paper {
  /* margin: 1px 4px 7px -28px !important;*/
  padding: 0px 10px 0px 10px !important;
}

.showmorepopup {
  display: flex !important;
  padding: 0px 5px 0px 5px !important;
  width: 14px;
  margin: 2px 4px 2px 4px;
  object-fit: contain;
}

.showmoretext {
  width: 88px;
  height: 16px;
  margin: 4px -27px 4px 0px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #434244;
}

tbody.plansbody > tr > td.rownumstyle {
  text-align: right !important;
  padding: 7px 76px 7px 4px !important;
}

tbody.plansbody > tr > td.rowtextstyle {
  color: #000;
  text-align: left !important;
}

tbody.plansbody > tr > td.rowtextstyle-scenario-name {
  text-align: left !important;
}

.textcapital {
  text-transform: capitalize;
  min-width: 203px;
}

.planmoreicon {
  cursor: pointer;
}

.noplansrow {
  text-align: center;
  font-size: 14px;
  margin: 41px 1px 41px 1px;
}
.lockedcopyPopOver > div.MuiPopover-paper {
  border-top-right-radius: 0px !important;
  box-shadow: -5px 10px 20px 0px rgba(0, 0, 0, 0) !important;
  background-color: #ffffff !important;
  overflow-y: hidden !important;
  border: 2px #000000 !important;
}
.adjustmentcomplete {
  height: 20px;
  padding: 4px 7px 4px 7px;
  border-radius: 14px;
  background-color: #f0fde5;
}
.completeOptimal {
  width: 93px;
  height: 20px;
  padding: 3px 9px 5px 10px;
  border-radius: 14px;
  background-color: #e5fdf1;
}
.runSupOptm {
  width: 74px;
  height: 20px;
  padding: 2px 8px 6px 10px;
  border-radius: 12px;
  background-color: #ff980e73;
}
.runUnknown {
  width: 74px;
  height: 20px;
  padding: 2px 8px 6px 10px;
  border-radius: 12px;
  background-color:  #eb200b54;
}
.EditingAdjustments{
  width: 74px;
  height: 20px;
  padding: 2px 8px 6px 10px;
  border-radius: 12px;
  background-color:  #e0908854;
}
.AdjustmentsModified{
  width: 74px;
  height: 20px;
  padding: 2px 8px 6px 10px;
  border-radius: 12px;
  background-color:  #8d8bd054;
}