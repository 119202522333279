
.margin {
    margin-left: 5px;
    /* margin-top: 20px; */
    /* margin-right: 40px; */
    /* margin-bottom: 35px; */
    height: 100%;
  }
  .marginleft{
    margin-left: -28px;
    margin-top: 10px;
    /* margin-bottom: 35px; */
    width: 103%;
  }
  .MuiTab-textColorInherit {
  opacity: 1 !important;
  }
  
  .MuiTab-root {
  min-width: 90px !important;
    min-height: 22px !important;
  font-family: Ride;
  }
  
  .MuiTabs-fixed {
  margin-left: 19px;
  }
  
  .MuiTabs-flexContainer {
    display: flex;
    flex-wrap: wrap;
  }
  .headertext{
  width: 193px;
  height: 38px;
  margin: 0 0 0 0;
  margin-left: 25px;
  padding-top: 15px;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: -1px;
  color: #000000;
  /* margin-top: -16px; */
  /* padding-top: 8px; */
  }
  
  .upgradeButtons{
    background-color: white;
    padding-top: 12px;
    display: flex;
    flex-wrap: wrap;
    width: 96.5%;
    position: relative;
    bottom: 12px;
    left: 24px;  
  }
  .upgradebtnstyle{
    height: 30px !important;
    width: 274px;
    margin-left: 11px;
  }
  .upgradebtnstyle>button.MuiButton-root.btnactive{
    border-radius: 4px !important;
    background-color: #ffed9b !important;
  }
  .upgradebtnstyle>button.MuiButton-root:hover{
    background-color:#ffffff !important;
  }
  .upgradebtnstyle>button.MuiButton-root.btnactive:hover{
    background-color: #ffed9b !important;
  }
  .upgradebtnstyle>button>span.MuiButton-label{
    width: 172px;
    height: 27px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  
  
  @keyframes copyinprogress {
    0%    {background-image: linear-gradient(to right, gold ,gold ,black,black,black,black,black,black,black,black,black)}
    10%   {background-image: linear-gradient(to right, black,gold ,gold ,black,black,black,black,black,black,black,black)}
    20%   {background-image: linear-gradient(to right, black,black,gold ,gold ,black,black,black,black,black,black,black)}
    30%   {background-image: linear-gradient(to right, black,black,black,gold ,gold ,black,black,black,black,black,black)}
    40%   {background-image: linear-gradient(to right, black,black,black,black,gold ,gold ,black,black,black,black,black)}
    50%   {background-image: linear-gradient(to right, black,black,black,black,black,gold ,gold ,black,black,black,black)}
    60%   {background-image: linear-gradient(to right, black,black,black,black,black,black,gold ,gold ,black,black,black)}
    70%   {background-image: linear-gradient(to right, black,black,black,black,black,black,black,gold ,gold ,black,black)}
    80%   {background-image: linear-gradient(to right, black,black,black,black,black,black,black,black,gold ,gold ,black)}
    90%   {background-image: linear-gradient(to right, black,black,black,black,black,black,black,black,black,gold ,gold )}
    100%  {background-image: linear-gradient(to right, black,black,black,black,black,black,black,black,black,black,gold )}
  }
  @keyframes copyinprogress_active {
    0%    {background-image: linear-gradient(to right, gold ,gold ,white,white,white,white,white,white,white,white,white)}
    10%   {background-image: linear-gradient(to right, white,gold ,gold ,white,white,white,white,white,white,white,white)}
    20%   {background-image: linear-gradient(to right, white,white,gold ,gold ,white,white,white,white,white,white,white)}
    30%   {background-image: linear-gradient(to right, white,white,white,gold ,gold ,white,white,white,white,white,white)}
    40%   {background-image: linear-gradient(to right, white,white,white,white,gold ,gold ,white,white,white,white,white)}
    50%   {background-image: linear-gradient(to right, white,white,white,white,white,gold ,gold ,white,white,white,white)}
    60%   {background-image: linear-gradient(to right, white,white,white,white,white,white,gold ,gold ,white,white,white)}
    70%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,gold ,gold ,white,white)}
    80%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,gold ,gold ,white)}
    90%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,white,gold ,gold )}
    100%  {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,white,white,gold )}
  }
  
  .margin #grid-theme-wrapper>div>div {
    /* height: 90% !important; */
    /* border: 1px solid red; */
  }
  .margin #grid-theme-wrapper>div>div>div {
    /* height: 100% !important; */
  }
  
  .margin #grid-theme-wrapper>div>div {
    height: 90% !important;
    /* border: 1px solid red; */
  }
  .margin #grid-theme-wrapper>div>div>div {
    height: 100% !important;
  }
  
  .mainplaninputstabpanel{
    width:96.5%;
    position:relative;
    top:24px;
    left:24px;
  }
  
  @media screen and (min-width: 2600px) {
    /* .margin #grid-theme-wrapper>div>div {
      height: 90% !important;
      border: 1px solid red;
    }
    .margin #grid-theme-wrapper>div>div>div {
      height: 100% !important;
    } */
  }
  
  @media screen and (max-width: 2600px) {
    /* .margin #grid-theme-wrapper>div>div {
      height: 90% !important;
      border: 1px solid red;
    }
    .margin #grid-theme-wrapper>div>div>div {
      height: 100% !important;
    } */
  }
    
  @media screen and (max-width: 1500px) {
    /* .margin #grid-theme-wrapper>div>div {
      height: 90% !important;
      border: 1px solid red;
    }
    .margin #grid-theme-wrapper>div>div>div {
      height: 100% !important;
    } */
    /* .revenue-adjust #grid-theme-wrapper>div>div>div{
      height: 450px !important;
    } */
  }   
  .newplanassumptiontabs{
    display: flex;
    background-color:#ffffff;
    min-width: inherit;
    width: auto;
}
.newplanassumptiontabs>div>div:nth-child(3)>div>button{
  margin: 0 0 5px 12px !important;
}
.newplanassumptiontabs>div>div:nth-child(3)>div>button:active{
  border-radius: 4px !important;
  background-color: #ffed9b !important;
  color: #000000;
}
.newplanassumptiontabs>div.MuiTabs-vertical{
  min-width: 17%;
  padding-right: 1%;
  /* height: 528px; */
  height: fit-content;
  margin-top: -40px;
  margin-left: -7px;
}
.newplanassumptiontabpanel{
  min-width: 942px;
}
.newplanassumptiontabpanel>div>p.MuiTypography-body1{
  width:917px;
}
.newplanassumptiontabpanel>div>p.MuiTypography-body1 {
  width: auto;
}

.newplanassumptiontabpanel {
  min-width: 82%;
  width: 100%;
  padding-bottom: 28px;
  padding-top:0px;
}
.paddingtarget .MuiBox-root {
  padding-right: 0px !important;
}
  
  
  
  