body {
  margin: 0;
  font-family: customFontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: customFontFamily;
  src: local('customFontFamily'), url(/static/media/Ride-Light.09d820c3.ttf);
}

@font-face {
  font-family: customFontFamilyRegular;
  src: local('customFontFamilyRegular'), url(/static/media/Ride-Bold.a23d5ff1.ttf);
}
div{
  font-family: customFontFamily;
}
span{
  font-family: customFontFamily;
}
label{
  font-family: customFontFamily;
}


.usDivisionDropdown {
    width: 135px;
    border: 1.5px solid #000000 !important;
    border-radius: 3px;
    height: 34px;
    padding-bottom: 5px;

}

.usDivisionDropdown-inverted {
    width: 135px;
    border: 1.5px solid white !important;
    border-radius: 3px;
    height: 34px;
    padding-bottom: 5px;
}

.profileDropdown {
    height: 34px;
    margin-bottom: 5px;
    min-width: 120px;
    width:120px;


}
.usDivisionDropdown>div.MuiInputBase-input{
    width: 93.7px !important;
    height: 20px;
    font-size: 14px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000000;
}
.usDivisionDropdown-inverted>div.MuiInputBase-input{
    width: 93.7px !important;
    height: 20px;
    font-size: 14px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
}
.usDivisionDropdown-inverted>svg{
    height: 22px;
    font-weight: 600 !important;
    font-size: 31px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
}
.usDivisionDropdown>svg{
    height: 22px;
    font-weight: 600 !important;
    font-size: 31px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000000;
}
.usericonstyle{
  width: 23px;
  height: 28px;
  object-fit: contain;
  margin-bottom: 10px;
}
.notificationstyle{
width: 27px;
height: 28px;
margin: 4px -7px 4px -15px;
padding: 0 4px 8px 19px;
object-fit: contain;
}
.usermenudropdown>div>div.MuiInput-input{
width: 154.4px !important;
height: 20px;
margin: 10px -39.4px 4px 4px;
font-size: 14px !important;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #000000;
}
.usermenudropdown-inverted>div>div.MuiInput-input{
    width: 154.4px !important;
    height: 20px;
    margin: 10px -39.4px 4px 4px;
    font-size: 14px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}
.usermenudropdown{
 margin-top: -1px;
}
.notificationmessage{
    position: absolute;
    z-index: 99;
    bottom: 0px;
    left: 30%;
    width: 40%;
    display: flex;
    flex-direction: column-reverse;
}
.notificationmessage>.MuiAlert-root{
    margin-bottom: 1.5%;
}
.newarchitecturebutton{
    color: #fff;
    cursor:pointer;
    height:34px;
    margin-left: 12px;
    border-radius: 4px;
    background-color: #434244;
    border: none;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.72;
    letter-spacing: normal;
    font-family: customFontFamily;

}

.toplevelcontainerwrapper:has([intransactionsmode="true"]) :is(.sidebarcontainer-sidebarminimized,.TopBarHeader,.planflexitems,.filterStyle,.chipsSection,.MuiTabs-root,.revFunctiontables){
    pointer-events: none;
    opacity: 0.6; 
}
.selectedplan{
    margin: 3px 9px 3px 0;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.72;
    letter-spacing: normal;
    color: #434244;
  }
  .popupopen{
    display: flex;
    flex-wrap: wrap;
    margin-right: 17px;
    padding: 7px 14px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background-color: #ffffff;
    text-align: left;
  }
  @media screen and (max-width: 500px) {  
      .popupopen { 
          width: 100px; 
          display: inline-block;
      } 
  }
  .popupclose{
    min-width: 223px;
    display: flex;
    flex-wrap: wrap;
    margin-right: 17px;
    padding: 7px 14px;
    border-radius: 4px;
    background-color: #ffffff;
    text-align: left;
  }
  @media screen and (max-width: 500px) {  
      .popupclose { 
          width: 90px; 
      } 
  }
  .planwidth{
    min-width: 194px;
  }
  .newtext{
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.57;
    letter-spacing: normal;
    text-align: center;
    color: #434244;
    border-radius: 4px;
    background-color: #ffde57;
    padding-bottom: 1px;
    padding-left: 3px;
    padding-right: 3px;
}
.chevrondown{
    width: 18px;
}
.popover>div.MuiPopover-paper {  
    top: 50px !important;
    left: 289px !important;
    width: auto;
    display: block;
    height: auto;
    padding: 13px 0 20px;
    border-top-left-radius: 0px !important;
    box-shadow: 2px 2px 4px 0 rgb(67 66 68 / 30%) !important;
    background-color: #ffffff !important;
    overflow-y: hidden !important;
}
 
 @media screen and (max-width: 900px) {
    .popover>div.MuiPopover-paper {
        left: 26px !important;
    }
 }
 .plansdropdown{
    display: inline-block;
    width: 590px;
 }
 .planlist{
    height: 16px;
    /* font-family: Ride; */
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    list-style-type: none;
    padding-left: 0px;
}
.planliststyle{
    display: flex;
    flex-wrap: wrap;
    border-bottom: 2px solid #f3f2f3;
    padding-left: 11px;
    text-align: center;
    padding-top: 7px;
}
.planliststyle>span:nth-child(1){
  width: 1px;
  height: 16px;
  margin-left: 1%;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  cursor: pointer;
}
.planliststyle>span:nth-child(1){
    color: #006bbd;
  }
.planliststyle>span:nth-child(2){
  width: 124px;
  margin-left: 10%;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #434244;
  text-align: left;
}
.planliststyle>span:nth-child(2){
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.planliststyle>span:nth-child(3){
    width: 10%;
    margin-left: 5%;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #434244;
}
.planliststyle>span:nth-child(4){
    width: 23%;
    margin-left: 1%;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: normal;
    color: #434244;
}
.statusstyle{
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.0;
    letter-spacing: normal;
    color: #434244;
    text-transform: capitalize;
    margin: -5px 0px 4px 0px;
}
.plannew{
    background-color: #ffcc00;
    padding: 5px 0px 5px 0px;
    border-radius: 12px;
    min-height: 16px;
    height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
}
.planready{
    background-color: #e6e7eb;
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
}
.planrunning{
    background-color: #ece4f4;
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
}
.plancomplete{
    background-color: #e5fdf1;
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
}
.planpublish{
    background-color:#e2f4ff;
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
}
.planfail{
    background-color: #fee6d8;
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
}
.copylink{
    padding-bottom: 0px !important;
    width: 18px;
    margin: 0px 14px 10px 6px;
    object-fit: contain;
    padding-top: 2px !important;
}
.copyimg{
    width: 15px;
    margin: 0px 0px 0px 21px;
}
.planlinks{
    position: relative;
    padding-left: 14px;
    margin-top: 15px;
    margin-bottom: -14px;
    display: flex;
    flex-wrap: wrap;
}
.planlinks>a:nth-child(1){
    width: 70%;
}
.planlinks>a:nth-child(2){
    margin-left: 11%;
}
.linksimg{
    width: 12px;
}
.linkname{
    height: 16px;
    margin: 0 0 4px 6px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;
    color: #434244;
}

.iconwidth{
    margin-left: 11px;
}

/* default topbar style */
@media (min-width: 0px) and (orientation: landscape){
.MuiToolbar-regular {
    min-height: 64px !important;
 }
}
.MuiToolbar-regular {
    min-height: 64px !important;
}
.planadjustmentcomplete{
    padding: 7px 0px 7px 0px;
    background-color: #f0fde5;
    border-radius: 12px;
    min-height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
  }
  .completeOptimal {
    background-color: #e5fdf1;
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
  }
  .pdSupOptm {
    background-color: #ff980e73;
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
  }
  .runUnknown {
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
    background-color:  #eb200b54;
  }
  .EditingAdjustments{
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
    background-color:  #e0908854;
  }
  .AdjustmentsModified{
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
    background-color:  #8d8bd054;
  }
.dialogbox>div.MuiDialog-container{ 
    height: 68% !important;
}
.dialogbox>div>div.MuiDialog-paperScrollPaper {
    max-height: none;
    width: 482px;
    height: 180px;
    width: 514px;
    height: 255px;
    padding: 12px 6px 12px 6px;
    border-radius: 5px;
    background-color: #ffffff;
}
.modelmfroem_dialogbox>div.MuiDialog-container{ 
  height: 68% !important;
}
.modelmfroem_dialogbox>div>div.MuiDialog-paperScrollPaper {
  max-height: none;
  width: 482px;
  height: 180px;
  width: 514px;
  height: 320px;
  padding: 12px 6px 12px 6px;
  border-radius: 5px;
  background-color: #ffffff;
}
.dialogtitle>h2.MuiTypography-h6{
  width: 100%; 
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
.dialogtitle>h2.MuiTypography-h6>span:nth-child(1){
  /* width: 121px;
  height: 16px; */
  margin: 0 175px 0 0;
  font-size: 18px;
  font-family: customFontFamily;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.73;
  letter-spacing: normal;
  color: #434244;
}
.dialogtitle>h2.MuiTypography-h6>span:nth-child(2)>img.closeicon{
  width: 16px;
  height: 16px;
}
.dialogtitle>h2.MuiTypography-h6>span:nth-child(2){
  margin-top: -6px;
}
.dialogcontent.MuiTypography-body1{
  width: 356px;
  height: 13px;
  margin-top: 11px;
  margin-bottom: 18px;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal;
  line-height: 0.81 !important;
  letter-spacing: normal !important;
  color: #434244 !important;
  font-family: customFontFamily;
}
.actionbuttons>button.MuiButton-root{
    text-transform: capitalize !important;
}
.actionbuttons>button.cancelbutton{
  padding: 14px !important;
  margin: 5px !important;
  border-radius: 4px;
  border: solid 1.5px #808285;
  background-color: #ffffff;
}
.actionbuttons>button.okbutton{
    padding: 14px !important;
    margin: 5px !important;
    border: solid 1.5px;
    border-radius: 4px;
    background-color: #ffcc00;
  }
.actionbuttons>button>span.MuiButton-label{
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: center;
  color: #434244;
}
.generateRfTextbox>div>div>input.MuiInputBase-input{
  height: 2.1876em !important;
  font-size: 18px !important;
  padding-left: 8px !important;
}

.ticker{
    padding-left: 10px;
    font-weight: bold;
    color: black;
    /* border: 1px solid black; */
}
.ticker_inverted{
    padding-left: 10px;
    font-weight: bold;
    color:#ffcc00
    /* border: 1px solid black; */
}
.mainpanel {
  background-color:#f3f2f3;
  display:flex
}
.navList {
  list-style-type: none;
  display: flex;
  -webkit-padding-start: 10px;
          padding-inline-start: 10px;
  -webkit-padding-end: 10px;
          padding-inline-end: 10px;
  height:100%;
  justify-content: center;
}

.navList-sidebarminimized {
  list-style-type: none;
  display: flex;
  -webkit-padding-start: 10px;
          padding-inline-start: 10px;
  -webkit-padding-end: 10px;
          padding-inline-end: 10px;
  height:100%;
  justify-content: center;
}

a:-webkit-any-link{
  text-decoration: none;
  color: #434244;
  padding-bottom: 7px;
  padding-top: 7px;
  display: block;
}

.liststyle{
  margin-bottom: 25%;
  text-align: center;
  width: 100%;
  align-items: center;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.navlabel {
  text-decoration:none;
  display: block;
  height: 16px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #434244;
}

.icon {
  height: 20px;
  margin: 0 26px 5px 25px;
  padding: 0 1px 1px;
}

.icon-sidebarminimized {
  height: 20px;
  margin: 0 5px 5px 5px;
  padding: 0 1px 1px;
}

.active {
  background-color: #dfdfe1;
  border-radius: 4px;
  display: block;
}
.activeselected {
  background-color: #dfdfe1;
  border-radius: 4px;
  display: block;
}
.navList>div{
  width: 90px;
}

.navList-sidebarminimized>div{
  width: 40px;
}

.innerDiv{
  width: 100%;
}
.sidebar{
  display: flex;
  align-items: flex-start;
}

.margin {
  margin-left: 5px;
  /* margin-top: 20px; */
  /* margin-right: 40px; */
  /* margin-bottom: 35px; */
  height: 100%;
}
.marginleft{
  margin-left: -28px;
  margin-top: 10px;
  /* margin-bottom: 35px; */
  width: 103%;
}
.MuiTab-textColorInherit {
opacity: 1 !important;
}

.MuiTab-root {
min-width: 90px !important;
  min-height: 22px !important;
font-family: Ride;
}

.MuiTabs-fixed {
margin-left: 19px;
}

.MuiTabs-flexContainer {
  display: flex;
  flex-wrap: wrap;
}
.headertext{
width: 193px;
height: 38px;
margin: 0 0 0 0;
margin-left: 25px;
padding-top: 15px;
font-size: 30px;
font-weight: 300;
font-stretch: normal;
font-style: normal;
line-height: 1.06;
letter-spacing: -1px;
color: #000000;
/* margin-top: -16px; */
/* padding-top: 8px; */
}


@-webkit-keyframes copyinprogress {
  0%    {background-image: linear-gradient(to right, gold ,gold ,black,black,black,black,black,black,black,black,black)}
  10%   {background-image: linear-gradient(to right, black,gold ,gold ,black,black,black,black,black,black,black,black)}
  20%   {background-image: linear-gradient(to right, black,black,gold ,gold ,black,black,black,black,black,black,black)}
  30%   {background-image: linear-gradient(to right, black,black,black,gold ,gold ,black,black,black,black,black,black)}
  40%   {background-image: linear-gradient(to right, black,black,black,black,gold ,gold ,black,black,black,black,black)}
  50%   {background-image: linear-gradient(to right, black,black,black,black,black,gold ,gold ,black,black,black,black)}
  60%   {background-image: linear-gradient(to right, black,black,black,black,black,black,gold ,gold ,black,black,black)}
  70%   {background-image: linear-gradient(to right, black,black,black,black,black,black,black,gold ,gold ,black,black)}
  80%   {background-image: linear-gradient(to right, black,black,black,black,black,black,black,black,gold ,gold ,black)}
  90%   {background-image: linear-gradient(to right, black,black,black,black,black,black,black,black,black,gold ,gold )}
  100%  {background-image: linear-gradient(to right, black,black,black,black,black,black,black,black,black,black,gold )}
}


@keyframes copyinprogress {
  0%    {background-image: linear-gradient(to right, gold ,gold ,black,black,black,black,black,black,black,black,black)}
  10%   {background-image: linear-gradient(to right, black,gold ,gold ,black,black,black,black,black,black,black,black)}
  20%   {background-image: linear-gradient(to right, black,black,gold ,gold ,black,black,black,black,black,black,black)}
  30%   {background-image: linear-gradient(to right, black,black,black,gold ,gold ,black,black,black,black,black,black)}
  40%   {background-image: linear-gradient(to right, black,black,black,black,gold ,gold ,black,black,black,black,black)}
  50%   {background-image: linear-gradient(to right, black,black,black,black,black,gold ,gold ,black,black,black,black)}
  60%   {background-image: linear-gradient(to right, black,black,black,black,black,black,gold ,gold ,black,black,black)}
  70%   {background-image: linear-gradient(to right, black,black,black,black,black,black,black,gold ,gold ,black,black)}
  80%   {background-image: linear-gradient(to right, black,black,black,black,black,black,black,black,gold ,gold ,black)}
  90%   {background-image: linear-gradient(to right, black,black,black,black,black,black,black,black,black,gold ,gold )}
  100%  {background-image: linear-gradient(to right, black,black,black,black,black,black,black,black,black,black,gold )}
}
@-webkit-keyframes copyinprogress_active {
  0%    {background-image: linear-gradient(to right, gold ,gold ,white,white,white,white,white,white,white,white,white)}
  10%   {background-image: linear-gradient(to right, white,gold ,gold ,white,white,white,white,white,white,white,white)}
  20%   {background-image: linear-gradient(to right, white,white,gold ,gold ,white,white,white,white,white,white,white)}
  30%   {background-image: linear-gradient(to right, white,white,white,gold ,gold ,white,white,white,white,white,white)}
  40%   {background-image: linear-gradient(to right, white,white,white,white,gold ,gold ,white,white,white,white,white)}
  50%   {background-image: linear-gradient(to right, white,white,white,white,white,gold ,gold ,white,white,white,white)}
  60%   {background-image: linear-gradient(to right, white,white,white,white,white,white,gold ,gold ,white,white,white)}
  70%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,gold ,gold ,white,white)}
  80%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,gold ,gold ,white)}
  90%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,white,gold ,gold )}
  100%  {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,white,white,gold )}
}
@keyframes copyinprogress_active {
  0%    {background-image: linear-gradient(to right, gold ,gold ,white,white,white,white,white,white,white,white,white)}
  10%   {background-image: linear-gradient(to right, white,gold ,gold ,white,white,white,white,white,white,white,white)}
  20%   {background-image: linear-gradient(to right, white,white,gold ,gold ,white,white,white,white,white,white,white)}
  30%   {background-image: linear-gradient(to right, white,white,white,gold ,gold ,white,white,white,white,white,white)}
  40%   {background-image: linear-gradient(to right, white,white,white,white,gold ,gold ,white,white,white,white,white)}
  50%   {background-image: linear-gradient(to right, white,white,white,white,white,gold ,gold ,white,white,white,white)}
  60%   {background-image: linear-gradient(to right, white,white,white,white,white,white,gold ,gold ,white,white,white)}
  70%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,gold ,gold ,white,white)}
  80%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,gold ,gold ,white)}
  90%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,white,gold ,gold )}
  100%  {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,white,white,gold )}
}

.margin #grid-theme-wrapper>div>div {
  /* height: 90% !important; */
  /* border: 1px solid red; */
}
.margin #grid-theme-wrapper>div>div>div {
  /* height: 100% !important; */
}

.margin #grid-theme-wrapper>div>div {
  height: 90% !important;
  /* border: 1px solid red; */
}
.margin #grid-theme-wrapper>div>div>div {
  height: 100% !important;
}

.mainplaninputstabpanel{
  width:96.5%;
  position:relative;
  top:24px;
  left:24px;
}

@media screen and (min-width: 2600px) {
  /* .margin #grid-theme-wrapper>div>div {
    height: 90% !important;
    border: 1px solid red;
  }
  .margin #grid-theme-wrapper>div>div>div {
    height: 100% !important;
  } */
}

@media screen and (max-width: 2600px) {
  /* .margin #grid-theme-wrapper>div>div {
    height: 90% !important;
    border: 1px solid red;
  }
  .margin #grid-theme-wrapper>div>div>div {
    height: 100% !important;
  } */
}
  
@media screen and (max-width: 1500px) {
  /* .margin #grid-theme-wrapper>div>div {
    height: 90% !important;
    border: 1px solid red;
  }
  .margin #grid-theme-wrapper>div>div>div {
    height: 100% !important;
  } */
  /* .revenue-adjust #grid-theme-wrapper>div>div>div{
    height: 450px !important;
  } */
}   





.inputRounded{
    border-radius: 4px !important;
    border: solid 0px #808285 !important;
    background-color: #ffffff;
    width: 209px;
    height: 29px;
}
.inputRounded>div>fieldset.PrivateNotchedOutline-root-18{
    border-style: none !important;
}
.inputRounded>div>input{
    height: 15px;
    margin: 0 0 0 -8px;
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.07;
    letter-spacing: normal;
    color: #5a585c;
}


.MuiOutlinedInput-input {
    /* padding: 18.5px 14px; !important */
  }
.mainHeader {
  background-color: white;
    -webkit-margin-before: -36px !important;
            margin-block-start: -36px !important;
    padding-bottom: 5px;
    color: white;
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
}

.fixedMainHeader {
  background-color: white;
  -webkit-margin-before: -36px !important;
          margin-block-start: -36px !important;
  padding-bottom: 5px;
  color: white;
  width: 1104px !important;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
}


.actionButtons>div:nth-child(1).Mui-focused {
  background-color: none !important;
}

.viewdropdown>div.MuiInputBase-input {
  padding: 0px 2px 7px 3px !important;
}

.viewdropdown>div.MuiSelect-select:focus {
  background-color: none !important;
}

.viewdropdown>svg.MuiSelect-icon {
  padding: 0px 2px 7px 2px !important;
}

.saveButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: row;
  min-width: 36%;
  margin-right: 10px;
  margin-top: 10px;
}

.actionButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
    min-width: 36%;
    margin-right: 10px;
    margin-top: 15px;
}

.filterMain {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 24px;
}

.actionButtons>Button {
  padding: 14px !important;
  margin: 5px !important;
  text-transform: none !important;
  padding: 10px;
}

.imageIcon {
  margin: 10px;
  width: 24px !important;
  height: 28px;
}

.MuiMenuItem-root {
  font-size: 13px !important;
}

.MuiSelect-selectMenu {
  width: 165px !important;
  border-radius: 3px;
  margin-top: 5px !important;
  padding: 5px !important;
}

.MuiFormControl-root {
  margin-right: 10px !important;
}

.MuiSvgIcon-root {
  padding-top: 3px !important;
  margin-top: 0px !important;
  padding-right: 5px;
}

.MuiSelect-select {
  /* width: 120px !important; */
  border-radius: 3px !important;
  /* border: 1px solid grey !important; */
  /* background: #ffcc00 !important;  */
}

.dropdownfields {
  display: flex;
  flex-direction: column;
  width: 176px;
  margin-top: 5px;
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
}

.MuiTypography-gutterBottom {
  margin-bottom: 0 !important;
}

.MuiMenuItem-root {
  font-family: customFontFamily !important;
  height: 28px;
  border-bottom: 1px solid lightgray;
}

.MuiInputBase-root {
  font-family: customFontFamily !important;
}

.MuiTypography-body1 {
  font-family: customFontFamily !important;
}

.muiDatePicker {
  margin-top: 0px !important;
  border: 1px solid #808285 !important;
  border-radius: 3px !important;
  padding-left: 5px !important;
  width: 165px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #808285 !important;
  border-radius: 3px !important;
  border-width: 1px !important;
}

.filterStyle {
  /* min-width: 63%;
  display: flex;
  flex-direction: row; */
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.viewdropdown {
  width: 90px;
  height: 34px;
  margin: 0 0 0 18px;
  padding: 1px;
  border-radius: 5px;
  background-color: #ffcc00;
}

.actionButtons>div.MuiFormControl-root {
  margin-top: 7px !important;
}

.primaryButton {
  border: solid 1px;
  border-radius: 5px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 12px;
  margin: 6px;
  padding-top: 2px;
  background-color: #ffcc00;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.secondaryButton {
  border: solid 1px;
  border-radius: 5px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 12px;
  margin: 6px;
  padding-top: 2px;
  background-color: transparent !important;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.MuiButton-label {
  text-transform: none !important;
}

.searchbar {
  margin-right: 48%;
  margin-top: 8px;
}

.MuiDialog-paperWidthSm {
  max-width: 675px !important;
}

.imageSpacing {
  padding-right: 15px !important;
  min-width: 24px !important;
}

.MuiListItem-root.Mui-selected {
  background-color: transparent !important;
}

.actiobarPopOver>div.MuiPopover-paper {
  border-top-right-radius: 0px !important;
  box-shadow: -5px 10px 20px 0px rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff !important;
  overflow-y: hidden !important;
  margin-top: 0.7px !important;
}

div.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.MuiDivider-root {
  background-color: #f3f2f3;
}
.programsfilterMargin {
  margin:0px 10px 0px 10px;
}

.customDialog>div.MuiDialog-container{ 
    height: 95% !important;
    width: 95% !important;
}
.customDialog>div>div.MuiDialog-paperScrollPaper {
    max-height: none;
    width: 90% !important;
    height: 90% !important;
    padding: 12px 6px 12px 6px;
    border-radius: 5px;
    background-color: #ffffff;
    display: flex;
}
.dialogtitle>h2.MuiTypography-h6{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
.dialogtitle>h2.MuiTypography-h6>span:nth-child(1){
  width: 261px;
  height: 16px;
  margin: 0 216px 0 0;
  font-size: 18px;
  font-family: customFontFamily;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.73;
  letter-spacing: normal;
  color: #434244;
  display: contents;
}
.dialogtitle>h2.MuiTypography-h6>span:nth-child(2)>img.closeicon{
  width: 16px;
  height: 16px;
}
.dialogtitle>h2.MuiTypography-h6>span:nth-child(2){
  margin-top: -6px;
}
.dialogcontent.MuiTypography-body1{
  width: 356px;
  height: 13px;
  margin-top: 11px;
  margin-bottom: 18px;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal;
  line-height: 0.81 !important;
  letter-spacing: normal !important;
  color: #434244 !important;
  font-family: customFontFamily;
}
.actionbuttons>button.MuiButton-root{
    text-transform: capitalize !important;
}
.actionbuttons>button:nth-child(1){
  padding: 14px !important;
  margin: 5px !important;
  border-radius: 4px;
  border: solid 1.5px #808285;
  background-color: #ffffff;
}
.actionbuttons>button:nth-child(2){
    padding: 14px !important;
    margin: 5px !important;
    border: solid 1.5px;
    border-radius: 4px;
    background-color: #ffcc00;
  }
.actionbuttons>button>span.MuiButton-label{
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: center;
  color: #434244;
}


    .inputFields {
      display: flex;
      flex-direction: column;
      width: 176px !important;
      margin-left: 10px !important;
    }
.muiDatePicker {
    margin-top: 0px !important;
    border: 1px solid #808285 !important;
    border-radius: 3px !important;
    padding-left: 5px !important;
    width: 160px !important;
}

.calendarIcon {
    width: 22px;
    height: 20px;
    padding-right: 5px;
}

.dropdownFilter {
    width: 160px !important;
    min-width: 160px !important;
}

.datepickerBar {
    display: flex;
    flex-direction: row;
    margin-top: 10px !important;
}

.datesFields {
    margin-left: 10px !important;
}

.dropdownStyle {
    border: 1px solid #808285 !important;
    border-radius: 3px;
    height: 30px;
    /* width: 167px;
    min-width: 120px; */
}

.rangeinput{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.andtext{
    height: 19.5px;
    margin: 8px 11px 8.5px 6px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #434244;
}
.rangemargin{
    margin-left: 8px;
}
.rangeinput>div>div.MuiFormControl-root.MuiTextField-root{
    width: 165px !important;
    margin-top: 0px !important;
}
.rangeinput>span.displayflx{
    display: flex;
    margin-top: -5px;
}
 .constraintdropdown {
      display: flex;
      flex-direction: column;
      width: 176px;
      margin-left: 10px;
    } 

.MuiSelect-select.MuiSelect-select {
    padding-left: 8px !important;
}
.constraintdropdown>div>div>div>div.dropdown-heading-value{
    font-size: 12px;
}
.constraintdropdown>div>div>div.dropdown-content{
    font-size: 13px !important;
    color: gray;
    font-family: customFontFamily !important;
}

/* .rmsc .options{
    color: gray !important;
    font-size: 13px !important;
} */
.dropdown-container:focus-within{
    border-color:#808285 !important;
    box-shadow: #808285 0 0 0 0px !important;
}
.constraintdropdown>p{
    width: 254px;
    height: 18px;
    margin: 0 26px 5px 0;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #808285;
}
.dropdownFilter>p{
    width: 254px;
    height: 18px;
    margin: 0 26px 5px 0;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #808285;
}
.constraintdropdown>div>div.dropdown-container{
    border-color:#808285 !important;
}

.go2642161244 {
    height: 30px !important;
}
.chipsSection{
    width: 100%;
    /* border-width: 2;
    border-style: solid; */
    color: black;
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
    flex-wrap: wrap;
    align-items: flex-start;
}

.chipsSectionInternal{
    width: 96%;
    /* border-width: 2;
    border-style: solid;
    border-color: blue; */
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.chipsCrossSection{
    /* border-width: 2;
    border-style: solid;
    border-color: red; */
    display: flex;
    background-color: #ffde57;
    border-radius: 5px;
}

.chipsCrossSection:hover{
    background-color: #ffaa44;
}

.aChip{
    background-color: #ffde57;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 6px;
    padding-left: 6px;
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: small;
    display: flex;
    align-items: center;
}

.chipCross{
    margin: 6px;
}

.chipCrossMain{
    margin: 6px;
    /* width: 24px;
    height: 24px; */
}

.tooltip {
    position: relative;
    display: inline-block;
    /* border: 1px dotted black; */
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 60px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 2px 0;
    font-size: 12px;
    opacity: 0.7;
    margin-top: 50px;
  
    /* Position the tooltip */
    position: absolute;
    bottom: -1;
    right: 0;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
.dateEditorStyle {
    margin-left: 1px;
    margin-right: 1px;
    width: 200;
}

.MuiInput-underline
{
  border:none !important;
  border-bottom: 0px solid !important;
}

.MuiIconButton-root {
    padding-top: 2px !important;
    padding-bottom: 5px !important;
}

.MuiInputBase-input {
    padding: 8px 22px 8px 3px !important;
    font-size: 13px !important;
    margin: 0 0 0 -3px !important;
}

.MuiInputBase-root { 
    margin-top: 0px !important;
    font-size: 13px !important;
}

.MuiSvgIcon-root {
    width: 0.8em !important;
    margin-top: -4px;
}

.MuiInput-underline:before {
    border-bottom: 0px !important;
    left: -1px !important;
}

.MuiInput-underline:after {
    border-bottom: 0px !important;
}

.MuiInputAdornment-positionEnd {
    margin-left: -32px !important;
    margin-right: -29px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.inputdatepicker>div>div>button>span:nth-child(1).MuiIconButton-label{
    width:1px !important
}

.deleteIconBlur{
    padding-right: 8px;
    padding-top: 10px;  
    opacity: 0.3;
}
.deleteIcon{
    padding-right: 8px;
    padding-top: 10px;  
    opacity: 1;
}

.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input {
    border: 1px solid lightgray;
    border-radius: 3px;
    padding-right: 21px !important;
    width: 98px !important;
    background: white;
    min-width: 90px;
    height: 12px;
    margin-top: 1px !important;
    margin-right: 2px !important;
    color: black !important;
    font-size: 13px !important;
}

.MuiAutocomplete-option {
    font-size: 13px !important;
    color: black !important;
    padding: 5px !important;
    text-transform: uppercase !important;
}

.MuiAutocomplete-popupIndicatorOpen {
    padding-left: 8px !important;
    padding-right: 8px !important;
    padding-top: 3px !important;
}

.MuiAutocomplete-popupIndicator {
    padding: 2px;
    margin-right: -2px;
    position: relative;
    display: flex;
}

/* .MuiAutocomplete-popupIndicatorOpen {
    padding-right: 3px !important;
} */
.ag-react-container>button.hotstyle{
    width: 75px !important;
    height: 35px !important;
    margin: -3px 5px 0 0;
    padding: 15px 0 14px;
    /* border-radius: 5px; */
    background-color: #eb7000 !important;
    color: #ffffff !important;
}
.ag-react-container>button.coldstyle{
    width: 75px !important;
    height: 35px !important;
    margin: -3px 5px 0 0;
    padding: 15px 0 14px;
    /* border-radius: 5px; */
    background-color:#006bbd !important;
    color: #ffffff !important;
}
.ag-react-container>button.emptyvaluestyle{
    width: 75px !important;
    height: 36px !important;
    border-radius: 5px !important;
    margin: -1px 1px 2px 0px;
    padding: -1px 0px 0px 0px;
    text-align: center;
    padding-top: -1px;
    line-height: 32px;
    background-color: #80828594 !important;
    color: #ffffff !important;
}
.ag-cell-focus{
    border: none !important;
}
.ag-react-container>button.MuiToggleButton-root{
    width: 75px !important;
    height: 36px !important;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #434244;
    text-transform: capitalize;
}
.TogglebtnvalidRow, .TogglebtnerrorCell {
    background-color: transparent !important;
}

.TogglebtnvalidRow::after, .TogglebtnerrorCell::after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 0;
    border-color: transparent #E90133 transparent transparent;
} 
.TogglebtnerrorCell::after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 0;
    border-color: transparent #E90133 transparent transparent;
}

 .TogglebtnvalidRow::before, .TogglebtnerrorCell::before {
    content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    display: block;
    border: 1.5px solid #E90133;
    border-radius: 3px;
} 
.TogglebtnerrorCell::before {
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: block;
    border: 1.5px solid #E90133;
    border-radius: 3px;
} 
.TogglebtnvalidRow::after{
    border-right-color: #006bbd;
}
.TogglebtnvalidRow::before{
    width: 73px !important;
    height: 34px !important;
    margin: -3px 10px -5px -3px;
    padding: 15px 0 14px;
    border-radius: 5px;
    border-color: #006bbd;
}
.ag-react-container>button.hotstyle::after {
    content: "";
    position: absolute;
    top: 0px;
    right: -1px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width:  0 11px 11px 0;
    border-color: transparent #E90133 transparent transparent;
} 

 .ag-react-container>button.hotstyle::before {
    content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    display: block;
    border: 1.5px solid #E90133;
    border-radius: 3px;
    border-top-right-radius: 0px !important;
} 
.ag-react-container>button.hotstyle::after{
    border-right-color: #ffb775;
}
.ag-react-container>button.hotstyle::before{
    width: 72px !important;
    margin: -3px 10px -5px -4px;
    padding: 15px 0 14px;
    border-radius: 5px;
    border-color: #ffb775;
}
.ag-react-container>button.coldstyle::after {
    content: "";
    position: absolute;
    top: 0px;
    right: -1px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width:  0 11px 11px 0;
    border-color: transparent #E90133 transparent transparent;
} 

 .ag-react-container>button.coldstyle::before {
    content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    display: block;
    border: 1.5px solid #E90133;
    border-radius: 3px;
} 
.ag-react-container>button.coldstyle::after{
    border-right-color: #bbe2fa;
}
.ag-react-container>button.coldstyle::before{
    width: 72px !important;
    margin: -3px 10px -5px -4px;
    padding: 15px 0 14px;
    border-radius: 5px;
    border-color: #bbe2fa;
}
.ag-react-container>button.emptyvaluestyle::after {
    content: "";
    position: absolute;
    top: 0px;
    right: -1px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width:  0 11px 11px 0;
    border-color: transparent #E90133 transparent transparent;
} 

 .ag-react-container>button.emptyvaluestyle::before {
    content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    display: block;
    border: 1.5px solid #E90133;
    border-radius: 3px;
} 
.ag-react-container>button.emptyvaluestyle::after{
    border-right-color: #ffb775;
}
.ag-react-container>button.emptyvaluestyle::before{
    width: 72px !important;
    margin: -3px 10px -5px -4px;
    padding: 15px 0 14px;
    border-radius: 5px;
    border-top-right-radius: 0px !important;
    border-color: #ffb775;
}
.tempediticon{
    padding-top: 10px;
    margin-left: -3px !important;
}
.tempreverticon{
    padding-top: 10px;
    margin-left: -3px !important;
}
.fromccgroup{
    width: 110px;
    height: 13px;
    margin: 2px 36px 3px 16px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #808285;
    text-transform: capitalize !important;
}
.toccgroupstyle{
    width: 110px;
    height: 13px;
    margin: 2px 0 3px 21px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #808285;
    text-transform: capitalize !important;
}
.textalignment{
    text-overflow: ellipsis;
    overflow: hidden;
}
.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input {
    border: 1px solid lightgray;
    border-radius: 3px;
    padding-right: 21px !important;
    width: 98px !important;
    background: white;
    min-width: 90px;
    height: 12px;
    margin-top: 1px !important;
    margin-right: 2px !important;
    color: black !important;
    font-size: 13px !important;
}

.MuiAutocomplete-option {
    font-size: 13px !important;
    color: black !important;
    padding: 5px !important
}

.MuiAutocomplete-popupIndicatorOpen {
    padding-left: 8px !important;
    padding-right: 8px !important;
    padding-top: 3px !important;
}

.MuiAutocomplete-popupIndicator {
    padding: 2px;
    margin-right: -2px;
    position: relative;
    display: flex;
}
.upgradeAutocomplete{
    width: 218px;
}
.upgradeAutocomplete>div>div>div>div.MuiAutocomplete-endAdornment{
    top: calc(50% - 16px);
    right: 58px;
}
.hotcoldicon{
    width: 15px;
    height: 14px;
    margin: 7px -10px -1px 0px;
}
.pathtempstyle{
  width: 30px;
  height: 13px;
  margin: 2px 60px 3px 15px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  color: #434244;
}
.textfieldStyles>div>div>div.MuiInputBase-root {
    min-width: 112px !important;
    background: #f6f5f6 !important;
    min-height: 34px !important;
    width:98%;
}

.textfieldStyles>div>div>div.MuiInputBase-root>.MuiInput-input {
    border: solid 1px lightgray !important;
    background-color: #ffffff !important;
    border-radius: 5px !important;
}

.textfieldStyles>div>div>div.MuiInputBase-root>.MuiInput-input:focus {
    outline: none;
    border-bottom: 1.5px solid #4c97d0 !important;
    /* -webkit-box-shadow: inset 0 1px 5px 4px #e2f4ff !important; */
    /* box-shadow: inset 0 1px 5px 4px #e2f4ff !important; */
    text-transform: uppercase !important;
    width: 100% !important;
    padding-right: 0px !important;
}
.textfieldStyles{
    margin: 0 2% 0 2% !important;
}
.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input {
    border: 1px solid lightgray;
    border-radius: 3px;
    padding-right: 21px !important;
    width: 98px !important;
    background: white;
    min-width: 90px;
    height: 12px;
    margin-top: 1px !important;
    margin-right: 2px !important;
    color: black !important;
    font-size: 13px !important;
}

.MuiAutocomplete-option {
    font-size: 13px !important;
    color: black !important;
    padding: 5px !important;
    text-transform: uppercase !important;
}

.MuiAutocomplete-popupIndicatorOpen {
    padding-left: 8px !important;
    padding-right: 8px !important;
    padding-top: 3px !important;
}

.MuiAutocomplete-popupIndicator {
    padding: 2px;
    margin-right: -2px;
    position: relative;
    display: flex;
}

.revenuedropdown>div.MuiSelect-select.MuiSelect-select{
    border-radius: 4px !important;
    border: solid 1.4px #808285 !important;
    background-color: #ffffff !important;
    width: 100% !important;
}

.revenuemenus>.MuiInputBase-root>.MuiSelect-select{
    text-align: left;
}

.revenuemenus>.MuiInputBase-root>.MuiSelect-selectMenu{
  width: 180px !important;
}
.revenuedesc{
    width: 203px;
    height: 20px;
    margin: 3px 15px 0 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    color: #808285;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .rfvalue{
    width: 203px;
    height: 20px;
    margin: 3px 15px 0 0;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    color: #808285;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .MuiMenu-paper{
    height: 200px;
  }
  .revenueoptions {
    min-height: 34px !important;
    display: block !important;
    text-align: left !important;
}

.revenuemenus>div.MuiInput-underline{
  padding-right: 20px;
  
}
.customIcons {
    justify-content: space-around;
    display: flex;
    padding-top: 10px;
}

.ag-theme-alpine .ag-ltr .ag-cell {
    border-radius: 0px !important;
    text-transform: uppercase !important;
}

.ag-theme-alpine .ag-cell-inline-editing {
    box-shadow: 0 0 0 0 transparent;
}

.ag-input-field-input {
    border: solid 1px;
    padding: 5px;
    border-radius: 0px;
    box-shadow: 0px 0px 0px 0px !important;
    width: 93%;
    height: 32px !important;
    min-height: 32px;
    margin: 5px;
    background-color: #f6f5f6;
}

.ag-theme-alpine .ag-ltr input[class^='ag-']:not([type]), .ag-theme-alpine .ag-ltr input[class^='ag-'][type='text'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='number'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='tel'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='date'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='datetime-local'], .ag-theme-alpine .ag-ltr textarea[class^='ag-'] {
    margin: 3px;
    margin-left: 5px !important;
    text-align: right;
    border: solid 1px lightgray !important;
}

.ag-root-wrapper {
    border: 0px !important;
}

.ag-header {
    height: 40px !important;
    min-height: 40px !important;
    border-bottom: solid 2px !important;
    border-bottom-color: lightgray !important;
}

.ag-header-viewport {
    background-color: white;
}

.ag-pinned-right-header {
    background-color: white;
}

.ag-header-cell {
    padding-bottom: 10px !important;
}

.ag-header-cell-resize {
    /* height: 0% !important */
}

.ag-right-aligned-header .ag-header-label-icon { 
    margin-left: 6px !important;
    margin-right: 0px !important;
}

 .ag-right-aligned-header .ag-header-cell-label { 
    flex-direction: row !important;
} 

.ag-right-aligned-header .ag-header-cell-text{
    flex-grow: 1;
    text-align: right;
}

.ag-header-row:after {
    right: -1px !important;
}

.ag-floating-bottom-viewport {
    background: #ffed9b !important;
}

.ag-floating-bottom {
    background: #ffed9b !important;
}

.ag-row-pinned {
    background: #fff3bc !important;
}

.ag-theme-alpine .ag-cell-inline-editing {
    background: #f6f5f6 !important;
    box-shadow: 0 1px 4px 1px transparent !important;
    border-color: transparent !important;
    border: 0px !important;
    border-radius: 0px !important;
    padding-right: 2px !important;
}

.ag-row-pinned>.ag-cell-inline-editing {
    background: #f6f5f6 !important;
}

.ag-theme-alpine .ag-row {
    border: 1px solid;
    border-color: lightgray;
    font-size: 13px;
}

.ag-icon {
    color: grey;
}

#grid-theme-wrapper.app-theme .ag-theme-alpine .ag-row-selected {
    background-color: #f6f5f6;
}

/* div.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-focus {
    background-color: whitesmoke !important;
} */

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
    color: whitesmoke !important;
    background-color: black;
    width: 13px !important;
    height: 13px !important;
    line-height: 13px !important;
    border: 1px solid darkgray;
}

.ag-theme-alpine .ag-checkbox-input-wrapper {
    background-color: white;
    font-size: 14px;
    width: 14px;
    height: 14px;
    line-height: 14px;
}

.ag-theme-alpine .ag-row-odd {
    background-color: white;
}

.ag-theme-alpine .ag-row-hover {
    background: #f6f5f6;
}

.copyRow {
    background-color: #e2f4ff !important;
}

/* small table row icon hover effect */

/* .ag-pinned-right-cols-container img {
    opacity: .7;
}

.ag-pinned-right-cols-container img:hover {
    opacity: 1;
    cursor: pointer;
} */

/*  move text in date picker up a little */

.MuiFormControl-marginNormal .MuiInputBase-input {
    padding-top: 5px !important;
}

/* add table input focus state */

.ag-theme-alpine input[class^=ag-]:not([type]):focus, .ag-theme-alpine input[class^=ag-][type=date]:focus, .ag-theme-alpine input[class^=ag-][type=datetime-local]:focus, .ag-theme-alpine input[class^=ag-][type=number]:focus, .ag-theme-alpine input[class^=ag-][type=tel]:focus, .ag-theme-alpine input[class^=ag-][type=text]:focus, .ag-theme-alpine textarea[class^=ag-]:focus, .MuiAutocomplete-inputRoot[class*=MuiInput-root] .MuiAutocomplete-input:focus, .ag-cell-focus .MuiFormControl-marginNormal {
    outline: none;
    border-bottom: 1.5px solid #4c97d0 !important;
    /* -webkit-box-shadow: inset 0 1px 5px 4px #e2f4ff !important; */
    /* box-shadow: inset 0 1px 5px 4px #e2f4ff !important; */
    text-transform: uppercase !important;
}

.validRow, .errorCell {
    background-color: transparent !important;
}

.validRow::after, .errorCell::after {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 0;
    border-color: transparent #E90133 transparent transparent;
} 

 .validRow::before, .errorCell::before {
    content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    display: block;
    border: 1.5px solid #E90133;
    border-radius: 3px;
} 

.validRow::after {
    border-right-color: #006bbd;
}

.validRow::before {
    border-color: #006bbd;
} 

/* Add New row (highlight medium blue left border) */

.ag-center-cols-clipper .copyRow::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 7px;
    background-color: #006bbd;
}

.ag-center-cols-clipper .errorRow::before{
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 7px;
    background-color: #eb7000;
}

.ag-center-cols-clipper .errorCellBorder::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 7px;
    background-color: #eb7000;
}

.errorRow {
    background-color: #ffe2c9 !important;
}

div.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height ag-cell-first-right-pinned ag-cell-value ag-cell-focus {
    border-left: 1px solid transparent !important;
}

.ag-theme-alpine .ag-ltr .ag-cell.typehotstyle{
    width: 75px !important;
    height: 36px !important;
    border-radius: 5px !important;
    background-color: #fce3b8 !important;
    margin: 2px 1px 2px 1px;
    padding: -1px 0px 0px 0px;
    text-align: center;
    padding-top: -1px;
    line-height: 32px;
}
.ag-theme-alpine .ag-ltr .ag-cell.typecoldstyle{
    width: 75px !important;
    height: 36px !important;
    border-radius: 5px !important;
    margin: 2px 1px 2px 1px;
    padding: -1px 0px 0px 0px;
    text-align: center;
    padding-top: -1px;
    line-height: 32px;
    background-color: #c7e5f8 !important;
}
.ag-theme-alpine .ag-ltr .ag-cell.blankvaluestyle{
    width: 75px !important;
    height: 36px !important;
    border-radius: 5px !important;
    margin: 2px 1px 2px 1px;
    padding: -1px 0px 0px 0px;
    text-align: center;
    padding-top: -1px;
    line-height: 32px;
    background-color: #80828594 !important;
}
.ag-cell-focus {
    border: 1px solid #006bbd !important;
}

.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input {
    /* border: 1px solid transparent; */
    border-radius: 3px;
    padding-right: 15px !important;
    margin-left: 5px !important;
    width: 98px !important;
    background: white;
    min-width: 90px;
    height: 14px;
    margin-top: 0px !important;
    margin-right: 2px !important;
    color: black !important;
    font-size: 13px !important;
}

/*.ag-theme-alpine .ag-ltr input[class^='ag-']:not([type]), .ag-theme-alpine .ag-ltr input[class^='ag-'][type='text'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='number'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='tel'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='date'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='datetime-local'], .ag-theme-alpine .ag-ltr textarea[class^='ag-'] {
    margin: 5px;
    border: solid 1px lightgray !important;
} */

.ag-theme-alpine input[class^='ag-']:not([type]), .ag-theme-alpine input[class^='ag-'][type='text'], .ag-theme-alpine input[class^='ag-'][type='number'], .ag-theme-alpine input[class^='ag-'][type='tel'], .ag-theme-alpine input[class^='ag-'][type='date'], .ag-theme-alpine input[class^='ag-'][type='datetime-local'], .ag-theme-alpine textarea[class^='ag-'] {
    min-height: 24px;
    border-radius: 3px;
} 

/* div.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-first-right-pinned.ag-cell-value.ag-cell-focus {
    border-left: 10px solid transparent !important;

} */
.indent {
  margin-left: 1.75em;
  margin-bottom: 4px;
}
.accordions{
  min-width: 1045px;
}
.no-padding {
  padding: 0px !important;
  margin: 0px !important;
}
.accordionview{
  border-radius: 4px;
  box-shadow: 1px 1px 2px 0 rgba(67, 66, 68, 0.15);
  background-color: #ffffff;
}
.contenticons{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: solid 2px #f3f2f3 !important;
    
}
.contenticons>span:nth-child(2)>img:nth-child(1){
  width: 19px;
  height: 20px;
  margin: 14px 0 19px 129px;
  object-fit: contain;
}
.heading{
  width: 100%;
  height: 16px;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  color: #434244;
}
.headerstyle{
  border-bottom: solid 2px #f3f2f3;
  width: 100%;
  padding-bottom: 4px;
}
.MuiCollapse-container .heading {
  font-weight: normal;
}

.accordions.MuiAccordionSummary-root{
  min-height: 0px !important;
}
.MuiAccordionSummary-root{
  min-height: 0px !important;
  padding: 0 12px 0 7px !important;
}
.MuiAccordion-rounded{
  border-radius: 0px !important;
}
.ui.accordion .title .dropdown.icon:before, .ui.accordion .accordion .title .dropdown.icon:before{
 content:url(/static/media/controls-expand-down-arrow.0841ef11.svg) !important;
 float: left !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.MuiAccordionSummary-content{
  order: 2;
  margin: 6px 11px !important;
}
.accordiondetails.MuiAccordionDetails-root{
  display: block !important;
  padding: 0px 20px 1px !important;
}

.modalgroup{
  margin: 4px 10px 8px 0;
  border-radius: 2px;
  background-color: #f3f2f3;
  padding: 8px 8px;
}
.modalatext{
  display: flex;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  margin: 0 0 5px;
  line-height: 1.08;
}
.carcode{
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #434244;
  margin: 0 0 5px;
  line-height: 1.08;
  
}
.contentpanel{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  width: 82%;
}
.constraintcontentpanel{
  position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row !important;
    font-size: 12px;
    min-width: 90%;
}
.datecontent{
  font-size: 12px;
  margin: 0 8px 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #434244;
}
.datetext{
  display: flex;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #434244;
  text-transform: uppercase;
}
.zone{
  display: flex;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #434244;
  text-transform: capitalize;
}
.divideline{
  border-right:solid 1px #e5e5e5;
  height: 30px;
  margin-left: 10px;
}
.volumestyle{
  margin-left: 12px;
}
.volume{
  display: flex;
}
.volumetext{
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
}
.mileage{
  margin: 0 0px 1px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #434244;
  line-height: 1.6;
}
.manufacturericon{
  height: 14px;
  margin: 3px 3px -1px 0px;
  object-fit: contain;
}
.modalname{
  margin: 1px 0 1px -1px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #434244;
}
.programtype{
  width: 33px;
  height: 14px;
  margin: 2px 0 0 8px;
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 0.93;
  letter-spacing: normal;
  color: #434244;
  text-transform: uppercase;
}
.spicode{
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 0.93;
  letter-spacing: normal;
  color: #434244;
}
.accordionsort{
  border-bottom: solid 2px #f3f2f3;
  background-color: #ffffff;
  padding: 0px 664px 4px 29px;
}
.sorttext{
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: normal;
  color: #000000;
}
.sorticon{
  width: 9px;
  height: 13px !important;
  margin: 3px 0 -3px 10px;
  object-fit: contain;
}

/* constraintcontent */
.constraintpanel{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 12px;
  width: 100%;
  padding-left: 9px;
  margin-top: 7px;
}
.contentdatefield{
  height: 13px;
  margin: 3px 44px 4px -66px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #434244;
}
.pooltext{
  min-width: 19%;
  width: 18%;
  height: 13px;
  margin: 3px 1px 4px 19px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #434244;
  text-align: left;
}
.minval{
  min-width: 66px;
  height: 13px;
  margin: 3px 27px 4px -7px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #434244;
  text-align: right;
}
.maxvalue{
  min-width: 66px;
  height: 13px;
  margin: 3px 43px 4px -7px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #434244;
  text-align: right;
}
.attributes{
  min-width: 250px;
  margin-top: -2px;
  text-align: left;
}
.programattribute{
  min-width: 54px;
  height: 15px;
  padding: 4px 6px 8px;
  opacity: 0.7;
  border-radius: 12px;
  background-color: rgba(187, 226, 250, 0.7);
  text-align: center;
  margin: -3px 0 -22px 15px;
}
.blnkprogramattribute{
  min-width: 54px;
  height: 15px;
  padding: 4px 6px 8px;
  text-align: center;
  margin: -3px 0 -22px 0;
}
.spittribute{
  min-width: 54px;
  height: 15px;
  padding: 4px 6px 8px;
  opacity: 0.7;
  border-radius: 12px;
  background-color: rgba(187, 226, 250, 0.7);
  text-align: center;
  margin: -3px 0 -22px 7px;
}
.blnkspittribute{
  min-width: 54px;
  height: 15px;
  padding: 4px 6px 8px;
  text-align: center;
  margin: -3px 0 -22px 7px;
}
.manattribute{
  min-width: 54px;
  height: 15px;
  padding: 4px 6px 8px;
  opacity: 0.7;
  border-radius: 12px;
  background-color: rgba(187, 226, 250, 0.7);
  text-align: center;
  margin: -3px 0 -22px 7px;
}
.nomanattributeattribut{
  min-width: 54px;
  height: 15px;
  padding: 4px 6px 8px;
  text-align: center;
  margin: -3px 0 -22px 7px;
}
.milesattribute{
  min-width: 54px;
  height: 15px;
  padding: 4px 6px 8px;
  opacity: 0.7;
  border-radius: 12px;
  background-color: rgba(187, 226, 250, 0.7);
  text-align: center;
  margin: -3px 0 -22px 7px;
}
.nomilesattribute{
  min-width: 54px;
  height: 15px;
  padding: 4px 6px 8px;
  text-align: center;
  margin: -3px 0 -22px 7px;
}
.constraintcontents{
  height: 37px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 2px #f3f2f3 !important;
}
.constraintdatate{
  min-width: 120px;
  text-align: right;
}

.att{
  margin-right: -35%;
  display: flex;
}
.constraintdelete{
  margin-left: 6%;
}

.constraintedit{
  margin-left: 5%;
}

.minFieldUI {
  width : 30% !important;
  min-width: 65px !important;
  height: 25px !important;
  min-height: 25px !important;
  margin-top: -7px !important;
}

.minFieldUI .MuiInputBase-input, .minFieldUISuccess .MuiInputBase-input, .minFieldUIError .MuiInputBase-input {
  padding: 8px 8px 8px 12px !important;
  font-size: 13px !important;
  margin-top: -4px;
}

.constraintcontentpanel>div>div.MuiFormControl-root {
  width: 77px !important;
}

.minFieldUI .MuiOutlinedInput-input, .minFieldUISuccess .MuiOutlinedInput-input, .minFieldUIError .MuiOutlinedInput-input {
  /* padding: 18.5px 14px; */
}

.minFieldUISuccess::before, .minFieldUIError::before {
  content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    display: block;
    border: 1.5px solid #E90133;
    border-radius: 3px;
    width: 30% !important;
    min-width: 65px !important;
    height: 24px !important;
    min-height: 24px !important;
    margin-top: -4px !important;
    margin-left: -4px;
    z-index: 1;
}

.minFieldUISuccess::after, .minFieldUIError::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 12px 0;
  border-color: transparent #E90133 transparent transparent;
  z-index: 1;
} 

.minFieldUISuccess, .minFieldUIError {
  background-color: transparent !important;
  min-width: 65px !important;
  width: 30% !important;
  height: 25px !important;
  min-height: 25px !important;
  border-color: transparent !important;
  border: 0 !important;
  margin-top: -7px !important;
}

.minFieldUISuccess::after {
  border-right-color: #006bbd;
}

.minFieldUISuccess::before {
  border-color: #006bbd;
} 

.cargroupstyle{
  width: 110px;
  height: 13px;
  margin: 1.5px 0 1.5px 23.5px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #808285;
  text-transform: capitalize !important;
}
.smooth-goto { 
  scroll-behavior: smooth; 
} 
.typeicons{
  display: flex;
}
.typeiconstyle{
  height: 14px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  color: #434244;
  margin: 1px 6px 1px 1px;
}

.MuiAccordion-root:after {
  position: absolute;
  content: "";
  top: 34px;
  left: 26px;
  background-color: #cccdd0;
  width: 2px;
  bottom: 10px;
}
.MuiAccordionSummary-expandIcon .MuiIconButton-label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;    
  background-color: #dfdfe1;
  border-radius: 4px;
  width: 16px;
  height: 16px;    
}
.MuiAccordionSummary-expandIcon .MuiSvgIcon-root {
  padding-top: 0px!important;
  margin-top: 0!important;
  padding-right: 0px;
}

.saveConstraints {
    display: flex;
    justify-content: flex-end;
    width: 10% !important;
}

.saveButtonStyle {
    min-width: -3%;
    margin-right: 10px;
    border: solid 1px #ffcc00;
    border-radius: 5px;
    height: 25px;
    display: flex;
    flex-wrap: wrap;
    margin-top: -4px;
    text-align: left;
    font-size: 12px;
    background-color: #ffcc00;
    box-shadow: 0 0 50px 0 rgb(0 0 0 / 10%);
}


.cancelButtonStyle {
    border: solid 1px #808285;
    border-radius: 5px;
    height: 25px;
    width: 60px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin-top: -4px;
}

/* .customTooltip > .tooltip {
    background-color: '#cccdd00' !important;
    font-size: 12px !important;
    color: #808285 !important;
} */

.imgOpacity {
    opacity: 0.5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  text-align: right;
  padding-right: 5px;
}

.minMaxFields {
    min-width: 177px;
    display: flex;
    width: 160px;
}
.pathcontenticons{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: solid 2px #f3f2f3 !important;
    height: 41px;
}
.pathcontentpanel{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    width: 82%;
    margin: 13px 10px 13px 20px;
}
.pathdeleteicon{
    margin: 9px 10px 10px 20px;
    cursor: pointer;
    min-width: 6px;
}
.patharrowicon{
    margin: 9px 10px 10px 20px;
    cursor: pointer;
    min-width: 10px;
}
.tocc{
    min-width: 30px;
    width:19%;
    text-align: left !important;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #434244;
    display: inline-block;
    margin-left: 12px;
}
.pathcost{
    margin-right: 2%;
    min-width: 72px;
    text-align: right !important;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: right;
    color: #434244;
}
.pathchoice{
    margin-right: 39%;
    min-width: 72px;
    text-align: right !important;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: right;
    color: #434244;
}
.toccgroup{
    width: 12%;
    height: 13px;
    margin: 0 -8px 0 -54px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #808285;
    text-transform: capitalize !important;
    word-break: break-all;
}
.anchoestyle{
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}
.toccstyle{
    min-width: 30px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #434244;
}
/* .upgradecontentdialog>div.MuiDialog-root.dialogbox>div.MuiBackdrop-root{
    background-color: rgba(0, 0, 0, 0.2) !important;
} */
 .MuiBackdrop-root{
    background-color: rgba(0, 0, 0, 0.2) !important;
} 
.MuiPaper-elevation24 {
    box-shadow: -2px 11px 15px -7px rgb(0 0 0 / 16%), 0px 24px 38px 3px rgb(0 0 0 / 0%), 0px 9px 46px 8px rgb(0 0 0 / 0%) !important;
}
.constraintactionbar{
  margin-top: -36px;
}

.fixedConstraintActionBar {
  width: 1104px !important;
}
.constrainttypes{
  background-color: white;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.constraintactions {
    background-color: white;
    -webkit-margin-before: 0px !important;
            margin-block-start: 0px !important;
    padding-top: 20px ;
    padding-bottom: 5px ;
    color: white;
    min-width: 1104px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
   }
   .conactionButtons>div:nth-child(1).Mui-focused{
     background-color: none !important;
   }
   .viewdropdown>div.MuiInputBase-input {
     padding: 0px 2px 7px 3px !important;
   }
   .viewdropdown>div.MuiSelect-select:focus {
    background-color: none !important;
  }
  
   .viewdropdown>svg.MuiSelect-icon{
    padding: 0px 2px 7px 2px !important;
   }
   .constraintfilterStyle {
    /* width: 175px; */
    display: flex;
    flex-direction: row;
    margin-top: 8px;
   }
   .constraintfilterStyle>div.MuiFormControl-root>div.viewdropdown{
     width: 121px !important;
     height: 32px;
   }
   .constraintfilterStyle>div.MuiFormControl-root>div.viewdropdown>div.MuiInputBase-input{
    padding: 0px 2px 7px 20px !important;
    height: 14px;
    font-size: 13px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #434244;
   }
   .conactionButtons {
     display: flex;
     flex-wrap: wrap;
     justify-content: flex-end;
     flex-direction: row;
     margin-top: 8px;
   }

   .conactionButtons >Button{
     padding: 14px !important; 
     margin: 5px !important; 
     text-transform: none !important;
     padding: 10px;
   }

   .imageIcon {
     margin: 10px;
      width: 24px !important;
  height: 28px;
   }

 .MuiMenuItem-root {
    font-size: 13px !important;
  }

    .MuiSelect-selectMenu {
      width: 165px !important;
      border-radius: 3px;
      margin-top: 5px !important;
      padding: 5px !important;
    }

    .MuiFormControl-root {
       margin-right: 10px !important;
    }

    .MuiSvgIcon-root {
      padding-top: 3px !important;
      margin-top: 0px !important;
      padding-right: 5px;
      
    }
.MuiTypography-body1 {
   font-family: customFontFamily !important;
}
.MuiOutlinedInput-notchedOutline {
    border-color: #808285 !important;
    border-radius: 3px !important;
    border-width: 1px !important;
}
.typebuttons{
  padding-left: 11px;
}
.activefontstyle{
  color: #000000 !important;
  font-weight: bold !important;
}
.types{
  /* min-width: 86px; */
  /* width: 57px; */
  height: 19px;
  margin: 4px -6px 4px -14px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #434244;
  font-family: customFontFamily !important;
  text-align: left;
  align-items: center;
  padding-right: 20px;
    padding-left: 20px;
    padding-top: 4px;
}
.typecount{
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #434244;
  font-family: customFontFamily !important;
  padding-right: 10px;
}
.constraintsearchbar{
  margin-right: 48%;
  margin-top: 8px;
  width: 100px;
  /* margin-left: -102px; */
}
.btnactive{
  border-radius: 4px !important;
  background-color: #ffed9b !important;
}
.typebuttons>button.MuiButton-root:hover{
  background-color:#ffffff !important;
}
.MuiTouchRipple-root{
  display: none !important;
}

.typebuttons>button.MuiButton-root.btnactive:hover{
  background-color: #ffed9b !important;
}
.typebuttons>button.MuiButton-root{
  height: 31px !important;
  flex-wrap: wrap;
  min-width: 80px;
  align-items: baseline;
}

.dividerStyle {
  width: 100%;
  margin-top: 15px;
}

.dialogClass {
 
    max-width: 770px !important;
  
}
.constraintfilterStyle>div>div.MuiInputBase-root::before{
  background-image: url(/static/media/icon_filter.99e715a3.svg);
  background-repeat: no-repeat;
  padding: 1px 0px 9px 10px;
}

/* filterstyles */
.presetdropdown{
  margin-left: -17px;
  width: 420px;
  height: 51px;
}
.presetdropdown>div>div.MuiInputBase-root{
  width: 156px;
  height: 29px;
  border: solid 1.5px #cccdd0 !important;
  border-radius: 5px !important;
  background-color: #ffffff;
}
.presets{
  width: 291px;
  height: 18px;
  margin: 0 30px 5px 0;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808285;
}
.botomborder{
  width: 648px;
  margin: -6px -45px -3px -26px;
  border-bottom: solid 1px #e4e4e5;
  position: static;
}

.cancelButton {
  border: solid 1px #808285;
  border-radius: 5px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 12px;
  margin: 6px;
  padding-top: 2px;
  background-color: transparent !important;
}

.filterPrimaryButton {
  border: solid 1px transparent;
  border-radius: 5px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 12px;
  background-color: #ffcc00;
  /* box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1); */
}

.filterSecondaryButton {
  border: solid 1px transparent;
  border-radius: 5px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 12px;
  background-color: #ffffff !important;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2) !important;
}

.filterCustomButton {
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  /* font-size: 12px; */
}

.filterPopOver>div.MuiPopover-paper {
  width: 200px;
  border-top-left-radius: 0px !important;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff !important;
  overflow-y: hidden !important;
  /* padding: 8px 16px 26px 10px; */
  border-radius: 5px;
  margin-top: 0.7px !important;
  /* border: solid 1px #eeeeee; */
}

.filterMargin {
  margin-left: 10px;
  margin-right: 10px;
}
div.is-disabled
{
  pointer-events: none;
  opacity: 0.7;
}
.muiDatePicker {
    margin-top: 0px !important;
    border: 1px solid #808285 !important;;
    border-radius: 3px !important;
    padding-left: 5px !important;
    width: 160px !important;
}

.calendarIcon {
    width: 22px;
    height: 20px;
    padding-right: 5px;
}

.rangeoption {
    width: 167px !important;
    min-width: 167px !important;
}
.pickermarging{
    margin-left: 8px;
}

.dropdownStyle {
    /* border: 1px solid #cccdd0 !important; */
    border-radius: 3px;
    height: 30px;
    min-width: 134px !important;
    width: 158px;
}
.dropdownStyle>div>div.MuiSelect-select.MuiSelect-select{
    padding: 1px 2px 1px 8px !important;
    height: 18px;
    font-size: 14px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #434244;
}
.andtext{
    height: 19.5px;
    margin: 8px 11px 8.5px 6px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #434244;
}
.MuiDialogContent-root{
    overflow-x: hidden !important;
}
.rangeoption>p{
    width: 254px;
    height: 18px;
    margin: 0 26px 5px 0;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #808285;
}

.constraintcustomDialog>div.MuiDialog-container{ 
    height: 95% !important;
    width: 95% !important;
}
.constraintcustomDialog>div>div.MuiDialog-paperScrollPaper {
    max-height: none;
    width: 90% !important;
    height: 90% !important;

    padding: 12px 6px 12px 6px;
    border-radius: 5px;
    background-color: #ffffff;
    display: flex;
}
.dialogtitle>h2.MuiTypography-h6{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
.dialogtitle>h2.MuiTypography-h6>span:nth-child(1){
  width: 121px;
  height: 16px;
  margin: 0 296px 0 0;
  font-size: 18px;
  font-family: customFontFamily;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.73;
  letter-spacing: normal;
  color: #434244;
}
.dialogtitle>h2.MuiTypography-h6>span:nth-child(2)>img.closeicon{
  width: 16px;
  height: 16px;
}
.dialogtitle>h2.MuiTypography-h6>span:nth-child(2){
  margin-top: -6px;
}
.dialogcontent.MuiTypography-body1{
  width: 356px;
  height: 13px;
  margin-top: 11px;
  margin-bottom: 18px;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  color: #434244 !important;
  font-family: customFontFamily;
}
.actionbuttons>button.MuiButton-root{
    text-transform: capitalize !important;
}
.actionbuttons>button:nth-child(1){
  padding: 14px !important;
  margin: 5px !important;
  border-radius: 4px;
  border: solid 1.5px #808285;
  background-color: #ffffff;
}
.actionbuttons>button:nth-child(2){
    padding: 14px !important;
    margin: 5px !important;
    border: solid 1.5px;
    border-radius: 4px;
    background-color: #ffcc00;
  }
.actionbuttons>button>span.MuiButton-label{
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: center;
  color: #434244;
}
.constraintcustomDialog>div>div.MuiDialog-paperWidthSm{
  max-width: 676px !important;
}
.constraintsort{
    border-bottom: solid 2px #f3f2f3;
    background-color: #ffffff;
    padding: 0px 10px 4px 32px;
}

.fixedHeader {
    width: 1104px !important;
}
.groupsort{
    min-width: 164px;
    display: inline-block;
}
.attheader{
    margin-right: -28px;
}
.datemargin{
    min-width: 173px;
    display: inline-block;
    margin: 3px -35px 4px 30px;
}
.minmargin{
    min-width: 94px !important;
    display: inline-block;
    margin: 3px 16px 4px 30px;
}
.maxmargin{
    min-width: 105px;
    display: inline-block;
    margin: 3px 25px 4px -35px;
}
.attributemargin{
    min-width: 262px;
    display: inline-block;
    margin: 3px 76px 4px -38px;
}
.norecords{
    text-align: center;
    font-size: 12px;
    margin-top: 32px;
}
.applyFreezMonthColor{
    color:#ffcc00;
  }


  .mainActionBar {
    background-color: white;
    -webkit-margin-before: -36px !important;
            margin-block-start: -36px !important;
    padding-top: 5px ;
    padding-bottom: 5px ;
    padding-right: 10px;
    color: white;
    min-width: 1104px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
   }
   .actionButtons>div:nth-child(1).Mui-focused{
     background-color: none !important;
   }
   .viewdropdown>div.MuiInputBase-input {
     padding: 0px 2px 7px 3px !important;
   }
   .viewdropdown>div.MuiSelect-select:focus {
    background-color: none !important;
  } 

   .viewdropdown>svg.MuiSelect-icon{
    padding: 0px 2px 7px 2px !important;
   }

   .saveButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
   }

   .actionButtons {
     display: flex;
     flex-wrap: wrap;
     justify-content: flex-end;
     flex-direction: row;
   }

    .filterMain {
     display: flex;
     flex-wrap: wrap;
     flex-direction: row;
     margin-top: 24px;
   }

   .actionButtons >Button{
     padding: 14px !important; 
     margin: 5px !important; 
     text-transform: none !important;
     padding: 10px;
   }

    .MuiTypography-gutterBottom {
    margin-bottom: 0 !important;
}

.MuiMenuItem-root {
  font-family: customFontFamily !important;
  height: 28px;
  border-bottom: 1px solid lightgray;
}

.MuiInputBase-root {
    font-family: customFontFamily !important;
}

.MuiTypography-body1 {
   font-family: customFontFamily !important;
}

.muiDatePicker {
    margin-top: 0px !important;
    border: 1px solid #808285 !important;
    border-radius: 3px !important;
    padding-left: 5px !important;
    width: 165px !important;
}

.MuiOutlinedInput-notchedOutline {
    /* border-color: #808285 !important;
    border-radius: 3px !important;
    border-width: 1px !important; */
}

.filterStyle {
 min-width: 63%;
 display: flex;
 flex-direction: row;
}

.viewdropdown{
  width: 90px;
  height: 34px;
  margin: 0 0 0 18px;
  padding: 1px;
  border-radius: 5px;
  background-color: #ffcc00;
}
.actionButtons>div.MuiFormControl-root{
  margin-top: 7px !important;
}

.primaryButtonParameters {
  border: solid 1px;
    border-radius: 5px;
    background-color: #ffcc00;
    height: 30px;
    margin: 6px;
    padding-top: 2px;
}

.secondaryButtonParameters {
  border: solid 1px gray;
    border-radius: 5px;
    height: 31px;
    margin: 8px;
    padding-top: 2px;
}

.MuiButton-label {
  text-transform: none !important;
}
.searchbar{
  margin-right: 48%;
  margin-top: 8px;
}

.MuiButton-text {
  padding: 0px 8px !important;
}
.upgradeactionbar{
    margin-top: -41px;
  }

  .fixedUpgradeActionBar {
    width: 1104px;
  }
.upgradebuttons{
    background-color: white;
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .upgradebtnstyel{
    height: 38px !important;
    width: 274px;
    margin-left: 11px;
  }
.upgradeactions{
    background-color: white;
    -webkit-margin-before: 0px !important;
            margin-block-start: 0px !important;
    padding-top: 5px ;
    padding-bottom: 5px ;
    color: white;
    min-width: 1104px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}
.upgradebtnstyel>button.MuiButton-root.btnactive{
  border-radius: 4px !important;
  background-color: #ffed9b !important;
}
.upgradebtnstyel>button.MuiButton-root:hover{
    background-color:#ffffff !important;
  }
.upgradebtnstyel>button.MuiButton-root.btnactive:hover{
    background-color: #ffed9b !important;
  }
  .upgradebtnstyel>button>span.MuiButton-label{
    width: 172px;
    height: 27px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .upgradefilterStyle {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
}
.upgradesaveButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
   }
.upgradeprimaryButton {
    border: solid 1px;
    border-radius: 5px;
    background-color: #ffcc00;
    height: 33px;
    margin: 6px;
    padding-top: 2px;
  }
  
.upgradesecondaryButton {
    border: solid 1px gray;
    border-radius: 5px;
    height: 31px;
    margin: 8px;
    padding-top: 2px;
  }
.searchinputRounded{
    border-radius: 4px !important;
    border: solid 0px #808285 !important;
    background-color: #ffffff;
    width: 209px;
    height: 29px;
    margin-left: 11px !important;
}
.searchinputRounded>div>fieldset.PrivateNotchedOutline-root-18{
    border-style: none !important;
}
.searchinputRounded>div>input{
    height: 15px;
    margin: 0 0 0 -8px;
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.07;
    letter-spacing: normal;
    color: #5a585c;
}
.upgradepathsort{
    border-bottom: solid 2px #f3f2f3;
    background-color: #ffffff;
    padding: 0px 10px 4px 32px;
}

.upgradepathsortFixed {
    width: 1104px !important
}
.pathgroupsort {
    min-width: 164px;
    display: inline-block;
}
.costmargin {
    min-width: 134px;
    display: inline-block;
    margin: 3px -42px 4px 130px;
}
.ultimatemargin {
    min-width: 139px !important;
    display: inline-block;
    margin: 3px -4px 4px 7px;
}
.pathsorticon {
    width: 9px;
    height: 13px !important;
    margin: 3px 0 -3px 10px;
    object-fit: contain;
}
.pathattributemargin {
    min-width: 262px;
    display: inline-block;
    margin: 3px 82px 4px 83px;
}
.pathsorttext {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: normal;
    color: #000000;
}
.pathattheader {
    margin-right: -28px;
}
.mainHeader {
    background-color: white;
    -webkit-margin-before: -36px !important;
            margin-block-start: -36px !important;
    padding-bottom: 5px;
    color: white;
    min-width: 1104px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .actionButtons>div:nth-child(1).Mui-focused {
    background-color: none !important;
  }
  
  .viewdropdown>div.MuiInputBase-input {
    padding: 0px 2px 7px 3px !important;
  }
  
  .viewdropdown>div.MuiSelect-select:focus {
    background-color: none !important;
  }
  
  .viewdropdown>svg.MuiSelect-icon {
    padding: 0px 2px 7px 2px !important;
  }
  
  .saveButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
    min-width: 36%;
    margin-right: 10px;
    margin-top: 10px;
  }
  
  .actionButtons {
    display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      flex-direction: row;
      min-width: 36%;
      margin-right: 10px;
      margin-top: 15px;
  }
  
  .filterMain {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 24px;
  }
  
  .actionButtons>Button {
    padding: 14px !important;
    margin: 5px !important;
    text-transform: none !important;
    padding: 10px;
  }
  
  .imageIcon {
    margin: 10px;
    width: 24px !important;
    height: 28px;
  }
  
  .MuiMenuItem-root {
    font-size: 13px !important;
  }
  
  .MuiSelect-selectMenu {
    width: 165px !important;
    border-radius: 3px;
    margin-top: 5px !important;
    padding: 5px !important;
  }
  
  .MuiFormControl-root {
    margin-right: 10px !important;
  }
  
  .MuiSvgIcon-root {
    padding-top: 3px !important;
    margin-top: 0px !important;
    padding-right: 5px;
  }
  
  .MuiSelect-select {
    /* width: 120px !important; */
    border-radius: 3px !important;
    /* border: 1px solid grey !important; */
    /* background: #ffcc00 !important;  */
  }
  
  .dropdownfields {
    display: flex;
    flex-direction: column;
    width: 176px;
    margin-top: 5px;
  }
  
  .flexContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
  }
  
  .MuiTypography-gutterBottom {
    margin-bottom: 0 !important;
  }
  
  .MuiMenuItem-root {
    font-family: customFontFamily !important;
    height: 28px;
    border-bottom: 1px solid lightgray;
  }
  
  .MuiInputBase-root {
    font-family: customFontFamily !important;
  }
  
  .MuiTypography-body1 {
    font-family: customFontFamily !important;
  }
  
  .muiDatePicker {
    margin-top: 0px !important;
    border: 1px solid #808285 !important;
    border-radius: 3px !important;
    padding-left: 5px !important;
    width: 165px !important;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: #808285 !important;
    border-radius: 3px !important;
    border-width: 1px !important;
  }
  
  .filterStyle {
    /* min-width: 63%;
    display: flex;
    flex-direction: row; */
    display: flex;
    flex-direction: row;
    margin-top: 15px;
  }
  
  .viewdropdown {
    width: 90px;
    height: 34px;
    margin: 0 0 0 18px;
    padding: 1px;
    border-radius: 5px;
    background-color: #ffcc00;
  }
  
  .actionButtons>div.MuiFormControl-root {
    margin-top: 7px !important;
  }
  
  .primaryButton {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding-top: 2px;
    background-color: #ffcc00;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  }
  
  .secondaryButton {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding-top: 2px;
    background-color: transparent !important;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  }
  
  .MuiButton-label {
    text-transform: none !important;
  }
  
  .searchbar {
    margin-right: 48%;
    margin-top: 8px;
  }
  
  .MuiDialog-paperWidthSm {
    max-width: 675px !important;
  }
  
  .imageSpacing {
    padding-right: 15px !important;
    min-width: 24px !important;
  }
  
  .MuiListItem-root.Mui-selected {
    background-color: transparent !important;
  }
  
  .actiobarPopOver>div.MuiPopover-paper {
    border-top-right-radius: 0px !important;
    box-shadow: -5px 10px 20px 0px rgba(0, 0, 0, 0.1) !important;
    background-color: #ffffff !important;
    overflow-y: hidden !important;
    margin-top: 0.7px !important;
  }
  
  div.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  
  .MuiDivider-root {
    background-color: #f3f2f3;
  }
  
.rangeinput{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.andtext{
    height: 19.5px;
    margin: 8px 11px 8.5px 6px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #434244;
}
.rangemargin{
    margin-left: 8px;
}
.rangeinput>div>div.MuiFormControl-root.MuiTextField-root{
    width: 165px !important;
    margin-top: 0px !important;
}
.rangeinput>span.displayflx{
    display: flex;
    margin-top: -5px;
}
 .dropdownfields {
      display: flex;
      flex-direction: column;
      width: 176px;
      margin-top: 5px;
} 

.MuiSelect-select.MuiSelect-select {
    padding-left: 8px !important;
}

.dropdownStyle {
    /* border: 1px solid #808285 !important; */
    border-radius: 3px;
    height: 30px;
    padding-bottom: 5px;
     width: 167px;
           min-width: 120px;
}

    .inputFields {
      display: flex;
      flex-direction: column;
      width: 176px !important;
      margin-left: 10px !important;
    }
.muiDatePicker {
    margin-top: 0px !important;
    border: 1px solid #808285 !important;
    border-radius: 3px !important;
    padding-left: 5px !important;
    width: 160px !important;
}

.calendarIcon {
    width: 22px;
    height: 20px;
    padding-right: 5px;
}

.dropdownFilter {
    width: 160px !important;
    min-width: 160px !important;
}

.datepickerBar {
    display: flex;
    flex-direction: row;
    margin-top: 10px !important;
}

.datesFields {
    margin-left: 10px !important;
}

.dropdownStyle {
    /* border: 1px solid #808285 !important; */
    border-radius: 3px;
    height: 30px;
    /* width: 167px;
    min-width: 120px; */
}

.mainHeader {
    background-color: white;
    -webkit-margin-before: -36px !important;
            margin-block-start: -36px !important;
    padding-bottom: 5px;
    color: white;
    min-width: 1104px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .actionButtons>div:nth-child(1).Mui-focused {
    background-color: none !important;
  }
  
  .viewdropdown>div.MuiInputBase-input {
    padding: 0px 2px 7px 3px !important;
  }
  
  .viewdropdown>div.MuiSelect-select:focus {
    background-color: none !important;
  }
  
  .viewdropdown>svg.MuiSelect-icon {
    padding: 0px 2px 7px 2px !important;
  }
  
  .saveButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
    min-width: 36%;
    margin-right: 10px;
    margin-top: 10px;
  }
  
  .actionButtons {
    display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      flex-direction: row;
      min-width: 36%;
      margin-right: 10px;
      margin-top: 15px;
  }
  
  .filterMain {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 24px;
  }
  
  .actionButtons>Button {
    padding: 14px !important;
    margin: 5px !important;
    text-transform: none !important;
    padding: 10px;
  }
  
  .imageIcon {
    margin: 10px;
    width: 24px !important;
    height: 28px;
  }
  
  .MuiMenuItem-root {
    font-size: 13px !important;
  }
  
  .MuiSelect-selectMenu {
    width: 165px !important;
    border-radius: 3px;
    margin-top: 5px !important;
    padding: 5px !important;
  }
  
  .MuiFormControl-root {
    margin-right: 10px !important;
  }
  
  .MuiSvgIcon-root {
    padding-top: 3px !important;
    margin-top: 0px !important;
    padding-right: 5px;
  }
  
  .MuiSelect-select {
    /* width: 120px !important; */
    border-radius: 3px !important;
    /* border: 1px solid grey !important; */
    /* background: #ffcc00 !important;  */
  }
  
  .dropdownfields {
    display: flex;
    flex-direction: column;
    width: 176px;
    margin-top: 5px;
  }
  
  .flexContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
  }
  
  .MuiTypography-gutterBottom {
    margin-bottom: 0 !important;
  }
  
  .MuiMenuItem-root {
    font-family: customFontFamily !important;
    height: 28px;
    border-bottom: 1px solid lightgray;
  }
  
  .MuiInputBase-root {
    font-family: customFontFamily !important;
  }
  
  .MuiTypography-body1 {
    font-family: customFontFamily !important;
  }
  
  .muiDatePicker {
    margin-top: 0px !important;
    border: 1px solid #808285 !important;
    border-radius: 3px !important;
    padding-left: 5px !important;
    width: 165px !important;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: #808285 !important;
    border-radius: 3px !important;
    border-width: 1px !important;
  }
  
  .filterStyle {
    /* min-width: 63%;
    display: flex;
    flex-direction: row; */
    display: flex;
    flex-direction: row;
    margin-top: 15px;
  }
  
  .viewdropdown {
    width: 90px;
    height: 34px;
    margin: 0 0 0 18px;
    padding: 1px;
    border-radius: 5px;
    background-color: #ffcc00;
  }
  
  .actionButtons>div.MuiFormControl-root {
    margin-top: 7px !important;
  }
  
  .primaryButton {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding-top: 2px;
    background-color: #ffcc00;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  }
  
  .secondaryButton {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding-top: 2px;
    background-color: transparent !important;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  }
  
  .MuiButton-label {
    text-transform: none !important;
  }
  
  .searchbar {
    margin-right: 48%;
    margin-top: 8px;
  }
  
  .MuiDialog-paperWidthSm {
    max-width: 675px !important;
  }
  
  .imageSpacing {
    padding-right: 15px !important;
    min-width: 24px !important;
  }
  
  .MuiListItem-root.Mui-selected {
    background-color: transparent !important;
  }
  
  .actiobarPopOver>div.MuiPopover-paper {
    border-top-right-radius: 0px !important;
    box-shadow: -5px 10px 20px 0px rgba(0, 0, 0, 0.1) !important;
    background-color: #ffffff !important;
    overflow-y: hidden !important;
    margin-top: 0.7px !important;
  }
  
  div.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  
  .MuiDivider-root {
    background-color: #f3f2f3;
  }
  
.planassumptionmainHeader {
    background-color: white;
    -webkit-margin-before: -36px !important;
            margin-block-start: -36px !important;
    padding-bottom: 5px;
    color: white;
    min-width: 800px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 950px;
    margin-left: 183px;
  }
  
  .planassumptionactionButtons>div:nth-child(1).Mui-focused {
    background-color: none !important;
  }
  
  .viewdropdown>div.MuiInputBase-input {
    padding: 0px 2px 7px 3px !important;
  }
  
  .viewdropdown>div.MuiSelect-select:focus {
    background-color: none !important;
  }
  
  .viewdropdown>svg.MuiSelect-icon {
    padding: 0px 2px 7px 2px !important;
  }
  
  .planassumptionsaveButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
    min-width: 56%;
    margin-right: 10px;
    margin-top: 15px;
  }
  
  .planassumptionactionButtons {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      flex-direction: row;
      min-width: 0%;
      margin-right: 10px;
      margin-top: 15px;
  }
  
  .filterMain {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 24px;
  }

  .filterMainForRange {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 24px;
    width: 100%;
  }
  
  .planassumptionactionButtons>Button {
    padding: 14px !important;
    margin: 5px !important;
    text-transform: none !important;
    padding: 10px;
  }
  
  .imageIcon {
    margin: 10px;
    width: 24px !important;
    height: 28px;
  }
  
  .MuiMenuItem-root {
    font-size: 13px !important;
  }
  
  .MuiSelect-selectMenu {
    width: 165px !important;
    border-radius: 3px;
    margin-top: 5px !important;
    padding: 5px !important;
  }
  
  .MuiFormControl-root {
    margin-right: 10px !important;
  }
  
  .MuiSvgIcon-root {
    padding-top: 3px !important;
    margin-top: 0px !important;
    padding-right: 5px;
  }
  
  .MuiSelect-select {
    /* width: 120px !important; */
    border-radius: 3px !important;
    /* border: 1px solid grey !important; */
    /* background: #ffcc00 !important;  */
  }
  
  .dropdownfields {
    display: flex;
    flex-direction: column;
    width: 176px;
    margin-top: 5px;
  }
  
  .flexContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
  }
  
  .MuiTypography-gutterBottom {
    margin-bottom: 0 !important;
  }
  
  .MuiMenuItem-root {
    font-family: customFontFamily !important;
    height: 28px;
    border-bottom: 1px solid lightgray;
  }
  
  .MuiInputBase-root {
    font-family: customFontFamily !important;
  }
  
  .MuiTypography-body1 {
    font-family: customFontFamily !important;
  }
  
  .muiDatePicker {
    margin-top: 0px !important;
    border: 1px solid #808285 !important;
    border-radius: 3px !important;
    padding-left: 5px !important;
    width: 165px !important;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: #808285 !important;
    border-radius: 3px !important;
    border-width: 1px !important;
  }
  
  .planassumptionfilterStyle {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    min-width: 47%;
  }
  
  .viewdropdown {
    width: 90px;
    height: 34px;
    margin: 0 0 0 18px;
    padding: 1px;
    border-radius: 5px;
    background-color: #ffcc00;
  }
  
  .planassumptionactionButtons>div.MuiFormControl-root {
    margin-top: 7px !important;
  }
  
  .primaryButton {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding-top: 2px;
    background-color: #ffcc00;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  }
  
  .secondaryButton {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding-top: 2px;
    background-color: transparent !important;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  }
  
  .MuiButton-label {
    text-transform: none !important;
  }
  
  .planassumptionsearchbar {
    margin-right: 0%;
    margin-top: 8px;
  }
  
  .MuiDialog-paperWidthSm {
    max-width: 675px !important;
  }
  
  .imageSpacing {
    padding-right: 15px !important;
    min-width: 24px !important;
  }
  
  .MuiListItem-root.Mui-selected {
    background-color: transparent !important;
  }
  
  .actiobarPopOver>div.MuiPopover-paper {
    border-top-right-radius: 0px !important;
    box-shadow: -5px 10px 20px 0px rgba(0, 0, 0, 0.1) !important;
    background-color: #ffffff !important;
    overflow-y: hidden !important;
    margin-top: 0.7px !important;
  }
  
  div.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  
  .MuiDivider-root {
    background-color: #f3f2f3;
  }
  .deleterecords{
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    margin: 6px;
    padding-top: 2px;
    cursor: pointer;
  }
  
.planassumptiontabs{
    display: flex;
}
.planassumptionbgcolor{
    background-color:#ffffff;
    /* height: 650px; */
    min-width: 1000px;
    width: 1110px;
}
.planassumptiontabs>div>div:nth-child(3)>div>button{
    margin: 0 0 5px 12px !important;
}

.planassumptiontabs>div>div:nth-child(3)>div>button:active{
    border-radius: 4px !important;
    background-color: #ffed9b !important;
    color: #000000;
}

.planassumptiontabpanel{
    min-width: 942px;
    margin-top: -35px;
}
.planassumptiontabpanel>div>p.MuiTypography-body1{
    width:917px;
}
.planassumptiontabs>div.MuiTabs-vertical{
    min-width: 18%;
    /* height: 528px; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: -91px;
    margin-left: -7px;
}

@-webkit-keyframes copyinprogress_sub_active {
    0%    {background-image: linear-gradient(to right, gold ,gold ,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b)}
    10%   {background-image: linear-gradient(to right, #ffed9b,gold ,gold ,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b)}
    20%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,gold ,gold ,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b)}
    30%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,gold ,gold ,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b)}
    40%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,gold ,gold ,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b)}
    50%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,gold ,gold ,#ffed9b,#ffed9b,#ffed9b,#ffed9b)}
    60%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,gold ,gold ,#ffed9b,#ffed9b,#ffed9b)}
    70%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,gold ,gold ,#ffed9b,#ffed9b)}
    80%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,gold ,gold ,#ffed9b)}
    90%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,gold ,gold )}
    100%  {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,gold )}
  }

@keyframes copyinprogress_sub_active {
    0%    {background-image: linear-gradient(to right, gold ,gold ,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b)}
    10%   {background-image: linear-gradient(to right, #ffed9b,gold ,gold ,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b)}
    20%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,gold ,gold ,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b)}
    30%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,gold ,gold ,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b)}
    40%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,gold ,gold ,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b)}
    50%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,gold ,gold ,#ffed9b,#ffed9b,#ffed9b,#ffed9b)}
    60%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,gold ,gold ,#ffed9b,#ffed9b,#ffed9b)}
    70%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,gold ,gold ,#ffed9b,#ffed9b)}
    80%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,gold ,gold ,#ffed9b)}
    90%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,gold ,gold )}
    100%  {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,gold )}
  }
  @-webkit-keyframes copyinprogress_sub {
    0%    {background-image: linear-gradient(to right, gold ,gold ,white,white,white,white,white,white,white,white,white)}
    10%   {background-image: linear-gradient(to right, white,gold ,gold ,white,white,white,white,white,white,white,white)}
    20%   {background-image: linear-gradient(to right, white,white,gold ,gold ,white,white,white,white,white,white,white)}
    30%   {background-image: linear-gradient(to right, white,white,white,gold ,gold ,white,white,white,white,white,white)}
    40%   {background-image: linear-gradient(to right, white,white,white,white,gold ,gold ,white,white,white,white,white)}
    50%   {background-image: linear-gradient(to right, white,white,white,white,white,gold ,gold ,white,white,white,white)}
    60%   {background-image: linear-gradient(to right, white,white,white,white,white,white,gold ,gold ,white,white,white)}
    70%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,gold ,gold ,white,white)}
    80%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,gold ,gold ,white)}
    90%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,white,gold ,gold )}
    100%  {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,white,white,gold )}
  }
  @keyframes copyinprogress_sub {
    0%    {background-image: linear-gradient(to right, gold ,gold ,white,white,white,white,white,white,white,white,white)}
    10%   {background-image: linear-gradient(to right, white,gold ,gold ,white,white,white,white,white,white,white,white)}
    20%   {background-image: linear-gradient(to right, white,white,gold ,gold ,white,white,white,white,white,white,white)}
    30%   {background-image: linear-gradient(to right, white,white,white,gold ,gold ,white,white,white,white,white,white)}
    40%   {background-image: linear-gradient(to right, white,white,white,white,gold ,gold ,white,white,white,white,white)}
    50%   {background-image: linear-gradient(to right, white,white,white,white,white,gold ,gold ,white,white,white,white)}
    60%   {background-image: linear-gradient(to right, white,white,white,white,white,white,gold ,gold ,white,white,white)}
    70%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,gold ,gold ,white,white)}
    80%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,gold ,gold ,white)}
    90%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,white,gold ,gold )}
    100%  {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,white,white,gold )}
  }


  .planassumptionactionButtons {
    min-width: 50%;
}

.planassumptionmainHeader {
    background-color: transparent;
    width: auto;
}

.planassumptionbgcolor {
    /* height: 750px; */
    min-width: inherit;
    width: auto;
}

.planassumptiontabpanel>div>p.MuiTypography-body1 {
    width: auto;
}

.planassumptiontabpanel {
    min-width: 82%;
    width: 100%;
}

/* .margin {
    margin-right: 8px
} */

.paddingtarget .MuiBox-root {
    padding-right: 0px !important;
}
.constraintactions {
    min-width: auto !important;
}
.mainHeader {
    min-width: auto !important;
}
.mainActionBar {
    min-width: auto !important;
}
.upgradeactions {
    min-width: auto !important;
}
.planassumptionfilterStyle {
    /* min-width: 55%; */
    margin-left: 46px;
}
.planassumptionactionButtons {
    min-width: 30%;
}

/* */
@media screen and (max-width: 4000px) {
    #grid-theme-wrapper>div>div{
        /* height: 1500px !important; */
        height: initial !important;
    }
    /* .margin {
        height: 1800px !important;
    } */
    /* .planassumptionbgcolor {
        height: 1400px;
    } */
    .planassumptionfilterStyle {
        margin-left: 500px !important;
    }
}

@media screen and (max-width: 2600px) {
    #grid-theme-wrapper>div>div{
        /* height: 900px !important; */
        height: initial !important;
    }
    /* .margin {
        height: 1230px !important;
    } */
    /* .planassumptionbgcolor {
        height: 1050px;
    } */
    .constraints-adjust #grid-theme-wrapper>div>div>div{
        height: 800px !important;
    }
    .planassumptionfilterStyle {
        margin-left: 12% !important;
    }
    
}


@media screen and (max-width: 1500px) {
    #grid-theme-wrapper>div>div{
        /* height: 600px !important; */
        height: initial !important;
    }
    .constraints-adjust #grid-theme-wrapper>div>div>div{
        height: 590px !important;
    }
    /* .margin {
        height: 920px !important;
    } */
    /* .planassumptionbgcolor {
        height: 750px;
    } */
    .planassumptionfilterStyle {
        margin-left: 50px !important;
    }
    
}


@media (min-width: 600px) {
    .MuiTab-root {
        min-width: 160px;
        max-width: 100% !important;
    }
}
.constraintactionbar{
  margin-top: -36px;
}

.fixedConstraintActionBar {
  width: 1104px !important;
}
.constrainttypes{
  background-color: white;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.constraintactions {
    background-color: white;
    -webkit-margin-before: 0px !important;
            margin-block-start: 0px !important;
    padding-top: 20px ;
    padding-bottom: 5px ;
    color: white;
    min-width: 1104px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
   }
   .conactionButtons>div:nth-child(1).Mui-focused{
     background-color: none !important;
   }
   .viewdropdown>div.MuiInputBase-input {
     padding: 0px 2px 7px 3px !important;
   }
   .viewdropdown>div.MuiSelect-select:focus {
    background-color: none !important;
  }
  
   .viewdropdown>svg.MuiSelect-icon{
    padding: 0px 2px 7px 2px !important;
   }
   .constraintfilterStyle {
    /* width: 175px; */
    display: flex;
    flex-direction: row;
    margin-top: 8px;
   }
   .constraintfilterStyle>div.MuiFormControl-root>div.viewdropdown{
     width: 121px !important;
     height: 32px;
   }
   .constraintfilterStyle>div.MuiFormControl-root>div.viewdropdown>div.MuiInputBase-input{
    padding: 0px 2px 7px 20px !important;
    height: 14px;
    font-size: 13px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #434244;
   }
   .conactionButtons {
     display: flex;
     flex-wrap: wrap;
     justify-content: flex-end;
     flex-direction: row;
     margin-top: 8px;
   }

   .conactionButtons >Button{
     padding: 14px !important; 
     margin: 5px !important; 
     text-transform: none !important;
     padding: 10px;
   }

   .imageIcon {
     margin: 10px;
      width: 24px !important;
  height: 28px;
   }

 .MuiMenuItem-root {
    font-size: 13px !important;
  }

    .MuiSelect-selectMenu {
      width: 165px !important;
      border-radius: 3px;
      margin-top: 5px !important;
      padding: 5px !important;
    }

    .MuiFormControl-root {
       margin-right: 10px !important;
    }

    .MuiSvgIcon-root {
      padding-top: 3px !important;
      margin-top: 0px !important;
      padding-right: 5px;
      
    }
.MuiTypography-body1 {
   font-family: customFontFamily !important;
}
.MuiOutlinedInput-notchedOutline {
    border-color: #808285 !important;
    border-radius: 3px !important;
    border-width: 1px !important;
}
.typebuttons{
  padding-left: 11px;
}
.activefontstyle{
  color: #000000 !important;
  font-weight: bold !important;
}
.types{
  /* min-width: 86px; */
  /* width: 57px; */
  height: 19px;
  margin: 4px -6px 4px -14px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #434244;
  font-family: customFontFamily !important;
  text-align: left;
  align-items: center;
  padding-right: 20px;
    padding-left: 20px;
    padding-top: 4px;
}
.typecount{
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #434244;
  font-family: customFontFamily !important;
  padding-right: 10px;
}
.constraintsearchbar{
  margin-right: 48%;
  margin-top: 8px;
  width: 100px;
  /* margin-left: -102px; */
}
.btnactive{
  border-radius: 4px !important;
  background-color: #ffed9b !important;
}
.typebuttons>button.MuiButton-root:hover{
  background-color:#ffffff !important;
}
.MuiTouchRipple-root{
  display: none !important;
}

.typebuttons>button.MuiButton-root.btnactive:hover{
  background-color: #ffed9b !important;
}
.typebuttons>button.MuiButton-root{
  height: 31px !important;
  flex-wrap: wrap;
  min-width: 80px;
  align-items: baseline;
}

.dividerStyle {
  width: 100%;
  margin-top: 15px;
}

.dialogClass {
 
    max-width: 770px !important;
  
}
.constraintfilterStyle>div>div.MuiInputBase-root::before{
  background-image: url(/static/media/icon_filter.99e715a3.svg);
  background-repeat: no-repeat;
  padding: 1px 0px 9px 10px;
}

/* filterstyles */
.presetdropdown{
  margin-left: -17px;
  width: 420px;
  height: 51px;
}
.presetdropdown>div>div.MuiInputBase-root{
  width: 156px;
  height: 29px;
  border: solid 1.5px #cccdd0 !important;
  border-radius: 5px !important;
  background-color: #ffffff;
}
.presets{
  width: 291px;
  height: 18px;
  margin: 0 30px 5px 0;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808285;
}
.botomborder{
  width: 648px;
  margin: -6px -45px -3px -26px;
  border-bottom: solid 1px #e4e4e5;
  position: static;
}

.cancelButton {
  border: solid 1px #808285;
  border-radius: 5px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 12px;
  margin: 6px;
  padding-top: 2px;
  background-color: transparent !important;
}

.filterPrimaryButton {
  border: solid 1px transparent;
  border-radius: 5px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 12px;
  background-color: #ffcc00;
  /* box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1); */
}

.filterSecondaryButton {
  border: solid 1px transparent;
  border-radius: 5px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 12px;
  background-color: #ffffff !important;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2) !important;
}

.filterCustomButton {
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  /* font-size: 12px; */
}

.filterPopOver>div.MuiPopover-paper {
  width: 200px;
  border-top-left-radius: 0px !important;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff !important;
  overflow-y: hidden !important;
  /* padding: 8px 16px 26px 10px; */
  border-radius: 5px;
  margin-top: 0.7px !important;
  /* border: solid 1px #eeeeee; */
}

.filterMargin {
  margin-left: 10px;
  margin-right: 10px;
}
div.is-disabled
{
  pointer-events: none;
  opacity: 0.7;
}
.moveConstraintsaveButtons{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: row;
  min-width: 99%;
  margin-right: 10px;
  margin-top: 10px;
}
.moveConstraintactionButtons>div:nth-child(1).Mui-focused {
  background-color: none !important;
}
.moveConstraintactionButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: row;
  min-width: 99%;
  margin-right: 10px;
  margin-top: 15px;
}
.moveConstraintactionButtons>Button {
  padding: 14px !important;
  margin: 5px !important;
  text-transform: none !important;
  padding: 10px;
}
.moveConstraintactionButtons>div.MuiFormControl-root {
  margin-top: 7px !important;
}
.allplansheader{
    width: 193px;
    height: 38px;
    margin: 30px 816px 30px 28px;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: -1px;
    color: #000000;
    margin-top: -16px;
    padding-top: 8px;
}
.allplansmargin{
    margin-left: 5px;
    margin-top: 20px;
    margin-right: 40px;
}
.flexitems{
  display: flex !important;
  justify-content: space-between;
  margin-right: -25px;
}

.planAddButton{
    width: 67px;
    border: solid 1px  #ffcc00;
    border-radius: 5px;
    background-color: #ffcc00;
    height: 33px;
}
.addlabel{
    width: 27px;
    height: 14px;
    margin: 0 0 1px 6px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #434244;
    cursor: pointer;
  }
  .planAddButton>button.MuiButton-text{
    padding: 11px 11px !important;
  }
  .planAddButton>button>span{
    margin-top: -3px !important;
  }
  .addblur{
    -webkit-filter: blur(0.4px);
            filter: blur(0.4px);
  }
.tablecontainer {
  width: 1102px;
  height: auto;
  margin: 1px 31px 49px 27px;
  padding: 0 0 0px;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 0 rgb(67 66 68 / 15%) !important;
  background-color: #ffffff;
}

td.MuiTableCell-root.MuiTableCell-body.planidlink {
  width: 43px;
  height: 16px;
  margin: 4px 26px 13px 19px;
  font-family: customFontFamily !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.14 !important;
  letter-spacing: normal !important;
}

td.MuiTableCell-root.MuiTableCell-body.planidlink {
  cursor: pointer;
  color: #006bbd !important;
}

.cursorpoint {
  cursor: pointer;
}

thead.planshead > tr > th.numbertyle {
  height: 14px !important;
  margin: 0 293px 16px 56px !important;
  font-family: customFontFamily !important;
  font-size: 14px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  text-align: right !important;
  padding: 12px 76px 12px 4px !important;
}

thead.planshead > tr > th.textstyle {
  text-align: left !important;
}

thead.planshead > tr > th.desctextstyle {
  text-align: left !important;
  padding-left: 7px;
}

thead.planshead > tr > th.initaltextstyle {
  padding-left: 29px;
}

thead.planshead > tr > th.planidlink {
  height: 14px !important;
  margin: 0 293px 16px 56px !important;
  font-family: customFontFamily !important;
  font-size: 14px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  padding: 12px 9px 12px 34px !important;
}

thead.planshead > tr > th.MuiTableCell-head {
  line-height: 0.88 !important;
  font-family: customFontFamilyRegular !important;
}

tbody.plansbody > tr > td.MuiTableCell-body {
  /* color: #434244 !important; */
  font-family: customFontFamily !important;
}

tbody.plansbody > tr > td:nth-child(2).MuiTableCell-root {
  width: 176px;
  height: 16px;
  margin: 3px 198px 14px 26px;
  padding: 7px !important;
  font-family: customFontFamily !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
}

.new {
  width: 84px;
  height: 20px;
  padding: 3px 9.9px 5px 10px;
  border-radius: 12px;
  background-color: #ffcc00;
}

.ready {
  width: 84px;
  height: 20px;
  padding: 3px 9.9px 5px 10px;
  border-radius: 12px;
  background-color: #ebe6e7;
}

.run {
  width: 74px;
  height: 20px;
  padding: 2px 8px 6px 10px;
  border-radius: 12px;
  background-color: #ece4f4;
}

.complete {
  width: 93px;
  height: 20px;
  padding: 3px 9px 5px 10px;
  border-radius: 14px;
  background-color: #e5fdf1;
}

.fail {
  width: 60px;
  height: 20px;
  padding: 3px 9px 5px 10px;
  border-radius: 14px;
  background-color: #fee6d8;
}

.publish {
  width: 84px;
  height: 20px;
  padding: 3px 9.9px 5px 10px;
  border-radius: 12px;
  background-color: #e2f4ff;
}

.planspopover > div:nth-child(3).MuiPaper-rounded {
  border-radius: 5px !important;
}

.planspopover > div:nth-child(3).MuiPaper-elevation8 {
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1) !important;
}

.planspopover > div:nth-child(3).MuiPaper-root {
  background-color: #ffffff !important;
}

.planspopover > div:nth-child(3).MuiPopover-paper {
  /* margin: 1px 4px 7px -28px !important;*/
  padding: 0px 10px 0px 10px !important;
}

.showmorepopup {
  display: flex !important;
  padding: 0px 5px 0px 5px !important;
  width: 14px;
  margin: 2px 4px 2px 4px;
  object-fit: contain;
}

.showmoretext {
  width: 88px;
  height: 16px;
  margin: 4px -27px 4px 0px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #434244;
}

tbody.plansbody > tr > td.rownumstyle {
  text-align: right !important;
  padding: 7px 76px 7px 4px !important;
}

tbody.plansbody > tr > td.rowtextstyle {
  color: #000;
  text-align: left !important;
}

tbody.plansbody > tr > td.rowtextstyle-scenario-name {
  text-align: left !important;
}

.textcapital {
  text-transform: capitalize;
  min-width: 203px;
}

.planmoreicon {
  cursor: pointer;
}

.noplansrow {
  text-align: center;
  font-size: 14px;
  margin: 41px 1px 41px 1px;
}
.lockedcopyPopOver > div.MuiPopover-paper {
  border-top-right-radius: 0px !important;
  box-shadow: -5px 10px 20px 0px rgba(0, 0, 0, 0) !important;
  background-color: #ffffff !important;
  overflow-y: hidden !important;
  border: 2px #000000 !important;
}
.adjustmentcomplete {
  height: 20px;
  padding: 4px 7px 4px 7px;
  border-radius: 14px;
  background-color: #f0fde5;
}
.completeOptimal {
  width: 93px;
  height: 20px;
  padding: 3px 9px 5px 10px;
  border-radius: 14px;
  background-color: #e5fdf1;
}
.runSupOptm {
  width: 74px;
  height: 20px;
  padding: 2px 8px 6px 10px;
  border-radius: 12px;
  background-color: #ff980e73;
}
.runUnknown {
  width: 74px;
  height: 20px;
  padding: 2px 8px 6px 10px;
  border-radius: 12px;
  background-color:  #eb200b54;
}
.EditingAdjustments{
  width: 74px;
  height: 20px;
  padding: 2px 8px 6px 10px;
  border-radius: 12px;
  background-color:  #e0908854;
}
.AdjustmentsModified{
  width: 74px;
  height: 20px;
  padding: 2px 8px 6px 10px;
  border-radius: 12px;
  background-color:  #8d8bd054;
}
.planinputsheader{
    width: 300px;
    height: 38px;
    /* margin: 30px 400px 30px 28px; */
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: -1px;
    color: #000000;
    /* margin-top: -16px; */
    /* padding-top: 8px; */
}
.planstartrun{
    display: flex;
}
.planinputsmargin{
    /* margin-left: 17px; */
    /* margin-top: 20px; */
    /* border: 1px solid red; */
}
.planflexitems{
    display: flex !important;
    justify-content: space-between;
    margin: 10px;
}
.planstartrun>button.MuiButton-text{
    width: 108px;
    height: 25px;
    margin: 0 2px 0 2px;
    padding: 14px 2px 15px 2px !important;
    border-radius: 4px;
    background-color: #ffcc00;
    font-stretch: normal;
    font-style: normal;
    /* color: #434244; */
    font-size: 14px;
}
.copiedfrom{
    width: 160px;
    height: 38px;
    margin: -4px 0px 0px 0px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.38;
    letter-spacing: -0.44px;
    color: #58595b;
}
.masterplanimg{
    width: 17px;
    height: 22px;
    margin: -6px 3px -6px 7px;
    cursor: pointer;
}
.plandetailcopyimg{
    width: 17px;
    height: 22px;
    margin: 6px 9px 0px 5px;
    cursor: pointer;
}
.plandetailcopyimgdisable{
    width: 17px;
    height: 22px;
    margin: 6px 9px 0px 5px;
    cursor: default;
}
.plandetailmoreimg{
    width: 13px;
    height: 19px;
    margin: 7px 11px 0px 5px;
    cursor: pointer;
}
.runblur{
    -webkit-filter: blur(0.4px);
            filter: blur(0.4px);
}
.removepopup{
    cursor: pointer;
  }
.removetext{
    cursor: pointer;
}
.flexContainer>ul{
    width: 100%;
    list-style-type: none;
}
.flexContainer>ul>li{
    width: 100%;
    /* border: 1px solid black; */
    /* background: #ffcc00; */
    padding:2px;
    padding-left:5px;
    border-radius: 5px;
    margin-bottom: 3px;
}
.flexContainer>ul>li:hover{
    width: 100%;
    /* border: 1px solid black; */
    /* background: #000000;
    color: #ffffff; */
    padding:2px;
    padding-left:5px;
    border-radius: 5px;
    margin-bottom: 3px;
}
.flexContainer>ul>li>span{
    display: inline-block;
    width: 90%;
    text-align: left;
}
.flexContainer>ul>li>input{
    text-align: right;
}
.flexContainer>ul>li>ul>li{
    width: 90%;
    margin-bottom: 3px;
    /* border: 1px solid red; */
}
.flexContainer>ul>li>ul>li>span{
    display: inline-block;
    width: 60%;
    text-align: left;
}
.flexContainer>ul>li>ul>li>input{
    text-align: right;
}

.flexContainer_ul_li_normal{
    background: #ffcc00;
    color: #000000;
    border-radius: 5px;
}

.flexContainer_ul_li_normal:hover{
    background: #000000;
    color: #ffffff;
    border-radius: 5px;
}

.flexContainer_ul_li_disabled{
    background: #f1ecd8;
    color: #000000;
    border-radius: 5px;
}

.flexContainer_ul_li_disabled:hover{
    background: #f1ecd8;
    color: #000000;
    border-radius: 5px;
}

/* *..* */
.navList {
    /* min-width: 90px; */
    /* width: 8%; */
}
.routeDimensions {
    /* width: initial !important;
    min-width: 90%; */
}
.planinputsmargin {
    margin-left: 5px;
}
.allplansmargin {
    margin-left: 0px;
}
.planinputsmargin {
    height: 100%;
    /* min-height: 1150px; */
    /* max-height: 1150px; */
}

/* @media screen and (max-width: 2600px) {
    .planinputsmargin  #grid-theme-wrapper>div>div{
        height: 900px !important;
    }
    .planinputsmargin .planassumptionbgcolor {
            height: 1100px !important;
    } 
    .planinputsmargin {
            min-height: 1450px;
            max-height: 1450px;
    } 
}
@media screen and (max-width: 1500px) {
    .planinputsmargin  #grid-theme-wrapper>div>div{
        height: 800px !important;
    }
    .planinputsmargin #grid-theme-wrapper>div>div>div {
        height: 800px !important;
    }
    .planinputsmargin .planassumptionbgcolor {
        height: 1000px !important;
    }
    .planinputsmargin {
        min-height: 1400px;
        max-height: 1400px;
        height: 700px;
    }

} */

.planinputsmargin #grid-theme-wrapper>div>div {
    height: 90% !important;
    /* border: 1px solid red; */
}
.planinputsmargin #grid-theme-wrapper>div>div>div {
    height: 100% !important;
}
.plandetails{
    display: flex;
    flex-wrap: wrap;
    margin-left: 28px;
}
.plandetailsmargin{
  margin-left: -28px;
}
.detailslable{
  margin: 10px 0px 0px 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.planidwidth{
  display: grid;
}
.descriptionwidth{
  display: grid;
  width: 50%;
}
.zonewidth{
  display: grid;
  width: 21%;
  word-break: break-word;
}
.poolwidth{
  display: grid;
  width: 21%;
  margin-left: 9px;
  word-break: break-word;
}
.savewidth{
  padding-right: 29px;
  font-size: 15px;
  color: #808285;
}
.styleflex{
  display: flex;
  justify-content: space-between;
}
.selectedid{
  height: 24px;
  margin: 1px 31px 24px 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434244;
}
.descriptionwidth>div>div>div.MuiInputBase-root{
    min-height: 54px !important;
    height: 65px !important;

}
.descriptionwidth>div>div>div.makeStyles-root-14 .MuiInputBase-root{
  min-height: 67px !important;
}
.descriptionwidth>div>div>div>fieldset.MuiOutlinedInput-notchedOutline{
    border: none !important;
}
.descriptionwidth>div>div>div.MuiInputBase-root{
  background: none !important;
}
.descriptionwidth>div>div>div>textarea.MuiInputBase-inputMultiline{
  height: 43px !important;
  font-size: 16px !important;
  font-family: customFontFamily !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #434244 !important;
  padding: 10px 20px 10px 20px !important;
  border-radius: 4px !important;
  border: solid 1.4px #808285 !important ;
  background-color: #ffffff !important;
  padding-left: 8px !important;
}
.descriptionwidth>div>div>div>textarea.MuiInputBase-inputMultiline:focus{
  border: solid 2px #808285 !important ;
}
.descriptionwidth>div:nth-child(2)>div.MuiTextField-root{
  margin-top: 10px;
  margin-left: -18px;
  width: 103%;
}
.descriptionwidth>div:nth-child(2).makeStyles-root-15 {
  margin-top: 1px;
  margin-left: 1px;
  margin-right: 1%;
}
.zonerevenuedropdown>div.MuiSelect-select.MuiSelect-select{
  width: 270px !important;
  height: 54px !important;
  border-radius: 4px !important;
  border: solid 1.4px #808285 !important;
  background-color: #ffffff !important;
  width: 100% !important;
}
.zonewidth>div.MuiFormControl-root{
    margin-top: 3px;
}

@media (min-width: 600px){
  .revenueoptions{
    min-height: 34px !important;
    display: block !important;
}
}
.revenuename{
  margin: 0 15px 3px 0;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #434244;
}
.revenuedesc{
  width: 100%;
  height: 20px;
  margin: 3px 15px 0 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #808285;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.zonename{
  width: 203px;
  height: 18px;
  margin: -9px 6px 5px 0;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #434244;
}
.zonedesc{
  width: 203px;
  height: 20px;
  margin: 3px 15px 0 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #808285;

}

.rfstyles{
  border: 1px solid grey;
  padding: 16px 15px 16px 15px;
  border-radius: 4px;
  display: block;
  width: 95%;
  height: 29px;
  margin: 0px 0px 5px 2px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #434244;
  white-space: nowrap;
  background-color: #e6e5e6;
}
.flexContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
}
.descriptionNewStyle {
  width: 100%;
}

.descriptionNewStyle>textarea.MuiInputBase-input {  
  font-size: 16px !important;
}

.margin {
    margin-left: 5px;
    /* margin-top: 20px; */
    /* margin-right: 40px; */
    /* margin-bottom: 35px; */
    height: 100%;
  }
  .marginleft{
    margin-left: -28px;
    margin-top: 10px;
    /* margin-bottom: 35px; */
    width: 103%;
  }
  .MuiTab-textColorInherit {
  opacity: 1 !important;
  }
  
  .MuiTab-root {
  min-width: 90px !important;
    min-height: 22px !important;
  font-family: Ride;
  }
  
  .MuiTabs-fixed {
  margin-left: 19px;
  }
  
  .MuiTabs-flexContainer {
    display: flex;
    flex-wrap: wrap;
  }
  .headertext{
  width: 193px;
  height: 38px;
  margin: 0 0 0 0;
  margin-left: 25px;
  padding-top: 15px;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: -1px;
  color: #000000;
  /* margin-top: -16px; */
  /* padding-top: 8px; */
  }
  
  .upgradeButtons{
    background-color: white;
    padding-top: 12px;
    display: flex;
    flex-wrap: wrap;
    width: 96.5%;
    position: relative;
    bottom: 12px;
    left: 24px;  
  }
  .upgradebtnstyle{
    height: 30px !important;
    width: 274px;
    margin-left: 11px;
  }
  .upgradebtnstyle>button.MuiButton-root.btnactive{
    border-radius: 4px !important;
    background-color: #ffed9b !important;
  }
  .upgradebtnstyle>button.MuiButton-root:hover{
    background-color:#ffffff !important;
  }
  .upgradebtnstyle>button.MuiButton-root.btnactive:hover{
    background-color: #ffed9b !important;
  }
  .upgradebtnstyle>button>span.MuiButton-label{
    width: 172px;
    height: 27px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  
  
  @-webkit-keyframes copyinprogress {
    0%    {background-image: linear-gradient(to right, gold ,gold ,black,black,black,black,black,black,black,black,black)}
    10%   {background-image: linear-gradient(to right, black,gold ,gold ,black,black,black,black,black,black,black,black)}
    20%   {background-image: linear-gradient(to right, black,black,gold ,gold ,black,black,black,black,black,black,black)}
    30%   {background-image: linear-gradient(to right, black,black,black,gold ,gold ,black,black,black,black,black,black)}
    40%   {background-image: linear-gradient(to right, black,black,black,black,gold ,gold ,black,black,black,black,black)}
    50%   {background-image: linear-gradient(to right, black,black,black,black,black,gold ,gold ,black,black,black,black)}
    60%   {background-image: linear-gradient(to right, black,black,black,black,black,black,gold ,gold ,black,black,black)}
    70%   {background-image: linear-gradient(to right, black,black,black,black,black,black,black,gold ,gold ,black,black)}
    80%   {background-image: linear-gradient(to right, black,black,black,black,black,black,black,black,gold ,gold ,black)}
    90%   {background-image: linear-gradient(to right, black,black,black,black,black,black,black,black,black,gold ,gold )}
    100%  {background-image: linear-gradient(to right, black,black,black,black,black,black,black,black,black,black,gold )}
  }
  
  
  @keyframes copyinprogress {
    0%    {background-image: linear-gradient(to right, gold ,gold ,black,black,black,black,black,black,black,black,black)}
    10%   {background-image: linear-gradient(to right, black,gold ,gold ,black,black,black,black,black,black,black,black)}
    20%   {background-image: linear-gradient(to right, black,black,gold ,gold ,black,black,black,black,black,black,black)}
    30%   {background-image: linear-gradient(to right, black,black,black,gold ,gold ,black,black,black,black,black,black)}
    40%   {background-image: linear-gradient(to right, black,black,black,black,gold ,gold ,black,black,black,black,black)}
    50%   {background-image: linear-gradient(to right, black,black,black,black,black,gold ,gold ,black,black,black,black)}
    60%   {background-image: linear-gradient(to right, black,black,black,black,black,black,gold ,gold ,black,black,black)}
    70%   {background-image: linear-gradient(to right, black,black,black,black,black,black,black,gold ,gold ,black,black)}
    80%   {background-image: linear-gradient(to right, black,black,black,black,black,black,black,black,gold ,gold ,black)}
    90%   {background-image: linear-gradient(to right, black,black,black,black,black,black,black,black,black,gold ,gold )}
    100%  {background-image: linear-gradient(to right, black,black,black,black,black,black,black,black,black,black,gold )}
  }
  @-webkit-keyframes copyinprogress_active {
    0%    {background-image: linear-gradient(to right, gold ,gold ,white,white,white,white,white,white,white,white,white)}
    10%   {background-image: linear-gradient(to right, white,gold ,gold ,white,white,white,white,white,white,white,white)}
    20%   {background-image: linear-gradient(to right, white,white,gold ,gold ,white,white,white,white,white,white,white)}
    30%   {background-image: linear-gradient(to right, white,white,white,gold ,gold ,white,white,white,white,white,white)}
    40%   {background-image: linear-gradient(to right, white,white,white,white,gold ,gold ,white,white,white,white,white)}
    50%   {background-image: linear-gradient(to right, white,white,white,white,white,gold ,gold ,white,white,white,white)}
    60%   {background-image: linear-gradient(to right, white,white,white,white,white,white,gold ,gold ,white,white,white)}
    70%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,gold ,gold ,white,white)}
    80%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,gold ,gold ,white)}
    90%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,white,gold ,gold )}
    100%  {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,white,white,gold )}
  }
  @keyframes copyinprogress_active {
    0%    {background-image: linear-gradient(to right, gold ,gold ,white,white,white,white,white,white,white,white,white)}
    10%   {background-image: linear-gradient(to right, white,gold ,gold ,white,white,white,white,white,white,white,white)}
    20%   {background-image: linear-gradient(to right, white,white,gold ,gold ,white,white,white,white,white,white,white)}
    30%   {background-image: linear-gradient(to right, white,white,white,gold ,gold ,white,white,white,white,white,white)}
    40%   {background-image: linear-gradient(to right, white,white,white,white,gold ,gold ,white,white,white,white,white)}
    50%   {background-image: linear-gradient(to right, white,white,white,white,white,gold ,gold ,white,white,white,white)}
    60%   {background-image: linear-gradient(to right, white,white,white,white,white,white,gold ,gold ,white,white,white)}
    70%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,gold ,gold ,white,white)}
    80%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,gold ,gold ,white)}
    90%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,white,gold ,gold )}
    100%  {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,white,white,gold )}
  }
  
  .margin #grid-theme-wrapper>div>div {
    /* height: 90% !important; */
    /* border: 1px solid red; */
  }
  .margin #grid-theme-wrapper>div>div>div {
    /* height: 100% !important; */
  }
  
  .margin #grid-theme-wrapper>div>div {
    height: 90% !important;
    /* border: 1px solid red; */
  }
  .margin #grid-theme-wrapper>div>div>div {
    height: 100% !important;
  }
  
  .mainplaninputstabpanel{
    width:96.5%;
    position:relative;
    top:24px;
    left:24px;
  }
  
  @media screen and (min-width: 2600px) {
    /* .margin #grid-theme-wrapper>div>div {
      height: 90% !important;
      border: 1px solid red;
    }
    .margin #grid-theme-wrapper>div>div>div {
      height: 100% !important;
    } */
  }
  
  @media screen and (max-width: 2600px) {
    /* .margin #grid-theme-wrapper>div>div {
      height: 90% !important;
      border: 1px solid red;
    }
    .margin #grid-theme-wrapper>div>div>div {
      height: 100% !important;
    } */
  }
    
  @media screen and (max-width: 1500px) {
    /* .margin #grid-theme-wrapper>div>div {
      height: 90% !important;
      border: 1px solid red;
    }
    .margin #grid-theme-wrapper>div>div>div {
      height: 100% !important;
    } */
    /* .revenue-adjust #grid-theme-wrapper>div>div>div{
      height: 450px !important;
    } */
  }   
  .newplanassumptiontabs{
    display: flex;
    background-color:#ffffff;
    min-width: inherit;
    width: auto;
}
.newplanassumptiontabs>div>div:nth-child(3)>div>button{
  margin: 0 0 5px 12px !important;
}
.newplanassumptiontabs>div>div:nth-child(3)>div>button:active{
  border-radius: 4px !important;
  background-color: #ffed9b !important;
  color: #000000;
}
.newplanassumptiontabs>div.MuiTabs-vertical{
  min-width: 17%;
  padding-right: 1%;
  /* height: 528px; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: -40px;
  margin-left: -7px;
}
.newplanassumptiontabpanel{
  min-width: 942px;
}
.newplanassumptiontabpanel>div>p.MuiTypography-body1{
  width:917px;
}
.newplanassumptiontabpanel>div>p.MuiTypography-body1 {
  width: auto;
}

.newplanassumptiontabpanel {
  min-width: 82%;
  width: 100%;
  padding-bottom: 28px;
  padding-top:0px;
}
.paddingtarget .MuiBox-root {
  padding-right: 0px !important;
}
  
  
  
  
.mainHeader {
    background-color: white;
    -webkit-margin-before: -36px !important;
            margin-block-start: -36px !important;
    padding-bottom: 5px;
    color: white;
    min-width: 1104px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}

.actionButtons>div:nth-child(1).Mui-focused {
    background-color: none !important;
}

.viewdropdown>div.MuiInputBase-input {
    padding: 0px 2px 7px 3px !important;
}

.viewdropdown>div.MuiSelect-select:focus {
    background-color: none !important;
}

.viewdropdown>svg.MuiSelect-icon {
    padding: 0px 2px 7px 2px !important;
}

.saveButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
    min-width: 36%;
    margin-right: 10px;
    margin-top: 10px;
}

.actionButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
    min-width: 36%;
    margin-right: 10px;
    margin-top: 15px;
}

.filterMain {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 24px;
}

.actionButtons>Button {
    padding: 14px !important;
    margin: 5px !important;
    text-transform: none !important;
    padding: 10px;
}

.imageIcon {
    margin: 10px;
    width: 24px !important;
    height: 28px;
}

.MuiMenuItem-root {
    font-size: 13px !important;
}

.MuiSelect-selectMenu {
    width: 165px !important;
    border-radius: 3px;
    margin-top: 5px !important;
    padding: 5px !important;
}

.MuiFormControl-root {
    margin-right: 10px !important;
}

.MuiSvgIcon-root {
    padding-top: 3px !important;
    margin-top: 0px !important;
    padding-right: 5px;
}

.MuiSelect-select {
    /* width: 120px !important; */
    border-radius: 3px !important;
    /* border: 1px solid grey !important; */
    /* background: #ffcc00 !important;  */
}

.dropdownfields {
    display: flex;
    flex-direction: column;
    width: 176px;
    margin-top: 5px;
}

.flexContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
}

.MuiTypography-gutterBottom {
    margin-bottom: 0 !important;
}

.MuiMenuItem-root {
    font-family: customFontFamily !important;
    height: 28px;
    border-bottom: 1px solid lightgray;
}

.MuiInputBase-root {
    font-family: customFontFamily !important;
}

.MuiTypography-body1 {
    font-family: customFontFamily !important;
}

.muiDatePicker {
    margin-top: 0px !important;
    border: 1px solid #808285 !important;
    border-radius: 3px !important;
    padding-left: 5px !important;
    width: 165px !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #808285 !important;
    border-radius: 3px !important;
    border-width: 1px !important;
}

.filterStyle {
    /* min-width: 63%;
    display: flex;
    flex-direction: row; */
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}

.viewdropdown {
    width: 90px;
    height: 34px;
    margin: 0 0 0 18px;
    padding: 1px;
    border-radius: 5px;
    background-color: #ffcc00;
}

.actionButtons>div.MuiFormControl-root {
    margin-top: 7px !important;
}

.primaryButton {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding-top: 2px;
    background-color: #ffcc00;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.secondaryButton {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding-top: 2px;
    background-color: transparent !important;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.MuiButton-label {
    text-transform: none !important;
}

.searchbar {
    margin-right: 0% !important;
    margin-top: 8px;
}

.MuiDialog-paperWidthSm {
    max-width: 675px !important;
}

.imageSpacing {
    padding-right: 15px !important;
    min-width: 24px !important;
}

.MuiListItem-root.Mui-selected {
    background-color: transparent !important;
}

.actiobarPopOver>div.MuiPopover-paper {
    border-top-right-radius: 0px !important;
    box-shadow: -5px 10px 20px 0px rgba(0, 0, 0, 0.1) !important;
    background-color: #ffffff !important;
    overflow-y: hidden !important;
    margin-top: 0.7px !important;
}

div.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.MuiDivider-root {
    background-color: #f3f2f3;
}
.flexContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
  }

.filterMain {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 24px;
}

.filterMainForRange {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 24px;
    width: 100%;
}

.filterMargin {
    margin:-1px 0px 0px 10px;
  }
 .dropdownfields {
      display: flex;
      flex-direction: column;
      width: 176px;
      margin-top: 5px;
} 

.MuiSelect-select.MuiSelect-select {
    padding-left: 8px !important;
}

.dropdownStyle {
    /* border: 1px solid #808285 !important; */
    border-radius: 3px;
    height: 30px;
    padding-bottom: 5px;
     width: 167px;
           min-width: 120px;
}
.customdropdown {
    display: flex;
    flex-direction: column;
    width: 176px;
    margin-left: 10px;
  } 
.customdropdown>div>div>div>div.dropdown-heading-value{
  font-size: 12px;
}
.customdropdown>div>div>div.dropdown-content{
  font-size: 13px !important;
  color: gray;
  font-family: customFontFamily !important;
}
.customdropdown>p{
  width: 254px;
  height: 18px;
  margin: 0 26px 5px 0;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808285;
}
.customdropdown>div>div.dropdown-container{
  border-color:#808285 !important;
}
.datePickerContainer>.muiDatePicker {
    margin: 0px !important;
    border: none !important;
    border-radius: 3px !important;
    padding-left: 5px !important;
    /* width: 160px !important; */
}

.calendarIcon {
    width: 22px;
    height: 20px;
    padding-right: 5px;
}

.dropdownFilter {
    width: 160px !important;
    min-width: 160px !important;
}

.datepickerBar {
    display: flex;
    flex-direction: row;
    margin-top: 10px !important;
}

.datesFields {
    margin-left: 10px !important;
}

.dropdownStyle {
    /* border: 1px solid #808285 !important; */
    border-radius: 3px;
    height: 30px;
    /* width: 167px;
    min-width: 120px; */
}

.datePickerContainer{
    display: inline-flex;
    border: 1px solid #808285 !important;
    width: 165px;
    border-radius: 3px !important;
    height: 30px;
}
.datePickerContainer .MuiSvgIcon-root{
    color: #aaa;
}
.datePickerContainer .MuiSvgIcon-root:hover{
    cursor: pointer;
}

    .custominputFields {
        display: flex;
        flex-direction: column;
        width: 176px !important;
        margin-left: 10px !important;
        color: #808285 !important;
    }
    .custominputFields>p{
        width: 254px;
        height: 18px;
        margin: 0 26px 5px 0;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #808285;
    }
    
.newrangeinput{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.newrangemargin{
    margin-left: 8px;
}
.newandtext{
    height: 19.5px;
    margin: 8px 11px 8.5px 6px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #434244;
}
.newrangeinput>div+div>div.MuiOutlinedInput-root{
    width: 170px !important;
    margin-top: 0px !important;
}
.newrangeinput>span.displayflx{
    display: flex;
}
.newrangeinput>div+div>div>input.MuiInputBase-input{
    padding: 8px 2px 8px 3px !important;
    text-align: left;
}
.displayflx>label+div>div.MuiOutlinedInput-root{
    width: 170px !important;
    margin-top: 0px !important;
}
.displayflx>label+div>div>input.MuiInputBase-input{
    padding: 8px 2px 8px 3px !important;
    text-align: left;
}
.newdropdownFilter>p{
    width: 254px;
    height: 18px;
    margin: 0 26px 5px 0;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #808285;
}
.chipsSection{
    width: 100%;
    /* border-width: 2;
    border-style: solid; */
    color: black;
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
    flex-wrap: wrap;
    align-items: flex-start;
}

.chipsSectionInternal{
    width: 96%;
    /* border-width: 2;
    border-style: solid;
    border-color: blue; */
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.chipsCrossSection{
    /* border-width: 2;
    border-style: solid;
    border-color: red; */
    display: flex;
    background-color: #ffde57;
    border-radius: 5px;
}

.chipsCrossSection:hover{
    background-color: #ffaa44;
}

.aChip{
    background-color: #ffde57;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 6px;
    padding-left: 6px;
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: small;
    display: flex;
    align-items: center;
}

.chipCross{
    margin: 6px;
}

.chipCrossMain{
    margin: 6px;
    /* width: 24px;
    height: 24px; */
}

.tooltip {
    position: relative;
    display: inline-block;
    /* border: 1px dotted black; */
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 60px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 2px 0;
    font-size: 12px;
    opacity: 0.7;
    margin-top: 50px;
  
    /* Position the tooltip */
    position: absolute;
    bottom: -1;
    right: 0;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
.deleteAllButton {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding-top: 2px;
    background-color: #ffcc00;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  }
.flexContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
  }

.errorMain {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 24px;
}

.errorMainForRange {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 24px;
    width: 100%;
}

.errorMargin {
    margin:-1px 10px 0px 10px;
  }
.newSearchInput{
    border-radius: 4px !important;
    border: solid 0px #808285 !important;
    background-color: #ffffff;
    width: 209px;
    margin-left: 10px !important;
    height: 29px;
}
.newSearchInput>div>fieldset.PrivateNotchedOutline-root-18{
    border-style: none !important;
}
.newSearchInput>div>input[aria-invalid='true']~fieldset.MuiOutlinedInput-notchedOutline{
    border-radius: 4px !important;
    border-style: solid  !important;
    border-color: red !important;
}
.newSearchInput>div>input{
    height: 15px;
    margin: 0 0 0 -8px;
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.07;
    letter-spacing: normal;
    color: #5a585c;
}

.newSearchInput>.MuiFormHelperText-contained{
    display: none;
}
.newSearchInput>div:has(input[aria-invalid='true'])~.MuiFormHelperText-contained{
    display:inline-block;
    color: red;
    width: -webkit-max-content;
    width: max-content;
}

.MuiOutlinedInput-input {
    /* padding: 18.5px 14px; !important */
  }
.toplevelfilteroptionscontainer{
    background-color: white;
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
  }
 .toplevelfilteroptionscontainer .MuiButtonBase-root{
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #434244;
    font-family: customFontFamily !important;
    height: 28px;
    align-items: center;
 }
 
 .inactiveButton{
    background-color: transparent !important;
 }
 .activeButton{
    color: #000000 !important;
    border-radius: 4px !important;
    background-color: #ffed9b !important;
 }

 .toplevelfilteroptionscontainer .MuiButtonBase-root>.MuiButton-label span{
   text-transform: uppercase;
    padding-right: 6px;
    padding-left: 6px;
 }
  
.plandetailsminimized{
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 20%;
    max-width: 50%;
    background-color: #ffffff;
    border: 2px solid #c2bdc2;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1rem;
}
.plandetailsminimized_withcopiedfrom{
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 20%;
  max-width: 30%;
  background-color: #ffffff;
  border: 2px solid #c2bdc2;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1rem;
}
.descriptionelements{
    display: block;
    flex-wrap: wrap;
    overflow: hidden;
    max-height: 46px;
}
.rfelements{
  display: block;
  flex-wrap: wrap;
  overflow: hidden;
  height: 23px;
}

@media screen and (min-width: 1900px) {
  .plandetailsminimized{
    min-width: 25%;
    max-width: 65%;
  }
  .plandetailsminimized_withcopiedfrom{
    min-width: 25%;
    max-width: 57%;
  }
}
.startRunDialogBox>div.MuiDialog-container {
    height: 95% !important;
    width: 95% !important;
}

.startRunDialogBox>div>div.MuiDialog-paperScrollPaper {
    max-height: 660px;
    height: 95%;
    width: 600px;
    padding: 12px 6px 12px 6px;
    border-radius: 5px;
    background-color: #ffffff;
}
.startRundialogtitle{
    border-bottom:2px ridge;
    padding: 10px 24px !important;
}
.startRundialogtitle>h2.MuiTypography-h6 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}

.startRundialogtitle>h2.MuiTypography-h6>span {
    /* width: 121px;
    height: 16px; */
    font-size: 18px;
    font-family: customFontFamily !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.73;
    letter-spacing: normal;
    color: #434244;
}

.startRundialogtitle>h2.MuiTypography-h6>span:nth-child(2) {
    font-weight: normal !important;
    color: red;
}

.startRunCloseIcon {
    width: 16px;
    height: 16px;
}

.startRunMainTabsFlexContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: space-around;
    height: 190px;
} 

.startRunPlanAssumptionTabsFlexContainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: space-around;
    height: 355px;
    width: 100%;
    margin-top: 2%;
}

.planAssumptionTabTitle{
position:relative;
inset:0 0 1% -23%;
font-weight: bolder;
font-size: 15px;
}

.startRunDialogContent{
    background-color: lightyellow;
}
.validStartRunDisplayName {
    border-radius: 4px;
    color: white;
    background-color: black;
    text-transform: none;
    text-align: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 50%;
    font-size: 16px;
}

.inValidStartRunDisplayName {
    border-radius: 4px;
    color: white;
    background-color: red;
    font-size: 14;
    text-transform: none;
    text-align: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 50%;
    font-size: 16px;
}

.startRunActionbuttons{
    border-top:2px ridge;
    padding: 0px 24px !important;
}
.startRunActionbuttons>button.cancelbutton {
    padding: 14px !important;
    margin: 5px !important;
    border-radius: 4px;
    border: solid 1.5px #808285;
    background-color: #ffffff;
    height: 30px !important;
}

.startRunActionbuttons>span>button.okbutton {
    padding: 14px !important;
    margin: 5px !important;
    border: none;
    background-color: #ffcc00;
    height: 30px !important;
}

.startRunActionbuttons>span>button.buttonblur {
    padding: 14px !important;
    margin: 5px !important;
    border: none;
    opacity: 0.7;
    height: 30px !important;
    background-color: #ffcc00;
}

.startRunActionbuttons>button>span.MuiButton-label,
.startRunActionbuttons>span>button>span.MuiButton-label {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    text-align: center;
    color: #434244;
}
.copyPlanDialogBox>div.MuiDialog-container {
    height: 95% !important;
    width: 95% !important;
}

.copyPlanDialogBox>div>div.MuiDialog-paperScrollPaper {
    max-height: none;
    height: 95%;
    width: 600px;
    padding: 12px 6px 12px 6px;
    border-radius: 5px;
    background-color: #ffffff;
}
.copyPlandialogtitle{
    border-bottom:2px ridge;
    padding: 10px 24px !important;
}
.copyPlandialogtitle>h2.MuiTypography-h6 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}

.copyPlandialogtitle>h2.MuiTypography-h6>span {
    font-size: 18px;
    font-family: customFontFamily !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.73;
    letter-spacing: normal;
    color: #434244;
}

.copyPlandialogtitle>h2.MuiTypography-h6>span:nth-child(2) {
    font-weight: normal !important;
    color: red;
}

.copyPlanCloseIcon {
    width: 16px;
    height: 16px;
}

.copyPlanMainTabsFlexContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: space-around;
    height: 190px;
} 

.copyPlanAssumptionTabsFlexContainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: space-around;
    height: 355px;
    width: 100%;
    margin-top: 2%;
}

.copyPlanAssumptionTabTitle{
    position:relative;
    inset:0 0 1% -23%;
    font-weight: bolder;
    font-size: 15px;
}

.copyPlanDialogContent{
    background-color: lightyellow;
}

.copytoMainactionbuttons>button.MuiButton-root{
    text-transform: capitalize !important;
}
.copytoMainactionbuttons>button:nth-child(1){
  padding: 14px !important;
  margin: 5px !important;
  border-radius: 4px;
  border: solid 1.5px #808285;
  background-color: #ffffff;
}
.copytoMainactionbuttons>button:nth-child(2){
    padding: 14px !important;
    margin: 5px !important;
    border: none;
    border-radius: 4px;
    background-color: #ffcc00;
    line-height: 0.75;
    font-family: customFontFamily;
    font-size: 0.875rem;
  }
.copytoMainactionbuttons>button:nth-child(2):not(:disabled){
    cursor: pointer;
    font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #434244;
  font-weight: 400;
}

.copytoMainactionbuttons>button>span.MuiButton-label{
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: center;
  color: #434244;
} 
.copyPlanDialogContent input[type='checkbox']{
    accent-color: rgb(255, 204, 0);   
}
.copyPlanDialogContent_normal{
    background: #ffcc00;
    color: #000000;
    border-radius: 5px;
    width: 57%;
}

.copyPlanDialogContent_normal:hover{
    background: #000000;
    color: #ffffff;
    border-radius: 5px;
}
.copyPlanDialogContent_disabled{
    background: #f1ecd8;
    color: #000000;
    border-radius: 5px;
    width: 57%;
}

.copyPlanDialogContent_disabled:hover{
    background: #f1ecd8;
    color: #000000;
    border-radius: 5px;
}

.mainAdjustmentHeader {
    background-color: white;
    -webkit-margin-before: -36px !important;
            margin-block-start: -36px !important;
    padding-bottom: 5px;
    color: white;
    width: 1183px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
}
.AllBumainAdjustmentHeader{
    background-color: white;
    -webkit-margin-before: -36px !important;
            margin-block-start: -36px !important;
    padding-bottom: 5px;
    color: white;
    width: 1193px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
}

.adjustmentsActionButtons>div:nth-child(1).Mui-focused {
    background-color: none !important;
}

.adjustmentsActionButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
}

.adjustmentsActionButtons>Button {
    padding: 14px !important;
    margin: 5px !important;
    text-transform: none !important;
    padding: 10px;
}

.adjustmentsFilterStyle {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: 90%;
}

.adjustmentsActionButtons>div.MuiFormControl-root {
    margin-top: 0px !important;
}
.adjFilterIcon{
    cursor: pointer;
}

.expandAllCollapseAllButton{
    border: solid 0px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding: 3px;
    padding-top: 5px;
    padding-right: 5px;
    background-color: #ffcc00;
    color: black;
    cursor: pointer;
}

.expandAllCollapseAllButton img{
    width: 16px;/* Original size is 16X16 */
    height: 16px;
    padding: 3px;
}

.errorMessage{
    color: red;
    background-color: #ffcccc;
    flex-basis: 100%;
    align-content: center;
    text-align: center;
    border-radius: 5px;
}
.rangeinput{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.andtext{
    height: 19.5px;
    margin: 8px 11px 8.5px 6px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #434244;
}
.rangemargin{
    margin-left: 8px;
}
.rangeinput>div>div.MuiFormControl-root.MuiTextField-root{
    width: 165px !important;
    margin-top: 0px !important;
}
.rangeinput>span.displayflx{
    display: flex;
    margin-top: -5px;
}
 .constraintdropdown {
      display: flex;
      flex-direction: column;
      width: 210px;
      margin-left: 10px;
    } 

.MuiSelect-select.MuiSelect-select {
    padding-left: 8px !important;
}
.constraintdropdown>div>div>div>div.dropdown-heading-value{
    font-size: 12px;
}
.constraintdropdown>div>div>div.dropdown-content{
    font-size: 13px !important;
    color: gray;
    font-family: customFontFamily !important;
    min-width: 184px;
    flex-wrap: wrap;
}

/* .rmsc .options{
    color: gray !important;
    font-size: 13px !important;
} */
.dropdown-container:focus-within{
    border-color:#808285 !important;
    box-shadow: #808285 0 0 0 0px !important;
}
.constraintdropdown>p{
    width: 254px;
    height: 18px;
    margin: 0 26px 5px 0;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #808285;
}
.dropdownFilter>p{
    width: 254px;
    height: 18px;
    margin: 0 26px 5px 0;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #808285;
}
.constraintdropdown>div>div.dropdown-container{
    border-color:#808285 !important;
}

.go2642161244 {
    height: 30px !important;
}
.adjustmentsorttext {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  text-transform: capitalize !important;
}

.adjustmentvalues {
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #808285;
  margin-top: 10px;
}

.collapsedAdjustmentValues {
  min-width: 99px;
  text-align: right;
  font-size: 13px;
  color: #808285;
}

.adjustmentValuesWithMargin {
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #808285;
}

.adjustmentvaluesgreen {
  margin-top: 10px;
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #0d8746;
  margin-top: 10px;
}

.adjustmentvaluesred {
  margin-top: 10px;
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #e90133;
}

.adjustmentvaluesblue {
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #006bbd;
  margin-top: 8px;
}

.adjustmenttotalFinal {
  background-color: #f3f2f3;
  border-radius: 3px;
  min-width: 80px;
  font-size: 13px;
  padding-right: 10px;
  padding-left: 5px;
  margin-left: 7px;
  z-index: 1;
  color: #434244;
}

.adjustmenttotal {
  background-color: #f3f2f3;
  border-radius: 3px;
  min-width: 80px;
  font-size: 13px;
  padding-right: 10px;
  padding-left: 5px;
  margin-left: 7px;
  z-index: 1;
  color: #808285;
}

.collapseAdjustmentTotal {
  background-color: #f3f2f3;
  width: 89px;
  font-size: 13px;
  color: #808285;
  border-radius: 3px;
  padding-right: 10px;
  margin-left: 2px;
}

.marginOverall {
  margin-left: 70px;
  margin-right: -111px !important;
  display: flex;
  overflow: auto;
  width:898px;
  height: 20px;
}
.acivitymarginOverall{
  margin-left: 98px;
  margin-right: -111px !important;
  display: flex;
  overflow: auto;
  width:898px;
  height: 20px;
}
.readOnlymarginOverall{
  margin-left: 31px;
  margin-right: -172px !important;
  display: flex;
  overflow: auto;
  width: 1000px;
  height: 20px;
}

.noMarginOverall {
  margin-left: 70px;
  margin-right: -111px !important;
  display: flex;
  overflow: auto;
  width:898px;
  height: 20px;
}
.readOnlynoMarginOverall{
  margin-right: -111px !important;
  display: flex;
  overflow: auto;
  width: 1000px;
  height: 20px;
  margin-left: 3px;
}

.rowFlex {
  display: flex;
}
.allBurowFlex{
  display: flex;
}

.noMarginOverall::-webkit-scrollbar {
  display: none;
}
.readOnlynoMarginOverall::-webkit-scrollbar {
  display: none;
}

.marginOverall::-webkit-scrollbar {
  display: none;
}
.acivitymarginOverall::-webkit-scrollbar {
  display: none;
}

.readOnlymarginOverall::-webkit-scrollbar {
  display: none;
}

.marginNegative {
  margin-left: -30px;
}

.greencolor {
  color: #0d8746;
}

.redcolor {
  color: #e90133;
}

.adjustmentvaluesTotal {
  display: flex;
}

.boldStyle {
  font-weight: bold;
}

.adjustment_value_total {
  min-width: 90px;
  text-align: right;
  font-size: 13px;
}

.applymargintotal {
  margin-right: 10px;
}

.adjustmentValuesMargin {
  margin-top: -36px;
  display: inline-flex;
  width: 900px;
  text-align: right;
  position: absolute;
  height: 115px;
}

.adjustmentValuesOverallMargin {
  margin-top: -30px;
  display: inline-flex;
  width: 900px;
  text-align: right;
  position: absolute;
  height: 98px;
}

.collapsedAdjustmentValuesOverallMargin {
  display: inline-flex;
  width: 900px;
  text-align: right;
  position: absolute;
  height: 98px;
  margin-top: 2px;
  margin-left: 41px;
}

.adjustmentBelowMargin {
  display: inline-flex;
  width: 900px;
  text-align: right;
}

.collapsedAdjustmentBelowMargin {
  display: inline-flex;
  width: 900px;
  text-align: right;
}

.adjustmentBelowOverallMargin {
  display: inline-flex;
  width: 900px;
  text-align: right;
  height: 28px;
}

.adjustmentsorttextleftMargin {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  margin-left: 10px;
  text-transform: capitalize !important;
}

.adjustmentsort {
  border-bottom: solid 2px #f3f2f3;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}

.coreBUadjustmentsort{
  border-bottom: solid 2px #f3f2f3;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}

.alignAdjustments {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding-right: 10px;
  width: 1174px;
}
.alignAllBuAdjustments{
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding-right: 10px;
  width: 1183px;
}

.adjustmentApplyMargin {
  display: flex;
  flex-direction: row;
}

.adjustmentMinWidth {
  justify-content: flex-end;
  display: flex;
  width: 84px;
  margin-left: 12px;
  border-radius: 3px;
}
.adjustmentAllBuMinWidth{
  justify-content: flex-end;
  display: flex;
  padding-right: 18px;
  width: 84px;
  margin-left: 19px;
  border-radius: 3px;
  padding-right: 8px;
}

.applyMonthMargin {
  display: inline-flex;
  width: 898px;
  overflow: scroll;
  margin-right: 5%;
}

.applyMonthMargin::-webkit-scrollbar {
  height: 10px;
  width: 0px;
}

.applyMonthMargin::-webkit-scrollbar-thumb {
  background: #808285;
  border-radius: 5px;
}

.applyAllBuMonthMargin {
  display: inline-flex;
  width: 85%;
  overflow: scroll;
}

.applyAllBuMonthMargin::-webkit-scrollbar {
  height: 10px;
  width: 0px;
}

.applyAllBuMonthMargin::-webkit-scrollbar-thumb {
  background: #808285;
  border-radius: 5px;
}

.adjustmentValuesMargin::-webkit-scrollbar {
  display: none;
}

.adjustmentValuesOverallMargin::-webkit-scrollbar {
  display: none;
}

.collapsedAdjustmentValuesOverallMargin::-webkit-scrollbar {
  display: none;
}

.adjustmentBelowMargin::-webkit-scrollbar {
  display: none;
}

.adjustmentBelowOverallMargin::-webkit-scrollbar {
  display: none;
}

.labelAlignment {
  display: flex;
  width: 14%;
  margin-right: 33px;
}

.allBuLabelAlignment {
  display: flex;
  width: 14%;
  margin-left: 17px;
}

.monthYearWidth {
  display: flex;
  min-width: 99px;
  justify-content: flex-end;
}

.headerExpandIcon {
  width: 20px;
}

.adjustmentdataalignment {
  width: 1065px;
  margin-top: 15px;
  margin-bottom: 30px;
}
.applyFreezMonthColor{
  color:#800080 !important;
}
.newadjustmentaccordions {
  overflow: scroll;
  text-align: left;
}

.newadjustmentaccordions>div.MuiPaper-root{
  background: none !important;
}

.adjustmentaccordiondetails.MuiAccordionDetails-root {
  display: flex !important;
  flex-direction: row;
  padding: 8px 0px 16px;
}

.adjustmentAccordiondetails {
  display: flex;
  flex-direction: row;
  margin-top: -15px;
  margin-bottom: -15px;
  margin-left: 6px;
}


.adjustmentOverallAccordiondetails {
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  margin-left: 6px;
}

.adjustmentOverallAccordionContent {
  display: flex;
  flex-direction: row;
}

.adjustmentAccordiondetailsTitle.MuiAccordionDetails-root {
  display: flex !important;
  flex-direction: row;
  padding: 5px 0px 16px;
}

.adjustmentOverallAccordiondetailsTitle.MuiAccordionDetails-root {
  display: flex !important;
  flex-direction: row;
  padding: 8px 0px 16px;
}

.adjustmentOverallAccordiondetailsTitle {
  width : 140px;
}

.adjustmentAccordiondetailsTotal.MuiAccordionDetails-root {
  display: flex !important;
  flex-direction: column !important;
  padding: 8px 0px 16px !important;
}

.adjustmentAccordiondetailsTotal {
  display: flex !important;
  flex-direction: column;
  padding: 8px 0px 16px;
  margin-left: 5px;
}

.adjustmentAccordiondetailsContent.MuiAccordionDetails-root {
  display: flex;
  padding: 16px 11px 16px;
}

.adjustmentAccordiondetailsContent::-webkit-scrollbar {
  display: none;
}


.adjustmentcontent {
    display: flex;
    flex-direction: row;
    overflow: auto;
    width: 1000px;
    margin-top: -30px;
}


.adjustmentcontent::-webkit-scrollbar {
  display: none;
}

.typeicons {
  display: flex;
}

.typeiconstyle {
  height: 14px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  color: #434244;
  margin: 1px 6px 1px 1px;
}


.adjustmentaccordions>div.MuiAccordion-root {
  display: flex;
}

.adjustmentexpand>div.MuiAccordionSummary-expandIcon {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.adjustmentexpand>div.MuiAccordionSummary-expandIcon.Mui-expanded {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.adjustmentaccordions {
  display: inline-flex;
}

.adjustmentaccordions::-webkit-scrollbar {
  display: none;
}

.adjustmentexpand>div.MuiAccordionSummary-content {
  order: 0;
  min-width: 60px;
  margin: 6px 11px !important;
}

.applyMonthMargin>div:nth-child(1) {
  min-width: 100px;
}

.verticalAdjustmentAccordion {
  width: 8%;
  min-width: 8%;
  margin-left: 18px;
}

.adjustmentsview {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  padding: 0px 0px 0px 0px;
}

.adjustmentAlignment {
  /* display: flex; */
  font-size: 13px;
  flex-direction: column;
}

.accordionContent {
  display: flex;
}

.alignTitleContent {
  display: flex;
}

.alignTitle {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  margin-left: 10px;
  text-align: left;
}

.alignTotal {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  margin-left: 10px;
  text-align: left;
  background-color: #f3f2f3;
  border-radius: 3px;
}

.allBUAccordionTotal {
  display: flex !important;
  flex-direction: column !important;
  padding-right: 20px;
  font-size: 13px;
  width: 80px;
}

.alignContent {
  display: flex;
  text-align: left;
  flex-direction: column;
  margin-top: -20px;
  margin-left: 1.5%;
}

.alignColumnContent {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-left: 4%;
}

.totalCurrentValue {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  width: 80px;
  text-align: right;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 10px;
  color: #808285;
  
}

.allBuOverallDivider {
    width: 1147px !important;
    height: 1px;
    background: red;
    margin-left: 43px !important;
    margin-top: 0px !important;
    margin-bottom: 7px !important;
}

.allBuChildDivider {
    width: 1121px !important;
    height: 1px;
    background: red;
    margin-left: 43px !important;
    margin-top: 7px !important;
    margin-bottom: 7px !important;
}

.allBuOverallDivider>div.MuiDivider-root {
  background-color: #f3f2f3 !important;
}

.subDivider {
  width: 1000px !important;
  height: 1px;
  margin-top: 20px !important;
  margin-left: -100px !important;
}

.subDivider>div.MuiDivider-root {
  background-color: #f3f2f3 !important;
}

.allBuAdjustmentheading {
    min-width: 90px;
    width: 70px;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    color: #434244;
    display: inline-flex;
    margin-top: 3px;
}
.buContent {
    font-size: 13px;
    margin-top: 8px;
    margin-left: 20px;
    color:#808285;
    font-style: italic;
    flex-direction: row;
    width: 150px;
}

.buContentValues {
    font-size: 13px;
    margin-top: 10px;
    margin-left: 20px;
    color:#808285;
    font-style: italic;
}

.adjustmentcontents{
    height: 37px;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
  }

  .adjustmentpanel{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    font-size: 12px;
    width: 100%;
    padding-left: 9px;
    margin-top: 7px;
  }

  .adjustmentcontentpanel{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    font-size: 12px;
    min-width: 96%;
  }

  .buAccordionContent {
    overflow: scroll;
    display: inline-flex;
    width: 800px;
    /* margin-left: 125px; */
    /* margin-top: -25px; */
}

.buAccordionContent::-webkit-scrollbar {
    display: none;
  }

  .allBuTitle {
    width: 108px;
    min-width: 108px;
    font-size: 13px;
    margin-left: 10px;
  }

.applygreybackground {
    background-color: #f3f2f3;
    width: 70px;
    margin-left: 6px;
    border-radius: 3px;
    padding-right: 26px;
    display: flex;
    flex-direction: column;
}

.cellComponent {
    display: flex;
    flex-direction: column;
    min-width: 99px;
}

.rowStyle {
    display: flex;
    /* height: 110px; */
}
.filteredRecord{
    background-color:#ffffe0;
}
.selectedForFilter{
    background-color:#d0f3fc;
    font-weight: bold;
}
.newadjustmentaccordions {
    overflow: scroll;
    text-align: left;
  }
  
  .adjustmentaccordiondetails.MuiAccordionDetails-root {
    display: flex !important;
    flex-direction: row;
    padding: 8px 0px 16px;
  }
  
  .adjustmentAccordiondetails {
    display: flex;
    flex-direction: row;
    margin-top: -15px;
    margin-bottom: -15px;
    margin-left: 6px;
  }
  
  
  .adjustmentOverallAccordiondetails {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    margin-left: 6px;
  }
  
  .coreBUadjustmentOverallAccordionContent {
    display: flex;
    flex-direction: row;
    min-width: 1800px;
  }
  
  .adjustmentAccordiondetailsTitle.MuiAccordionDetails-root {
    display: flex !important;
    flex-direction: row;
    padding: 5px 0px 16px;
  }
  
  .adjustmentOverallAccordiondetailsTitle.MuiAccordionDetails-root {
    display: flex !important;
    flex-direction: row;
    padding: 8px 0px 16px;
  }
  
  .adjustmentOverallAccordiondetailsTitle {
    width : 140px;
  }
  
  .adjustmentAccordiondetailsTotal.MuiAccordionDetails-root {
    display: flex !important;
    flex-direction: column !important;
    padding: 8px 0px 16px !important;
  }
  
  .adjustmentAccordiondetailsTotal {
    display: flex !important;
    flex-direction: column;
    padding: 8px 0px 16px;
    margin-left: 5px;
  }
  
  .coreBUadjustmentAccordiondetailsContent.MuiAccordionDetails-root {
    display: flex;
    padding: 16px 11px 16px;
    min-width: 1051px;
  }
  
  .coreBUadjustmentAccordiondetailsContent>span>span::-webkit-scrollbar {
    display: none;
  }
  .yearcomponent::-webkit-scrollbar {
    display: none;
  }
  
  
  .buadjustmentcontent {
    display: flex;
    flex-direction: row;
    width: 1000px;
    margin-top: -30px;
  }
  
  
  .buadjustmentcontent::-webkit-scrollbar {
    display: none;
  }

  .enfleetbuadjustmentcontent {
    display: flex;
    flex-direction: row;
    overflow: auto;
    width: 898px;
    margin-top: -30px;
  }
  
  
  .enfleetbuadjustmentcontent::-webkit-scrollbar {
    display: none;
  }
  
  .typeicons {
    display: flex;
  }
  
  .typeiconstyle {
    height: 14px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    letter-spacing: normal;
    color: #434244;
    margin: 1px 6px 1px 1px;
  }
  
  
  .adjustmentaccordions>div.MuiAccordion-root {
    display: flex;
  }
  
  .adjustmentexpand>div.MuiAccordionSummary-expandIcon {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  
  .adjustmentexpand>div.MuiAccordionSummary-expandIcon.Mui-expanded {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  
  .adjustmentaccordions {
    display: inline-flex;
  }
  
  .adjustmentaccordions::-webkit-scrollbar {
    display: none;
  }
  
  .adjustmentexpand>div.MuiAccordionSummary-content {
    order: 0;
    min-width: 60px;
    margin: 6px 11px !important;
  }
  
  .applyMonthMargin>div:nth-child(1) {
    min-width: 100px;
  }
  
  .verticalAdjustmentAccordion {
    width: 8%;
    min-width: 8%;
    margin-left: 18px;
  }
  
  .adjustmentsview {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    padding: 0px 0px 0px 0px;
  }
  
  .adjustmentAlignment {
    /* display: flex; */
    font-size: 13px;
    flex-direction: column;
  }
  
  .accordionContent {
    display: flex;
  }
  
  .alignTitleContent {
    display: flex;
  }
  
  .alignTitle {
    display: flex;
    flex-direction: column;
    margin-top: -10px;
    margin-left: 10px;
    text-align: left;
  }
  
  .alignTotal {
    display: flex;
    flex-direction: column;
    margin-top: -10px;
    margin-left: 10px;
    text-align: left;
    background-color: #f3f2f3;
    border-radius: 3px;
  }
  
  .allBUAccordionTotal {
    display: flex !important;
    flex-direction: column !important;
    padding-right: 20px;
    font-size: 13px;
    width: 80px;
  }
  
  .coreBUalignContent {
    display: flex;
    text-align: left;
    flex-direction: column;
    margin-top: -20px;
    margin-left: 3.8%;
  }
  
  .coreBUalignColumnContent {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-left: 3.5%;
  }
  
  .totalCurrentValue {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    width: 80px;
    text-align: right;
    padding-right: 10px;
    padding-top: 0px;
    padding-bottom: 10px;
    color: #808285;
    
  }
  
  .overallDivider {
      width: 1127px !important;
      height: 1px;
      background: red;
      margin-left: 43px !important;
      margin-top: 0px !important;
      margin-bottom: 7px !important;
  }
  
  .childDivider {
      width: 1112px !important;
      height: 1px;
      background: red;
      margin-left: 43px !important;
      margin-top: 7px !important;
      margin-bottom: 7px !important;
  }
  
  .overallDivider>div.MuiDivider-root {
    background-color: #f3f2f3 !important;
  }
  
  .subDivider {
    width: 1000px !important;
    height: 1px;
    margin-top: 20px !important;
    margin-left: -100px !important;
  }
  
  .subDivider>div.MuiDivider-root {
    background-color: #f3f2f3 !important;
  }
  
  .adjustmentheading { 
    min-width: 116px;
    width: 116px;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    color: #434244;
    display: inline-flex;
    margin-top: 3px;
    margin-right: -47px;
  }
  .displayflexstyle{
    display: flex;
  }
  .componentWidth{
    width: 898px;
  }
  .activityediticon{
    margin: -14px 6px 34px 6px;
    padding: 44px 0 14px;
    /* box-shadow: -5px 10px 20px 0 rgb(0 0 0 / 10%); */
    background-color: #fff;
  }
  .zonesediticon{
    margin: -34px 18px 34px 18px;
    padding: 44px 0 14px;
    /* box-shadow: -5px 10px 20px 0 rgb(0 0 0 / 10%); */
    background-color: #fff;
  }
  .displayflexstyle>span>button.MuiButton-root:hover{
    background-color:#ffffff !important;
  }
  .disableEdit{
    opacity: 0.3;
    background-color: red;
  }
  .disableSave>span.MuiButton-label{
    margin: -1px 4px 1px -6px;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #434244;
    opacity: 0.3;
  }
  .adjustmentsSaveBtn{
    border: solid 1px #fc0;
    border-radius: 5px;
    height: 29px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin:24px 0px 0px 5px ;
    background-color: #ffcc00;
    box-shadow: 0 0 50px 0 rgb(0 0 0 / 10%);
    /* width: 47px; */
    /* if width is applied to save button it will scroll */
  }
  .adjustmentsSavetext>span.MuiButton-label{
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #434244;
  }
  .yearcomponent{
    overflow: auto;
  }
.adjustmentinputfield>div>div>div>fieldset.MuiOutlinedInput-notchedOutline{
    border: none !important;
}
.adjustmentinputfield>div>div>div>input{
  color: #808285;
  border-radius: 3px !important;
  border: solid 1.5px #cccdd0 !important;
  background-color: #fff !important;
  text-align: right !important;
  padding: 6px 0 6px 0 !important;
  margin: 0 -10px 0 10px !important;
  /* overriding default padding */
}
.adjustmentinputdisablefield>div>div>div>input{
    color: #800080;
    border: none !important;
  /* border-radius: 3px !important;
  border: solid 1.5px #cccdd0 !important; */
  /* background-color:#f3f2f3 !important; */
  text-align: right !important;
  padding: 6px 0 6px 0 !important;
  margin: 0 -12px 0 11px;
  }
.editablefield{
    margin: 0px 0px -3px 16px;
    font-size: 13px;
    color: #808285;
}
.adjustmentinputfield>div>div>div{
    min-width: 10px !important;
}
.errorMsgStyle>div>div>div>input{
  color: #808285;
  border-radius: 3px !important;
  border: solid 1.5px #E90133 !important;
  background-color: #fff !important;
  text-align: right !important;
  padding: 6px 0 6px 0 !important;
  /* overriding default padding */
}
.errorMsgStyle>div>div>div::after{    
    content: "";
    position: absolute;
    top:1px;
    right: 1px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 0;
    border-color: transparent #E90133 transparent transparent;
}
.errorMsgStyle>div>div>div::before{    
    content: "";
    position: absolute;
    top:1px;
    right: 1px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 0;
    border-color: transparent #E90133 transparent transparent;
}
.errorMsgStyle>div>div>div>fieldset.MuiOutlinedInput-notchedOutline{
    border: none !important;
}
.errorMsgStyle>div>div>div{
    min-width: 88px !important;
}
.adjustmentheader{
    width: 193px;
    height: 38px;
    margin: 30px 555px 30px 28px;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: -1px;
    color: #000000;
    margin-top: -16px;
    padding-top: 8px;
}
.adjustmentrun{
    display: flex;
}
.adjustmentmargin{
    margin-left: 17px;
    margin-top: 20px;
}
.adjustmentflexitems{
    display: flex !important;
    justify-content: space-between;
    margin: 10px;
}
.adjustmentrun>button.MuiButton-text{
    width: 180px;
    height: 25px;
    margin: 0 42px 0 0px;
    padding: 14px 2px 15px 2px !important;
    border-radius: 4px;
    background-color: #ffcc00;
    font-stretch: normal;
    font-style: normal;
    /* color: #434244; */
    font-size: 14px;
}
.runblur{
    -webkit-filter: blur(0.4px);
            filter: blur(0.4px);
}

/* // */

/* .mainAdjustmentHeader, .alignAdjustments {
    width: auto !important; 
}
.adjustmentmargin {
    margin-left: 0px;
}
.adjustmentrun {
    width: 25%;
}
.adjustmentsFilterStyle {
    min-width: 85%;
    justify-content: flex-start;
}
.AllBumainAdjustmentHeader {
    width: 92%;
}
.alignAllBuAdjustments {
    width: 91%;
    padding-right: 12px;
}

@media screen and (max-width: 1500px) {
    .adjustmentmargin {
        height: 750px;
    }
} */
.adminpage{
    margin-left: 5px;
    /* margin-top: 20px; */
    /* margin-right: 40px; */
    height: 100%;
    /* border: 1px solid red; */
}
.headertext{
font-size: 30px;
font-weight: 300;
font-stretch: normal;
font-style: normal;
line-height: 1.06;
letter-spacing: -1px;
color: #000000;
}

.adminpage #grid-theme-wrapper>div>div {
    height: 90% !important;
    /* border: 1px solid red; */
}
.adminpage #grid-theme-wrapper>div>div>div {
    height: 100% !important;
}
.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input {
    border: 1px solid lightgray;
    border-radius: 3px;
    padding-right: 21px !important;
    width: 98px !important;
    background: white;
    min-width: 90px;
    height: 12px;
    margin-top: 1px !important;
    margin-right: 2px !important;
    color: black !important;
    font-size: 13px !important;
}

.MuiAutocomplete-option {
    font-size: 13px !important;
    color: black !important;
    padding: 5px !important
}

.MuiAutocomplete-popupIndicatorOpen {
    padding-left: 8px !important;
    padding-right: 8px !important;
    padding-top: 3px !important;
}

.MuiAutocomplete-popupIndicator {
    padding: 2px;
    margin-right: -2px;
    position: relative;
    display: flex;
}

/* .MuiAutocomplete-popupIndicatorOpen {
    padding-right: 3px !important;
} */
.customIcons {
    justify-content: space-around;
    display: flex;
    padding-top: 10px;
}

.ag-theme-alpine .ag-ltr .ag-cell {
    border-radius: 0px !important;
}

.ag-theme-alpine .ag-cell-inline-editing {
    box-shadow: 0 0 0 0 transparent;
}

.ag-input-field-input {
    border: solid 1px;
    padding: 5px;
    border-radius: 0px;
    box-shadow: 0px 0px 0px 0px !important;
    width: 93%;
    height: 32px !important;
    min-height: 32px;
    margin: 5px;
    background-color: #f6f5f6;
}

.ag-theme-alpine .ag-ltr input[class^='ag-']:not([type]), .ag-theme-alpine .ag-ltr input[class^='ag-'][type='text'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='number'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='tel'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='date'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='datetime-local'], .ag-theme-alpine .ag-ltr textarea[class^='ag-'] {
    margin: 3px;
    margin-left: 5px !important;
    text-align: right;
    border: solid 1px lightgray !important;
}

.ag-root-wrapper {
    border: 0px !important;
}

.ag-header {
    height: 40px !important;
    min-height: 40px !important;
    border-bottom: solid 2px !important;
    border-bottom-color: lightgray !important;
}

.ag-header-viewport {
    background-color: white;
}

.ag-pinned-right-header {
    background-color: white;
}

.ag-header-cell {
    padding-bottom: 10px !important;
}

.ag-header-cell-resize {
    /* height: 0% !important */
}

.ag-header-row:after {
    right: -1px !important;
}

.ag-floating-bottom-viewport {
    background: #ffed9b !important;
}

.ag-floating-bottom {
    background: #ffed9b !important;
}

.ag-row-pinned {
    background: #fff3bc !important;
}

.ag-theme-alpine .ag-cell-inline-editing {
    background: #f6f5f6 !important;
    box-shadow: 0 1px 4px 1px transparent !important;
    border-color: transparent !important;
    border: 0px !important;
    border-radius: 0px !important;
    padding-right: 2px !important;
}

.ag-row-pinned>.ag-cell-inline-editing {
    background: #f6f5f6 !important;
}

.ag-theme-alpine .ag-row {
    border: 1px solid;
    border-color: lightgray;
    font-size: 13px;
}

.ag-icon {
    color: grey;
}

#grid-theme-wrapper.app-theme .ag-theme-alpine .ag-row-selected {
    background-color: #f6f5f6;
}

/* div.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-focus {
    background-color: whitesmoke !important;
} */

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
    color: whitesmoke !important;
    background-color: black;
    width: 13px !important;
    height: 13px !important;
    line-height: 13px !important;
    border: 1px solid darkgray;
}

.ag-theme-alpine .ag-checkbox-input-wrapper {
    background-color: white;
    font-size: 14px;
    width: 14px;
    height: 14px;
    line-height: 14px;
}

.ag-theme-alpine .ag-row-odd {
    background-color: white;
}

.ag-theme-alpine .ag-row-hover {
    background: #f6f5f6;
}

.copyRow {
    background-color: #e2f4ff !important;
}

/* small table row icon hover effect */

/* .ag-pinned-right-cols-container img {
    opacity: .7;
}

.ag-pinned-right-cols-container img:hover {
    opacity: 1;
    cursor: pointer;
} */

/*  move text in date picker up a little */

.MuiFormControl-marginNormal .MuiInputBase-input {
    padding-top: 5px !important;
}

/* add table input focus state */

.ag-theme-alpine input[class^=ag-]:not([type]):focus, .ag-theme-alpine input[class^=ag-][type=date]:focus, .ag-theme-alpine input[class^=ag-][type=datetime-local]:focus, .ag-theme-alpine input[class^=ag-][type=number]:focus, .ag-theme-alpine input[class^=ag-][type=tel]:focus, .ag-theme-alpine input[class^=ag-][type=text]:focus, .ag-theme-alpine textarea[class^=ag-]:focus, .MuiAutocomplete-inputRoot[class*=MuiInput-root] .MuiAutocomplete-input:focus, .ag-cell-focus .MuiFormControl-marginNormal {
    outline: none;
    border-bottom: 1.5px solid #4c97d0 !important;
    /* -webkit-box-shadow: inset 0 1px 5px 4px #e2f4ff !important; */
    /* box-shadow: inset 0 1px 5px 4px #e2f4ff !important; */
}

.validRow, .errorCell {
    background-color: transparent !important;
}

.validRow::after, .errorCell::after {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 0;
    border-color: transparent #E90133 transparent transparent;
} 

 .validRow::before, .errorCell::before {
    content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    display: block;
    border: 1.5px solid #E90133;
    border-radius: 3px;
} 

.validRow::after {
    border-right-color: #006bbd;
}

.validRow::before {
    border-color: #006bbd;
} 

/* Add New row (highlight medium blue left border) */

.ag-center-cols-clipper .copyRow::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 7px;
    background-color: #006bbd;
}

.ag-center-cols-clipper .errorRow::before{
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 7px;
    background-color: #eb7000;
}

.ag-center-cols-clipper .errorCellBorder::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 7px;
    background-color: #eb7000;
}

.errorRow {
    background-color: #ffe2c9 !important;
}

div.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height ag-cell-first-right-pinned ag-cell-value ag-cell-focus {
    border-left: 1px solid transparent !important;
}

.ag-theme-alpine .ag-ltr .ag-cell.typehotstyle{
    width: 75px !important;
    height: 36px !important;
    border-radius: 5px !important;
    background-color: #fce3b8 !important;
    margin: 2px 1px 2px 1px;
    padding: -1px 0px 0px 0px;
    text-align: center;
    padding-top: -1px;
    line-height: 32px;
}
.ag-theme-alpine .ag-ltr .ag-cell.typecoldstyle{
    width: 75px !important;
    height: 36px !important;
    border-radius: 5px !important;
    margin: 2px 1px 2px 1px;
    padding: -1px 0px 0px 0px;
    text-align: center;
    padding-top: -1px;
    line-height: 32px;
    background-color: #c7e5f8 !important;
}
.ag-theme-alpine .ag-ltr .ag-cell.blankvaluestyle{
    width: 75px !important;
    height: 36px !important;
    border-radius: 5px !important;
    margin: 2px 1px 2px 1px;
    padding: -1px 0px 0px 0px;
    text-align: center;
    padding-top: -1px;
    line-height: 32px;
    background-color: #80828594 !important;
}
.ag-cell-focus {
    border: 1px solid #006bbd !important;
}

.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input {
    /* border: 1px solid transparent; */
    border-radius: 3px;
    padding-right: 15px !important;
    margin-left: 5px !important;
    width: 98px !important;
    background: white;
    min-width: 90px;
    height: 14px;
    margin-top: 0px !important;
    margin-right: 2px !important;
    color: black !important;
    font-size: 13px !important;
}

/*.ag-theme-alpine .ag-ltr input[class^='ag-']:not([type]), .ag-theme-alpine .ag-ltr input[class^='ag-'][type='text'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='number'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='tel'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='date'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='datetime-local'], .ag-theme-alpine .ag-ltr textarea[class^='ag-'] {
    margin: 5px;
    border: solid 1px lightgray !important;
} */

.ag-theme-alpine input[class^='ag-']:not([type]), .ag-theme-alpine input[class^='ag-'][type='text'], .ag-theme-alpine input[class^='ag-'][type='number'], .ag-theme-alpine input[class^='ag-'][type='tel'], .ag-theme-alpine input[class^='ag-'][type='date'], .ag-theme-alpine input[class^='ag-'][type='datetime-local'], .ag-theme-alpine textarea[class^='ag-'] {
    min-height: 24px;
    border-radius: 3px;
} 

/* div.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-first-right-pinned.ag-cell-value.ag-cell-focus {
    border-left: 10px solid transparent !important;

} */
.mainHeader {
    background-color: white;
    -webkit-margin-before: -36px !important;
            margin-block-start: -36px !important;
    padding-bottom: 5px;
    color: white;
    min-width: 1104px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .actionButtons>div:nth-child(1).Mui-focused {
    background-color: none !important;
  }
  
  .viewdropdown>div.MuiInputBase-input {
    padding: 0px 2px 7px 3px !important;
  }
  
  .viewdropdown>div.MuiSelect-select:focus {
    background-color: none !important;
  }
  
  .viewdropdown>svg.MuiSelect-icon {
    padding: 0px 2px 7px 2px !important;
  }
  
  .saveButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
    min-width: 36%;
    margin-right: 10px;
    margin-top: 10px;
  }
  
  .actionButtons {
    display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      flex-direction: row;
      min-width: 36%;
      margin-right: 10px;
      margin-top: 15px;
  }
  
  .filterMain {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 24px;
  }
  
  .actionButtons>Button {
    padding: 14px !important;
    margin: 5px !important;
    text-transform: none !important;
    padding: 10px;
  }
  
  .imageIcon {
    margin: 10px;
    width: 24px !important;
    height: 28px;
  }
  
  .MuiMenuItem-root {
    font-size: 13px !important;
  }
  
  .MuiSelect-selectMenu {
    width: 165px !important;
    border-radius: 3px;
    margin-top: 5px !important;
    padding: 5px !important;
  }
  
  .MuiFormControl-root {
    margin-right: 10px !important;
  }
  
  .MuiSvgIcon-root {
    padding-top: 3px !important;
    margin-top: 0px !important;
    padding-right: 5px;
  }
  
  .MuiSelect-select {
    /* width: 120px !important; */
    border-radius: 3px !important;
    /* border: 1px solid grey !important; */
    /* background: #ffcc00 !important;  */
  }
  
  .dropdownfields {
    display: flex;
    flex-direction: column;
    width: 176px;
    margin-top: 5px;
  }
  
  .flexContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
  }
  
  .MuiTypography-gutterBottom {
    margin-bottom: 0 !important;
  }
  
  .MuiMenuItem-root {
    font-family: customFontFamily !important;
    height: 28px;
    border-bottom: 1px solid lightgray;
  }
  
  .MuiInputBase-root {
    font-family: customFontFamily !important;
  }
  
  .MuiTypography-body1 {
    font-family: customFontFamily !important;
  }
  
  .muiDatePicker {
    margin-top: 0px !important;
    border: 1px solid #808285 !important;
    border-radius: 3px !important;
    padding-left: 5px !important;
    width: 165px !important;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: #808285 !important;
    border-radius: 3px !important;
    border-width: 1px !important;
  }
  
  .filterStyle {
    /* min-width: 63%;
    display: flex;
    flex-direction: row; */
    display: flex;
    flex-direction: row;
    margin-top: 15px;
  }
  
  .viewdropdown {
    width: 90px;
    height: 34px;
    margin: 0 0 0 18px;
    padding: 1px;
    border-radius: 5px;
    background-color: #ffcc00;
  }
  
  .actionButtons>div.MuiFormControl-root {
    margin-top: 7px !important;
  }
  
  .primaryButton {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding-top: 2px;
    background-color: #ffcc00;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  }
  
  .secondaryButton {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding-top: 2px;
    background-color: transparent !important;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  }
  
  .MuiButton-label {
    text-transform: none !important;
  }
  
  .searchbar {
    margin-right: 48%;
    margin-top: 8px;
  }
  
  .MuiDialog-paperWidthSm {
    max-width: 675px !important;
  }
  
  .imageSpacing {
    padding-right: 15px !important;
    min-width: 24px !important;
  }
  
  .MuiListItem-root.Mui-selected {
    background-color: transparent !important;
  }
  
  .actiobarPopOver>div.MuiPopover-paper {
    border-top-right-radius: 0px !important;
    box-shadow: -5px 10px 20px 0px rgba(0, 0, 0, 0.1) !important;
    background-color: #ffffff !important;
    overflow-y: hidden !important;
    margin-top: 0.7px !important;
  }
  
  div.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  
  .MuiDivider-root {
    background-color: #f3f2f3;
  }
  
.metadatatabs{
    display: flex;
}
.metadatabgcolor{
    background-color:#ffffff;
    /* height: 650px; */
    min-width: 1000px;
    width: 1110px;
}
.metadatatabs>div>div:nth-child(3)>div>button{
    margin: 0 0 5px 12px !important;
}

.metadatatabs>div>div:nth-child(3)>div>button:active{
    border-radius: 4px !important;
    background-color: #ffed9b !important;
    color: #000000;
}

.metadatatabpanel{
    min-width: 942px;
    margin-top: -35px;
}
.metadatatabpanel>div>p.MuiTypography-body1{
    width:917px;
}
.metadatatabs>div.MuiTabs-vertical{
    min-width: 18%;
    /* height: 528px; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: -91px;
    margin-left: -7px;
}

/* */
.metadatabgcolor {
    background-color: #ffffff;
    /* height: 650px; */
    min-width: inherit;
    width: auto;
}
.metadatatabpanel {
    min-width: 82%;
    margin-top: -35px;
    width: 100%;
}
.metadatatabpanel>div>p.MuiTypography-body1 {
    width: auto;
}
.metadatamainHeader {
    min-width: auto !important;
    width: auto !important;
}
.adminpage {
    margin-right: 7px;
  }
  .metadataactionButtons {
    min-width: 50% !important;
  }

.adminpage #grid-theme-wrapper>div>div {
    height: 100% !important;
    /* border: 1px solid red; */
}
.adminpage #grid-theme-wrapper>div>div>div {
    height: 100% !important;
}

@media screen and (max-width: 4000px) {
    /* #grid-theme-wrapper>div>div>div{
        height: 1300px !important;
    } */
    /* .adminpage {
        height: 1600px !important;
    } */
    /* .metadatabgcolor {
        height: 1400px;
    } */
}

@media screen and (max-width: 2600px) {
    /* #grid-theme-wrapper>div>div>div{
        height: 950px !important;
    } */
    /* .adminpage {
        height: 1200px !important;
    } */
    /* .metadatabgcolor {
        height: 1050px;
    } */

}
@media screen and (max-width: 1500px) {
    /* #grid-theme-wrapper>div>div>div{
        height: 650px !important;
    } */
    /* .adminpage {
        height: 920px !important;
    } */
    /* .metadatabgcolor {
        height: 730px;
    } */
}
.metadatamainHeader {
    background-color: white;
    -webkit-margin-before: -36px !important;
            margin-block-start: -36px !important;
    padding-bottom: 5px;
    color: white;
    min-width: 800px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 950px;
    margin-left: 19%;
  }
  
  .metadataactionButtons>div:nth-child(1).Mui-focused {
    background-color: none !important;
  }
  
  .viewdropdown>div.MuiInputBase-input {
    padding: 0px 2px 7px 3px !important;
  }
  
  .viewdropdown>div.MuiSelect-select:focus {
    background-color: none !important;
  }
  
  .viewdropdown>svg.MuiSelect-icon {
    padding: 0px 2px 7px 2px !important;
  }
  
  .metadatasaveButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
    min-width: 56%;
    margin-right: 10px;
    margin-top: 15px;
  }
  
  .metadataactionButtons {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      flex-direction: row;
      min-width: 0%;
      margin-right: 10px;
      margin-top: 15px;
  }
  
  .filterMain {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 24px;
  }

  .filterMainForRange {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 24px;
    width: 100%;
  }
  
  .metadataactionButtons>Button {
    padding: 14px !important;
    margin: 5px !important;
    text-transform: none !important;
    padding: 10px;
  }
  
  .imageIcon {
    margin: 10px;
    width: 24px !important;
    height: 28px;
  }
  
  .MuiMenuItem-root {
    font-size: 13px !important;
  }
  
  .MuiSelect-selectMenu {
    width: 165px !important;
    border-radius: 3px;
    margin-top: 5px !important;
    padding: 5px !important;
  }
  
  .MuiFormControl-root {
    margin-right: 10px !important;
  }
  
  .MuiSvgIcon-root {
    padding-top: 3px !important;
    margin-top: 0px !important;
    padding-right: 5px;
  }
  
  .MuiSelect-select {
    /* width: 120px !important; */
    border-radius: 3px !important;
    /* border: 1px solid grey !important; */
    /* background: #ffcc00 !important;  */
  }
  
  .dropdownfields {
    display: flex;
    flex-direction: column;
    width: 176px;
    margin-top: 5px;
  }
  
  .flexContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
  }
  
  .MuiTypography-gutterBottom {
    margin-bottom: 0 !important;
  }
  
  .MuiMenuItem-root {
    font-family: customFontFamily !important;
    height: 28px;
    border-bottom: 1px solid lightgray;
  }
  
  .MuiInputBase-root {
    font-family: customFontFamily !important;
  }
  
  .MuiTypography-body1 {
    font-family: customFontFamily !important;
  }
  
  .muiDatePicker {
    margin-top: 0px !important;
    border: 1px solid #808285 !important;
    border-radius: 3px !important;
    padding-left: 5px !important;
    width: 165px !important;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: #808285 !important;
    border-radius: 3px !important;
    border-width: 1px !important;
  }
  
  .metadatafilterStyle {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    min-width: 47%;
  }
  
  .viewdropdown {
    width: 90px;
    height: 34px;
    margin: 0 0 0 18px;
    padding: 1px;
    border-radius: 5px;
    background-color: #ffcc00;
  }
  
  .metadataactionButtons>div.MuiFormControl-root {
    margin-top: 7px !important;
  }
  
  .primaryButton {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding-top: 2px;
    background-color: #ffcc00;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  }
  
  .secondaryButton {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding-top: 2px;
    background-color: transparent !important;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  }
  
  .MuiButton-label {
    text-transform: none !important;
  }
  
  .metadatasearchbar {
    margin-right: 0%;
    margin-top: 8px;
  }
  
  .MuiDialog-paperWidthSm {
    max-width: 675px !important;
  }
  
  .imageSpacing {
    padding-right: 15px !important;
    min-width: 24px !important;
  }
  
  .MuiListItem-root.Mui-selected {
    background-color: transparent !important;
  }
  
  .actiobarPopOver>div.MuiPopover-paper {
    border-top-right-radius: 0px !important;
    box-shadow: -5px 10px 20px 0px rgba(0, 0, 0, 0.1) !important;
    background-color: #ffffff !important;
    overflow-y: hidden !important;
    margin-top: 0.7px !important;
  }
  
  div.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  
  .MuiDivider-root {
    background-color: #f3f2f3;
  }
  
.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input {
    border: 1px solid lightgray;
    border-radius: 3px;
    padding-right: 21px !important;
    width: 98px !important;
    background: white;
    min-width: 90px;
    height: 12px;
    margin-top: 1px !important;
    margin-right: 2px !important;
    color: black !important;
    font-size: 13px !important;
}

.MuiAutocomplete-option {
    font-size: 13px !important;
    color: black !important;
    padding: 5px !important
}

.MuiAutocomplete-popupIndicatorOpen {
    padding-left: 8px !important;
    padding-right: 8px !important;
    padding-top: 3px !important;
}

.MuiAutocomplete-popupIndicator {
    padding: 2px;
    margin-right: -2px;
    position: relative;
    display: flex;
}

/* .MuiAutocomplete-popupIndicatorOpen {
    padding-right: 3px !important;
} */
.actionrendererstyle{
display: flex;
justify-content: space-between;
width: 50px;
margin-top: 8px;
}
.fontfam
{
font-family:wingdings;
text-transform: lowercase;
margin:0;
}
.customIcons {
    justify-content: space-around;
    display: flex;
    padding-top: 10px;
}

.ag-theme-alpine .ag-ltr .ag-cell {
    border-radius: 0px !important;
}

.ag-theme-alpine .ag-cell-inline-editing {
    box-shadow: 0 0 0 0 transparent;
}

.ag-input-field-input {
    border: solid 1px;
    padding: 5px;
    border-radius: 0px;
    box-shadow: 0px 0px 0px 0px !important;
    width: 93%;
    height: 32px !important;
    min-height: 32px;
    margin: 5px;
    background-color: #f6f5f6;
}

.ag-theme-alpine .ag-ltr input[class^='ag-']:not([type]), .ag-theme-alpine .ag-ltr input[class^='ag-'][type='text'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='number'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='tel'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='date'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='datetime-local'], .ag-theme-alpine .ag-ltr textarea[class^='ag-'] {
    margin: 3px;
    margin-left: 5px !important;
    text-align: right;
    border: solid 1px lightgray !important;
}

.ag-root-wrapper {
    border: 0px !important;
}

.ag-header {
    height: 40px !important;
    min-height: 40px !important;
    border-bottom: solid 2px !important;
    border-bottom-color: lightgray !important;
}

.ag-header-viewport {
    background-color: white;
}

.ag-pinned-right-header {
    background-color: white;
}

.ag-header-cell {
    padding-bottom: 10px !important;
}

.ag-header-cell-resize {
    /* height: 0% !important */
}

.ag-right-aligned-header .ag-header-label-icon { 
    margin-left: 6px !important;
    margin-right: 0px !important;
}

 .ag-right-aligned-header .ag-header-cell-label {
    flex-direction: row !important;
} 
.ag-right-aligned-header .ag-header-cell-text{
    flex-grow: 1;
    text-align: right;
}

.ag-header-row:after {
    right: -1px !important;
}

.ag-floating-bottom-viewport {
    background: #ffed9b !important;
}

.ag-floating-bottom {
    background: #ffed9b !important;
}

.ag-row-pinned {
    background: #fff3bc !important;
}

.ag-theme-alpine .ag-cell-inline-editing {
    background: #f6f5f6 !important;
    box-shadow: 0 1px 4px 1px transparent !important;
    border-color: transparent !important;
    border: 0px !important;
    border-radius: 0px !important;
    padding-right: 2px !important;
}

.ag-row-pinned>.ag-cell-inline-editing {
    background: #f6f5f6 !important;
}

.ag-theme-alpine .ag-row {
    border: 1px solid;
    border-color: lightgray;
    font-size: 13px;
}

.ag-icon {
    color: grey;
}

#grid-theme-wrapper.app-theme .ag-theme-alpine .ag-row-selected {
    background-color: #f6f5f6;
}

/* div.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-focus {
    background-color: whitesmoke !important;
} */

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
    color: whitesmoke !important;
    background-color: black;
    width: 13px !important;
    height: 13px !important;
    line-height: 13px !important;
    border: 1px solid darkgray;
}

.ag-theme-alpine .ag-checkbox-input-wrapper {
    background-color: white;
    font-size: 14px;
    width: 14px;
    height: 14px;
    line-height: 14px;
}

.ag-theme-alpine .ag-row-odd {
    background-color: white;
}

.ag-theme-alpine .ag-row-hover {
    background: #f6f5f6;
}

.copyRow {
    background-color: #e2f4ff !important;
}

/* small table row icon hover effect */

/* .ag-pinned-right-cols-container img {
    opacity: .7;
}

.ag-pinned-right-cols-container img:hover {
    opacity: 1;
    cursor: pointer;
} */

/*  move text in date picker up a little */

.MuiFormControl-marginNormal .MuiInputBase-input {
    padding-top: 5px !important;
}

/* add table input focus state */

.ag-theme-alpine input[class^=ag-]:not([type]):focus, .ag-theme-alpine input[class^=ag-][type=date]:focus, .ag-theme-alpine input[class^=ag-][type=datetime-local]:focus, .ag-theme-alpine input[class^=ag-][type=number]:focus, .ag-theme-alpine input[class^=ag-][type=tel]:focus, .ag-theme-alpine input[class^=ag-][type=text]:focus, .ag-theme-alpine textarea[class^=ag-]:focus, .MuiAutocomplete-inputRoot[class*=MuiInput-root] .MuiAutocomplete-input:focus, .ag-cell-focus .MuiFormControl-marginNormal {
    outline: none;
    border-bottom: 1.5px solid #4c97d0 !important;
    /* -webkit-box-shadow: inset 0 1px 5px 4px #e2f4ff !important; */
    /* box-shadow: inset 0 1px 5px 4px #e2f4ff !important; */
}

.validRow, .errorCell {
    background-color: transparent !important;
}

.validRow::after, .errorCell::after {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 0;
    border-color: transparent #E90133 transparent transparent;
} 

 .validRow::before, .errorCell::before {
    content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    display: block;
    border: 1.5px solid #E90133;
    border-radius: 3px;
} 

.validRow::after {
    border-right-color: #006bbd;
}

.validRow::before {
    border-color: #006bbd;
} 

/* Add New row (highlight medium blue left border) */

.ag-center-cols-clipper .copyRow::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 7px;
    background-color: #006bbd;
}

.ag-center-cols-clipper .errorRow::before{
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 7px;
    background-color: #eb7000;
}

.ag-center-cols-clipper .errorCellBorder::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 7px;
    background-color: #eb7000;
}

.errorRow {
    background-color: #ffe2c9 !important;
}

div.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height ag-cell-first-right-pinned ag-cell-value ag-cell-focus {
    border-left: 1px solid transparent !important;
}

.ag-theme-alpine .ag-ltr .ag-cell.typehotstyle{
    width: 75px !important;
    height: 36px !important;
    border-radius: 5px !important;
    background-color: #fce3b8 !important;
    margin: 2px 1px 2px 1px;
    padding: -1px 0px 0px 0px;
    text-align: center;
    padding-top: -1px;
    line-height: 32px;
}
.ag-theme-alpine .ag-ltr .ag-cell.typecoldstyle{
    width: 75px !important;
    height: 36px !important;
    border-radius: 5px !important;
    margin: 2px 1px 2px 1px;
    padding: -1px 0px 0px 0px;
    text-align: center;
    padding-top: -1px;
    line-height: 32px;
    background-color: #c7e5f8 !important;
}
.ag-theme-alpine .ag-ltr .ag-cell.blankvaluestyle{
    width: 75px !important;
    height: 36px !important;
    border-radius: 5px !important;
    margin: 2px 1px 2px 1px;
    padding: -1px 0px 0px 0px;
    text-align: center;
    padding-top: -1px;
    line-height: 32px;
    background-color: #80828594 !important;
}
.ag-cell-focus {
    border: 1px solid #006bbd !important;
}

.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input {
    /* border: 1px solid transparent; */
    border-radius: 3px;
    padding-right: 15px !important;
    margin-left: 5px !important;
    width: 98px !important;
    background: white;
    min-width: 90px;
    height: 14px;
    margin-top: 0px !important;
    margin-right: 2px !important;
    color: black !important;
    font-size: 13px !important;
}

/*.ag-theme-alpine .ag-ltr input[class^='ag-']:not([type]), .ag-theme-alpine .ag-ltr input[class^='ag-'][type='text'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='number'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='tel'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='date'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='datetime-local'], .ag-theme-alpine .ag-ltr textarea[class^='ag-'] {
    margin: 5px;
    border: solid 1px lightgray !important;
} */

.ag-theme-alpine input[class^='ag-']:not([type]), .ag-theme-alpine input[class^='ag-'][type='text'], .ag-theme-alpine input[class^='ag-'][type='number'], .ag-theme-alpine input[class^='ag-'][type='tel'], .ag-theme-alpine input[class^='ag-'][type='date'], .ag-theme-alpine input[class^='ag-'][type='datetime-local'], .ag-theme-alpine textarea[class^='ag-'] {
    min-height: 24px;
    border-radius: 3px;
} 

/* div.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-first-right-pinned.ag-cell-value.ag-cell-focus {
    border-left: 10px solid transparent !important;

} */
.revFunctionHeader {
    background-color: white;
    -webkit-margin-before: -36px !important;
            margin-block-start: -36px !important;
    padding-bottom: 5px;
    color: white;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  
  .actionButtons>div:nth-child(1).Mui-focused {
    background-color: none !important;
  }
  
  .viewdropdown>div.MuiInputBase-input {
    padding: 0px 2px 7px 3px !important;
  }
  
  .viewdropdown>div.MuiSelect-select:focus {
    background-color: none !important;
  }
  
  .viewdropdown>svg.MuiSelect-icon {
    padding: 0px 2px 7px 2px !important;
  }
  
  .saveButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
    min-width: 36%;
    margin-right: 10px;
    margin-top: 10px;
  }
  
  .actionButtons {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      flex-direction: row;
      min-width: 36%;
      margin-right: 10px;
      margin-top: 15px;
  }
  
  .filterMain {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 24px;
  }
  
  .actionButtons>Button {
    padding: 14px !important;
    margin: 5px !important;
    text-transform: none !important;
    padding: 10px;
  }
  
  .imageIcon {
    margin: 10px;
    width: 24px !important;
    height: 28px;
  }
  
  .MuiMenuItem-root {
    font-size: 13px !important;
  }
  
  .MuiSelect-selectMenu {
    width: 165px !important;
    border-radius: 3px;
    margin-top: 5px !important;
    padding: 5px !important;
  }
  
  .MuiFormControl-root {
    margin-right: 10px !important;
  }
  
  .MuiSvgIcon-root {
    padding-top: 3px !important;
    margin-top: 0px !important;
    padding-right: 5px;
  }
  
  .MuiSelect-select {
    /* width: 120px !important; */
    border-radius: 3px !important;
    /* border: 1px solid grey !important; */
    /* background: #ffcc00 !important;  */
  }
  
  .dropdownfields {
    display: flex;
    flex-direction: column;
    width: 176px;
    margin-top: 5px;
  }
  
  .flexContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
  }
  
  .MuiTypography-gutterBottom {
    margin-bottom: 0 !important;
  }
  
  .MuiMenuItem-root {
    font-family: customFontFamily !important;
    height: 28px;
    border-bottom: 1px solid lightgray;
  }
  
  .MuiInputBase-root {
    font-family: customFontFamily !important;
  }
  
  .MuiTypography-body1 {
    font-family: customFontFamily !important;
  }
  
  .muiDatePicker {
    margin-top: 0px !important;
    border: 1px solid #808285 !important;
    border-radius: 3px !important;
    padding-left: 5px !important;
    width: 165px !important;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: #808285 !important;
    border-radius: 3px !important;
    border-width: 1px !important;
  }
  
  .filterStyle {
    /* min-width: 63%;
    display: flex;
    flex-direction: row; */
    display: flex;
    flex-direction: row;
    margin-top: 15px;
  }
  
  .viewdropdown {
    width: 90px;
    height: 34px;
    margin: 0 0 0 18px;
    padding: 1px;
    border-radius: 5px;
    background-color: #ffcc00;
  }
  
  .actionButtons>div.MuiFormControl-root {
    margin-top: 7px !important;
  }
  
  .primaryButton {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding-top: 2px;
    background-color: #ffcc00;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  }
  
  .secondaryButton {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding-top: 2px;
    background-color: transparent !important;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  }
  
  .MuiButton-label {
    text-transform: none !important;
  }
  
  .searchbar {
    margin-right: 48%;
    margin-top: 8px;
  }
  
  .MuiDialog-paperWidthSm {
    max-width: 675px !important;
  }
  
  .imageSpacing {
    padding-right: 15px !important;
    min-width: 24px !important;
  }
  
  .MuiListItem-root.Mui-selected {
    background-color: transparent !important;
  }
  
  .actiobarPopOver>div.MuiPopover-paper {
    border-top-right-radius: 0px !important;
    box-shadow: -5px 10px 20px 0px rgba(0, 0, 0, 0.1) !important;
    background-color: #ffffff !important;
    overflow-y: hidden !important;
    margin-top: 0.7px !important;
  }
  
  div.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  
  .MuiDivider-root {
    background-color: #f3f2f3;
  }
  .programsfilterMargin {
    margin:0px 10px 0px 10px;
  }
  .revFunctionbus{
    background-color: white;
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }  
.dialogbox>div.MuiDialog-container{ 
  height: 68% !important;
}
.dialogbox>div>div.MuiDialog-paperScrollPaper {
  max-height: none;
  width: 482px;
  height: 180px;
  width: 514px;
  height: 255px;
  padding: 12px 6px 12px 6px;
  border-radius: 5px;
  background-color: #ffffff;
}
.modelmfroem_dialogbox>div.MuiDialog-container{ 
height: 68% !important;
}
.modelmfroem_dialogbox>div>div.MuiDialog-paperScrollPaper {
max-height: none;
width: 482px;
height: 180px;
width: 514px;
height: 320px;
padding: 12px 6px 12px 6px;
border-radius: 5px;
background-color: #ffffff;
}
.dialogtitle>h2.MuiTypography-h6{
width: 100%; 
display: flex;
flex-wrap: wrap;
flex-direction: row;
justify-content: space-between;
}
.dialogtitle>h2.MuiTypography-h6>span:nth-child(1){
/* width: 121px;
height: 16px; */
margin: 0 175px 0 0;
font-size: 18px;
font-family: customFontFamily;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: 0.73;
letter-spacing: normal;
color: #434244;
}
.dialogtitle>h2.MuiTypography-h6>span:nth-child(2)>img.closeicon{
width: 16px;
height: 16px;
}
.dialogtitle>h2.MuiTypography-h6>span:nth-child(2){
margin-top: -6px;
}
.dialogcontent.MuiTypography-body1{
width: 356px;
height: 13px;
margin-top: 11px;
margin-bottom: 18px;
font-size: 16px !important;
font-weight: normal !important;
font-stretch: normal !important;
font-style: normal;
line-height: 1.3 !important;
letter-spacing: normal !important;
color: #434244 !important;
font-family: customFontFamily;
}
.actionbuttons>span>button.MuiButton-root{
  text-transform: capitalize !important;
}
.actionbuttons>button.cancelbutton{
padding: 14px !important;
margin: 5px !important;
border-radius: 4px;
border: solid 1.5px #808285;
background-color: #ffffff;
}
.actionbuttons>span>button.okbutton{
  padding: 14px !important;
  margin: 5px !important;
  /* border: solid 1.5px; */
  border-radius: 4px;
  background-color: #ffcc00;
}

.actionbuttons>span>button.buttonblur{
  padding: 14px !important;
  margin: 5px !important;
  /* border: solid 1.5px; */
  border-radius: 4px;
  background-color: #ffcc00;
  opacity: 0.7;
}

.actionbuttons>span>button>span.MuiButton-label{
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 0.75;
letter-spacing: normal;
text-align: center;
color: #434244;
}
.generateRfTextbox>div>div>input.MuiInputBase-input{
height: 2.1876em !important;
font-size: 18px !important;
padding-left: 8px !important;
}

.salesfinheader{
    width: 300px;
    height: 38px;
    /* margin: 30px 555px 30px 28px; */
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: -1px;
    color: #000000;
    /* margin-top: -16px; */
    padding-top: 8px;
    margin-left: 24px;
}
.salesfinrun{
    display: flex;
}
.salesfinmargin{
    /* margin-left: 17px; */
    margin-top: 20px;
}
.salesfinflexitems{
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    /* margin: 10px; */
}
.runblur{
    -webkit-filter: blur(0.4px);
            filter: blur(0.4px);
}
.width100percent{
    width: 100%;
}
.adjustmentrun{
    display: flex;
}
.adjustmentmargin{
    margin-left: 17px;
    margin-top: 20px;
}
.adjustmentflexitems{
    display: flex !important;
    justify-content: space-between;
    margin: 10px;
}
.adjustmentrun>button.MuiButton-text{
    width: 180px;
    height: 25px;
    margin: 0 22px 0 0px;
    padding: 14px 2px 15px 2px !important;
    border-radius: 4px;
    background-color: #ffcc00;
    font-stretch: normal;
    font-style: normal;
    /* color: #434244; */
    font-size: 13px;
}
.runblur{
    -webkit-filter: blur(0.4px);
            filter: blur(0.4px);
}
.adjustmentheader{
    width: 350px;
    height: 38px;
    margin: 30px 200px 30px 28px;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: -1px;
    color: #000000;
    margin-top: -16px;
    padding-top: 8px;
}
/* // */

/* .mainSalesfinHeader, .alignSalesfins {
    width: auto !important; 
}
.salesfinmargin {
    margin-left: 0px;
}
.salesfinrun {
    width: 25%;
}
.salesfinsFilterStyle {
    min-width: 85%;
    justify-content: flex-start;
}
.AllBumainSalesfinHeader {
    width: 92%;
}
.alignAllBuSalesfins {
    width: 91%;
    padding-right: 12px;
}

@media screen and (max-width: 1500px) {
    .salesfinmargin {
        height: 750px;
    }
} */
.mainSalesAndFinanceHeader {
    background-color: white;
    -webkit-margin-before: -36px !important;
            margin-block-start: -36px !important;
    padding-bottom: 5px;
    color: white;
    width: 1183px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
}
.AllBumainSalesAndFinanceHeader{
    background-color: white;
    -webkit-margin-before: -36px !important;
            margin-block-start: -36px !important;
    padding-bottom: 5px;
    color: white;
    /* width: 1193px; */
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
}

.salesandfinancesActionButtons>div:nth-child(1).Mui-focused {
    background-color: none !important;
}

.salesandfinancesActionButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
}

.salesandfinancesActionButtons>Button {
    padding: 14px !important;
    margin: 5px !important;
    text-transform: none !important;
    padding: 10px;
}

.salesandfinancesFilterStyle {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    min-width: 63%;
}

.salesandfinancesActionButtons>div.MuiFormControl-root {
    margin-top: 0px !important;
}
.adjFilterIcon{
    cursor: pointer;
}

.expandAllCollapseAllButton{
    border: solid 0px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding: 3px;
    padding-top: 5px;
    padding-right: 5px;
    background-color: #ffcc00;
    color: black;
    cursor: pointer;
}

.expandAllCollapseAllButton img{
    width: 16px;/* Original size is 16X16 */
    height: 16px;
    padding: 3px;
}

.errorMessage{
    color: red;
    background-color: #ffcccc;
    flex-basis: 100%;
    align-content: center;
    text-align: center;
    border-radius: 5px;
}

.width100percent{
    width: 100%;
}
.salesandfinancesorttext {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  text-transform: capitalize !important;
}

.salesandfinancevalues {
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #808285;
  margin-top: 10px;
}

.collapsedSalesAndFinanceValues {
  min-width: 99px;
  text-align: right;
  font-size: 13px;
  color: #808285;
}

.salesandfinanceValuesWithMargin {
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #808285;
}

.salesandfinancevaluesgreen {
  margin-top: 10px;
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #0d8746;
  margin-top: 10px;
}

.salesandfinancevaluesred {
  margin-top: 10px;
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #e90133;
}

.salesandfinancevaluesblue {
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #006bbd;
  margin-top: 8px;
}

.salesandfinancetotalFinal {
  background-color: #f3f2f3;
  border-radius: 3px;
  min-width: 80px;
  font-size: 13px;
  padding-right: 10px;
  padding-left: 5px;
  margin-left: 7px;
  z-index: 1;
  color: #434244;
}

.salesandfinancetotal {
  background-color: #f3f2f3;
  border-radius: 3px;
  min-width: 80px;
  font-size: 13px;
  padding-right: 10px;
  padding-left: 5px;
  margin-left: 7px;
  z-index: 1;
  color: #808285;
}

.collapseSalesAndFinanceTotal {
  background-color: #f3f2f3;
  width: 89px;
  font-size: 13px;
  color: #808285;
  border-radius: 3px;
  padding-right: 10px;
  margin-left: 2px;
}

.marginOverall {
  margin-left: 70px;
  margin-right: -111px !important;
  display: flex;
  overflow: auto;
  width:898px;
  height: 20px;
}
.acivitymarginOverall{
  margin-left: 98px;
  margin-right: -111px !important;
  display: flex;
  overflow: auto;
  width:898px;
  height: 20px;
}
.readOnlymarginOverall{
  margin-left: 31px;
  margin-right: -172px !important;
  display: flex;
  overflow: auto;
  width: 1000px;
  height: 20px;
}

.noMarginOverall {
  margin-left: 70px;
  margin-right: -111px !important;
  display: flex;
  overflow: auto;
  width:898px;
  height: 20px;
}
.readOnlynoMarginOverall{
  margin-right: -111px !important;
  display: flex;
  overflow: auto;
  width: 850px;
  height: 20px;
  margin-left: 3px;
}

.rowFlex {
  display: flex;
}
.allBurowFlex{
  display: flex;
}

.noMarginOverall::-webkit-scrollbar {
  display: none;
}
.readOnlynoMarginOverall::-webkit-scrollbar {
  display: none;
}

.marginOverall::-webkit-scrollbar {
  display: none;
}
.acivitymarginOverall::-webkit-scrollbar {
  display: none;
}

.readOnlymarginOverall::-webkit-scrollbar {
  display: none;
}

.marginNegative {
  margin-left: -30px;
}

.greencolor {
  color: #0d8746;
}

.redcolor {
  color: #e90133;
}

.salesandfinancevaluesTotal {
  display: flex;
}

.boldStyle {
  font-weight: bold;
}

.salesandfinance_value_total {
  min-width: 90px;
  text-align: right;
  font-size: 13px;
}

.applymargintotal {
  margin-right: 10px;
}

.salesandfinanceValuesMargin {
  margin-top: -36px;
  display: inline-flex;
  width: 900px;
  text-align: right;
  position: absolute;
  height: 115px;
}

.salesandfinanceValuesOverallMargin {
  margin-top: -30px;
  display: inline-flex;
  width: 900px;
  text-align: right;
  position: absolute;
  height: 98px;
}

.collapsedSalesAndFinanceValuesOverallMargin {
  display: inline-flex;
  width: 900px;
  text-align: right;
  position: absolute;
  height: 98px;
  margin-top: 2px;
  margin-left: 41px;
}

.salesandfinanceBelowMargin {
  display: inline-flex;
  width: 900px;
  text-align: right;
}

.collapsedSalesAndFinanceBelowMargin {
  display: inline-flex;
  width: 900px;
  text-align: right;
}

.salesandfinanceBelowOverallMargin {
  display: inline-flex;
  width: 900px;
  text-align: right;
  height: 28px;
}

.salesandfinancesorttextleftMargin {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  margin-left: 10px;
  text-transform: capitalize !important;
}

.salesandfinancesort {
  border-bottom: solid 2px #f3f2f3;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-start;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}

.coreBUsalesandfinancesort{
  border-bottom: solid 2px #f3f2f3;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}

.alignSalesAndFinance {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding-right: 10px;
  width: 1174px;
}
.alignAllBuSalesAndFinance{
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  /* padding-right: 10px; */
  /* width: 1183px; */
  width: 100%;
}

.salesandfinanceApplyMargin {
  display: flex;
  flex-direction: row;
}

.salesandfinanceMinWidth {
  justify-content: flex-end;
  display: flex;
  width: 84px;
  margin-left: 12px;
  border-radius: 3px;
}
.salesandfinanceAllBuMinWidth{
  justify-content: flex-end;
  display: flex;
  padding-right: 18px;
  width: 84px;
  margin-left: 19px;
  border-radius: 3px;
  padding-right: 8px;
}

.applyMonthMargin {
  display: inline-flex;
  width: 898px;
  overflow: scroll;
  margin-right: 5%;
}

.applyMonthMargin::-webkit-scrollbar {
  height: 10px;
  width: 0px;
}

.applyMonthMargin::-webkit-scrollbar-thumb {
  background: #808285;
  border-radius: 5px;
}

.applySalesAndFinanceMonthMargin {
  display: inline-flex;
  /* width: 85%; */
  width: 850px;
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  overflow: scroll;
}

.applySalesAndFinanceMonthMargin::-webkit-scrollbar {
  height: 10px;
  width: 0px;
}

.applySalesAndFinanceMonthMargin::-webkit-scrollbar-thumb {
  background: #808285;
  border-radius: 5px;
}

.salesandfinanceValuesMargin::-webkit-scrollbar {
  display: none;
}

.salesandfinanceValuesOverallMargin::-webkit-scrollbar {
  display: none;
}

.collapsedSalesAndFinanceValuesOverallMargin::-webkit-scrollbar {
  display: none;
}

.salesandfinanceBelowMargin::-webkit-scrollbar {
  display: none;
}

.salesandfinanceBelowOverallMargin::-webkit-scrollbar {
  display: none;
}

.labelAlignment {
  display: flex;
  width: 14%;
  margin-right: 33px;
}

.salesAndFinanceLabelAlignment {
  display: flex;
  width: 206px;
  margin-left: 17px;
}

.monthYearWidth {
  display: flex;
  min-width: 99px;
  justify-content: flex-end;
}

.headerExpandIcon {
  width: 20px;
}

.salesandfinancedataalignment {
  width: 1065px;
  margin-top: 15px;
  margin-bottom: 30px;
}
.applyFreezMonthColor{
  color:#800080 !important;
}

.width100percent{
  width: 100%;
}
.newadjustmentaccordions {
  overflow: hidden;
  text-align: left;
}

.newadjustmentaccordions>div.MuiPaper-root{
  background: none !important;
}

.adjustmentaccordiondetails.MuiAccordionDetails-root {
  display: flex !important;
  flex-direction: row;
  padding: 8px 0px 16px;
}

.salesAccordiondetails {
  display: flex;
  flex-direction: column;
  /* margin-top: -15px;
  margin-bottom: -15px; */
  margin-left: 23px;
}


.salesOverallAccordiondetails {
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  margin-left: 6px;
  /* border: 1px solid black; */
}

.salesAdjustmentOverallAccordionContent {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  /* border: 1px solid red; */
}

.adjustmentAccordiondetailsTitle.MuiAccordionDetails-root {
  display: flex !important;
  flex-direction: row;
  padding: 5px 0px 16px;
}

.salesOverallAccordiondetailsTitle.MuiAccordionDetails-root {
  display: flex !important;
  flex-direction: row;
  padding: 16px 0px 16px;
}

.salesOverallAccordiondetailsTitle {
  width : 200px;
}

.salesOverallInnerAccordiondetailsTitle.MuiAccordionDetails-root {
  display: flex !important;
  flex-direction: row;
  padding: 16px 0px 16px;
}

.salesOverallInnerAccordiondetailsTitle {
  width : 179px;
}

.salesAccordiondetailsTotal.MuiAccordionDetails-root {
  display: flex !important;
  flex-direction: column !important;
  padding: 8px 0px 16px !important;
}

.salesAccordiondetailsTotal {
  display: flex !important;
  flex-direction: column;
  padding: 8px 0px 16px;
  margin-left: 5px;
}

.adjustmentAccordiondetailsContent.MuiAccordionDetails-root {
  display: flex;
  padding: 16px 11px 16px;
}

.adjustmentAccordiondetailsContent::-webkit-scrollbar {
  display: none;
}


.salesadjustmentcontent {
    display: flex;
    flex-direction: row;
    overflow: auto;
    width: 850px;
    /* margin-top: -30px; */
    margin-top: -10px;
    /* border: 1px solid green; */
}


.salesadjustmentcontent::-webkit-scrollbar {
  display: none;
}

.typeicons {
  display: flex;
}

.typeiconstyle {
  height: 14px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  color: #434244;
  margin: 1px 6px 1px 1px;
}


.adjustmentaccordions>div.MuiAccordion-root {
  display: flex;
}

.adjustmentexpand>div.MuiAccordionSummary-expandIcon {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.adjustmentexpand>div.MuiAccordionSummary-expandIcon.Mui-expanded {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.adjustmentaccordions {
  display: inline-flex;
}

.adjustmentaccordions::-webkit-scrollbar {
  display: none;
}

.adjustmentexpand>div.MuiAccordionSummary-content {
  order: 0;
  min-width: 60px;
  margin: 6px 11px !important;
}

.applyMonthMargin>div:nth-child(1) {
  min-width: 100px;
}

.verticalAdjustmentAccordion {
  width: 8%;
  min-width: 8%;
  margin-left: 18px;
}

.adjustmentsview {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  padding: 0px 0px 0px 0px;
}

.adjustmentAlignment {
  /* display: flex; */
  font-size: 13px;
  flex-direction: column;
}

.accordionContent {
  display: flex;
}

.alignTitleContent {
  display: flex;
}

.alignTitleInSales {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  margin-left: 10px;
  text-align: left;
}

.alignTotal {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  margin-left: 10px;
  text-align: left;
  background-color: #f3f2f3;
  border-radius: 3px;
}

.allBUAccordionTotal {
  display: flex !important;
  flex-direction: column !important;
  padding-right: 20px;
  font-size: 13px;
  width: 80px;
}

.alignContent {
  display: flex;
  text-align: left;
  flex-direction: column;
  margin-top: -20px;
  margin-left: 1.5%;
}

.alignColumnContentForSalesAdjustment {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-left: 4%;
}

.totalCurrentValue {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  width: 80px;
  text-align: right;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 10px;
  color: #808285;
  
}

.salesOverallDivider {
    width: 100% !important;
    height: 1px;
    background: red;
    margin-left: 43px !important;
    margin-top: 0px !important;
    margin-bottom: 7px !important;
}

.allBuChildDivider {
    width: 1121px !important;
    height: 1px;
    background: red;
    margin-left: 43px !important;
    margin-top: 7px !important;
    margin-bottom: 7px !important;
}

.allBuOverallDivider>div.MuiDivider-root {
  background-color: #f3f2f3 !important;
}

.subDivider {
  width: 1000px !important;
  height: 1px;
  margin-top: 20px !important;
  margin-left: -100px !important;
}

.subDivider>div.MuiDivider-root {
  background-color: #f3f2f3 !important;
}

.salesAdjustmentHeading {
    min-width: 90px;
    width: 175px;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    color: #434244;
    display: inline-flex;
    margin-top: 3px;
}

.salesAdjustmentInnerheading{
    min-width: 90px;
    width: 152px;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    color: #434244;
    display: inline-flex;
    margin-top: 3px;
}



.salesAndFinanceAllBuMinWidth{
  justify-content: flex-end;
  display: flex;
  /* padding-right: 0px; */
  width: 99px;
  min-width: 99px;
  /* padding-left: 19px; */
  border-radius: 3px;
  padding-right: 3px;
}

.salesBuContent {
  font-size: 13px;
  margin-top: 8px;
  margin-left: 20px;
  color:#808285;
  font-style: italic;
  flex-direction: row;
  width: 150px;
}

.salesBuTitle {
  width: 200px;
  min-width: 200px;
  font-size: 13px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}


.salesActivityediticon{
  /* margin: -14px 6px 34px 6px; */
  padding: 9px 0 14px;
  /* box-shadow: -5px 10px 20px 0 rgb(0 0 0 / 10%); */
  background-color: #fff;
}

.salesActivityediticonDisabled{
  opacity: 0.3;
}

.salesAdjustmentsSaveBtn{
  border: solid 1px #fc0;
  border-radius: 5px;
  height: 29px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 12px;
  margin:0px 0px 0px 5px ;
  background-color: #ffcc00;
  box-shadow: 0 0 50px 0 rgb(0 0 0 / 10%);
  /* width: 47px; */
  /* if width is applied to save button it will scroll */
}

.editiconmargin{
  /* border: 1px solid blue; */
}

@media only screen and (width: 1600px) {

  .applySalesAndFinanceMonthMargin{
    width: 1150px;
  }
  .readOnlynoMarginOverall{
    width: 1150px;
  }
  .salesadjustmentcontent{
    width: 1150px;
  }
}

@media only screen and (width: 1920px) {
  .applySalesAndFinanceMonthMargin{
    width: 1480px;
  }
  .readOnlynoMarginOverall{
    width: 1480px;
  }
  .salesadjustmentcontent{
    width: 1480px;
  }
}

@media only screen and (width: 2400px) {
  .applySalesAndFinanceMonthMargin{
    width: 1950px;
  }
  .readOnlynoMarginOverall{
    width: 1950px;
  }
  .salesadjustmentcontent{
    width: 1950px;
  }
}


.applygreybackgroundforSF {
    background-color: #f3f2f3;
    /* width: 70px;
    margin-left: 6px; */
    border-radius: 3px;
    padding-right: 3px;
    display: flex;
    flex-direction: column;
    width: 99px;
    min-width: 99px;
    /* padding-left: 19px; */
}

.cellComponentForSF {
    display: flex;
    flex-direction: column;
    width: 99px;
    min-width: 99px;
    padding-right: 3px;
}

.rowStyle {
    display: flex;
    /* height: 110px; */
}
.filteredRecord{
    background-color:#ffffe0;
}
.selectedForFilter{
    background-color:#d0f3fc;
    font-weight: bold;
}
.adjustmentinputfield>div>div>div>fieldset.MuiOutlinedInput-notchedOutline{
    border: none !important;
}
.adjustmentinputfield>div>div>div>input{
  color: #808285;
  border-radius: 3px !important;
  border: solid 1.5px #cccdd0 !important;
  background-color: #fff !important;
  text-align: right !important;
  padding: 6px 0 6px 0 !important;
  margin: 0 -10px 0 10px !important;
  /* overriding default padding */
}
.adjustmentinputdisablefield>div>div>div>input{
    color: #800080;
    border: none !important;
  /* border-radius: 3px !important;
  border: solid 1.5px #cccdd0 !important; */
  /* background-color:#f3f2f3 !important; */
  text-align: right !important;
  padding: 6px 0 6px 0 !important;
  margin: 0 -12px 0 11px;
  }
.editablefield{
    margin: 0px 0px -3px 16px;
    font-size: 13px;
    color: #808285;
}
.adjustmentinputfield>div>div>div{
    min-width: 10px !important;
}
.errorMsgStyle>div>div>div>input{
  color: #808285;
  border-radius: 3px !important;
  border: solid 1.5px #E90133 !important;
  background-color: #fff !important;
  text-align: right !important;
  padding: 6px 0 6px 0 !important;
  /* overriding default padding */
}
.errorMsgStyle>div>div>div::after{    
    content: "";
    position: absolute;
    top:1px;
    right: 1px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 0;
    border-color: transparent #E90133 transparent transparent;
}
.errorMsgStyle>div>div>div::before{    
    content: "";
    position: absolute;
    top:1px;
    right: 1px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 0;
    border-color: transparent #E90133 transparent transparent;
}
.errorMsgStyle>div>div>div>fieldset.MuiOutlinedInput-notchedOutline{
    border: none !important;
}
.errorMsgStyle>div>div>div{
    min-width: 88px !important;
}
.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input {
    border: 1px solid lightgray;
    border-radius: 3px;
    padding-right: 21px !important;
    width: 98px !important;
    background: white;
    min-width: 90px;
    height: 12px;
    margin-top: 1px !important;
    margin-right: 2px !important;
    color: black !important;
    font-size: 13px !important;
}

.MuiAutocomplete-option {
    font-size: 13px !important;
    color: black !important;
    padding: 5px !important
}

.MuiAutocomplete-popupIndicatorOpen {
    padding-left: 8px !important;
    padding-right: 8px !important;
    padding-top: 3px !important;
}

.MuiAutocomplete-popupIndicator {
    padding: 2px;
    margin-right: -2px;
    position: relative;
    display: flex;
}

/* .MuiAutocomplete-popupIndicatorOpen {
    padding-right: 3px !important;
} */
#grid-theme-wrapper>div>div{
    height:100% !important;
}

.customIcons {
    justify-content: space-around;
    display: flex;
    padding-top: 10px;
}

.ag-theme-alpine .ag-ltr .ag-cell {
    border-radius: 0px !important;
}

.ag-theme-alpine .ag-cell-inline-editing {
    box-shadow: 0 0 0 0 transparent;
}

.ag-input-field-input {
    border: solid 1px;
    padding: 5px;
    border-radius: 0px;
    box-shadow: 0px 0px 0px 0px !important;
    width: 93%;
    height: 32px !important;
    min-height: 32px;
    margin: 5px;
    background-color: #f6f5f6;
}

.ag-theme-alpine .ag-ltr input[class^='ag-']:not([type]), .ag-theme-alpine .ag-ltr input[class^='ag-'][type='text'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='number'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='tel'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='date'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='datetime-local'], .ag-theme-alpine .ag-ltr textarea[class^='ag-'] {
    margin: 3px;
    margin-left: 5px !important;
    text-align: right;
    border: solid 1px lightgray !important;
}

.ag-root-wrapper {
    border: 0px !important;
}

.ag-header {
    height: 40px !important;
    min-height: 40px !important;
    border-bottom: solid 2px !important;
    border-bottom-color: lightgray !important;
}

.ag-header-viewport {
    background-color: white;
}

.ag-pinned-right-header {
    background-color: white;
}

.ag-header-cell {
    padding-bottom: 10px !important;
}

.ag-header-cell-resize {
    /* height: 0% !important */
}

.ag-right-aligned-header .ag-header-label-icon { 
    margin-left: 6px !important;
    margin-right: 0px !important;
}

 .ag-right-aligned-header .ag-header-cell-label {
    flex-direction: row !important;
} 
.ag-right-aligned-header .ag-header-cell-text{
    flex-grow: 1;
    text-align: right;
}

.ag-header-row:after {
    right: -1px !important;
}

.ag-floating-bottom-viewport {
    background: #ffed9b !important;
}

.ag-floating-bottom {
    background: #ffed9b !important;
}

.ag-row-pinned {
    background: #fff3bc !important;
}

.ag-theme-alpine .ag-cell-inline-editing {
    background: #f6f5f6 !important;
    box-shadow: 0 1px 4px 1px transparent !important;
    border-color: transparent !important;
    border: 0px !important;
    border-radius: 0px !important;
    padding-right: 2px !important;
}

.ag-row-pinned>.ag-cell-inline-editing {
    background: #f6f5f6 !important;
}

.ag-theme-alpine .ag-row {
    border: 1px solid;
    border-color: lightgray;
    font-size: 13px;
}

.ag-icon {
    color: grey;
}

#grid-theme-wrapper.app-theme .ag-theme-alpine .ag-row-selected {
    background-color: #f6f5f6;
}

/* div.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-focus {
    background-color: whitesmoke !important;
} */

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
    color: whitesmoke !important;
    background-color: black;
    width: 13px !important;
    height: 13px !important;
    line-height: 13px !important;
    border: 1px solid darkgray;
}

.ag-theme-alpine .ag-checkbox-input-wrapper {
    background-color: white;
    font-size: 14px;
    width: 14px;
    height: 14px;
    line-height: 14px;
}

.ag-theme-alpine .ag-row-odd {
    background-color: white;
}

.ag-theme-alpine .ag-row-hover {
    background: #f6f5f6;
}

.copyRow {
    background-color: #e2f4ff !important;
}

/* small table row icon hover effect */

/* .ag-pinned-right-cols-container img {
    opacity: .7;
}

.ag-pinned-right-cols-container img:hover {
    opacity: 1;
    cursor: pointer;
} */

/*  move text in date picker up a little */

.MuiFormControl-marginNormal .MuiInputBase-input {
    padding-top: 5px !important;
}

/* add table input focus state */

.ag-theme-alpine input[class^=ag-]:not([type]):focus, .ag-theme-alpine input[class^=ag-][type=date]:focus, .ag-theme-alpine input[class^=ag-][type=datetime-local]:focus, .ag-theme-alpine input[class^=ag-][type=number]:focus, .ag-theme-alpine input[class^=ag-][type=tel]:focus, .ag-theme-alpine input[class^=ag-][type=text]:focus, .ag-theme-alpine textarea[class^=ag-]:focus, .MuiAutocomplete-inputRoot[class*=MuiInput-root] .MuiAutocomplete-input:focus, .ag-cell-focus .MuiFormControl-marginNormal {
    outline: none;
    border-bottom: 1.5px solid #4c97d0 !important;
    /* -webkit-box-shadow: inset 0 1px 5px 4px #e2f4ff !important; */
    /* box-shadow: inset 0 1px 5px 4px #e2f4ff !important; */
}

.validRow, .errorCell {
    background-color: transparent !important;
}

.validRow::after, .errorCell::after {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 0;
    border-color: transparent #E90133 transparent transparent;
} 

 .validRow::before, .errorCell::before {
    content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    display: block;
    border: 1.5px solid #E90133;
    border-radius: 3px;
} 

.validRow::after {
    border-right-color: #006bbd;
}

.validRow::before {
    border-color: #006bbd;
} 

/* Add New row (highlight medium blue left border) */

.ag-center-cols-clipper .copyRow::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 7px;
    background-color: #006bbd;
}

.ag-center-cols-clipper .errorRow::before{
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 7px;
    background-color: #eb7000;
}

.ag-center-cols-clipper .errorCellBorder::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 7px;
    background-color: #eb7000;
}

.errorRow {
    background-color: #ffe2c9 !important;
}

div.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height ag-cell-first-right-pinned ag-cell-value ag-cell-focus {
    border-left: 1px solid transparent !important;
}

.ag-theme-alpine .ag-ltr .ag-cell.typehotstyle{
    width: 75px !important;
    height: 36px !important;
    border-radius: 5px !important;
    background-color: #fce3b8 !important;
    margin: 2px 1px 2px 1px;
    padding: -1px 0px 0px 0px;
    text-align: center;
    padding-top: -1px;
    line-height: 32px;
}
.ag-theme-alpine .ag-ltr .ag-cell.typecoldstyle{
    width: 75px !important;
    height: 36px !important;
    border-radius: 5px !important;
    margin: 2px 1px 2px 1px;
    padding: -1px 0px 0px 0px;
    text-align: center;
    padding-top: -1px;
    line-height: 32px;
    background-color: #c7e5f8 !important;
}
.ag-theme-alpine .ag-ltr .ag-cell.blankvaluestyle{
    width: 75px !important;
    height: 36px !important;
    border-radius: 5px !important;
    margin: 2px 1px 2px 1px;
    padding: -1px 0px 0px 0px;
    text-align: center;
    padding-top: -1px;
    line-height: 32px;
    background-color: #80828594 !important;
}
.ag-cell-focus {
    border: 1px solid #006bbd !important;
}

.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input {
    /* border: 1px solid transparent; */
    border-radius: 3px;
    padding-right: 15px !important;
    margin-left: 5px !important;
    width: 98px !important;
    background: white;
    min-width: 90px;
    height: 14px;
    margin-top: 0px !important;
    margin-right: 2px !important;
    color: black !important;
    font-size: 13px !important;
}

/*.ag-theme-alpine .ag-ltr input[class^='ag-']:not([type]), .ag-theme-alpine .ag-ltr input[class^='ag-'][type='text'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='number'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='tel'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='date'], .ag-theme-alpine .ag-ltr input[class^='ag-'][type='datetime-local'], .ag-theme-alpine .ag-ltr textarea[class^='ag-'] {
    margin: 5px;
    border: solid 1px lightgray !important;
} */

.ag-theme-alpine input[class^='ag-']:not([type]), .ag-theme-alpine input[class^='ag-'][type='text'], .ag-theme-alpine input[class^='ag-'][type='number'], .ag-theme-alpine input[class^='ag-'][type='tel'], .ag-theme-alpine input[class^='ag-'][type='date'], .ag-theme-alpine input[class^='ag-'][type='datetime-local'], .ag-theme-alpine textarea[class^='ag-'] {
    min-height: 24px;
    border-radius: 3px;
} 

/* div.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-first-right-pinned.ag-cell-value.ag-cell-focus {
    border-left: 10px solid transparent !important;

} */
.routeDimensions {
    width: 100%;
    height: 100%;
}
.toplevelcontainer{
    display: flex;
    flex: 95 1;
    flex-direction: column;
    height: 100vh;
}

.toplevelcontainer-sidebarminimized{
    display: flex;
    flex: 97 1;
    flex-direction: column;
    height: 100vh;
}

.toplevelcontainerwrapper{
    display: flex;
    flex: 1 1;
    flex-direction: row;
    height: 100vh;
}

.sidebarcontainer-sidebarminimized{
    display: flex;
    flex:3 1;
    height: 100%;
    justify-content: flex-start;
    flex-direction: column;
}

.sidebarcontainer{
    display: flex;
    flex:5 1;
    height: 100%;
    justify-content: flex-start;
    flex-direction: column;
}

.row {
    width: 100%;
    height: 100%;
    /* position: absolute; */
    display: flex;
    flex-direction: row;
    background-color: #f3f2f3;
    overflow-y: scroll;
    box-shadow: 10px 0px 5px #e6e7eb inset;
}

footer {
    position: inherit;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #e6e7eb;
    margin: 0 0 0;
    padding: 12px 28px;
    display: flex;
    justify-content: space-between;
}

footer > div {
    height: 20px;
    margin: 0 0 0 0;
    font-family: Ride;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #58595b;
    padding-right:3%;
}

.progressWrapper {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
}

.navigationAlignment {
    display: flex;
    width: 100%;
}

.menutoggle{
    height: 49px;/* This + padding on both top & bottom=64px, The size of topbar  */
    background-color: #f3f2f3;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.menuiconwrapper{
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.menutoggle-bigline{
    width: 22px;
    height: 5px;
    background-color: #58595b;    
    margin: 5px;
    border-radius: 50px;
}

.menutoggle-smallline{
    width: 15px;
    height: 5px;
    background-color: #58595b;    
    margin: 5px;
    border-radius: 50px;
}

.bg-blue-300 {
  background-color: #93c5fd;
}
.bg-purple-300 {
  background-color: #c4b5fd;
}
.bg-pink-300 {
  background-color: #f9a8d4;
}
.bg-transparent {
  background-color: transparent;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.inline-flex {
  display: inline-flex;
}
.flex-1 {
  flex: 1 1;
}
.flex-auto {
  flex: 1 1 auto;
}
.flex-initial {
  flex: 0 1 auto;
}
.flex-none {
  flex: none;
}
.fixed {
  position: fixed;
}
.p-0 {
  padding: 0px;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.pr-0 {
  padding-right: 0px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pl-0 {
  padding-left: 0px;
}
.p-3 {
  padding: 12px;
}
.px-3 {
  padding-left: 12px;
  padding-right: 12px;
}
.py-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.pt-3 {
  padding-top: 12px;
}
.pr-3 {
  padding-right: 12px;
}
.pb-3 {
  padding-bottom: 12px;
}
.pl-3 {
  padding-left: 12px;
}
.ml-15 {
  margin-left: 15px;
}
.-mr-10 {
  margin-right: -25px;
}
.mr-25 {
  margin-right: 10px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-90 {
  margin-right: 95%;
}
.ml-14 {
  margin-left: 9%;
}
.ml-144 {
  margin-left: 110px;
}
.mr-144 {
  margin-right: 80px;
}
.m-0 {
  margin: 0px;
}
.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}
.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.mr-0 {
  margin-right: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.ml-0 {
  margin-left: 0px;
}
.my-6 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.my-9 {
  margin-top: 27px;
  margin-bottom: 27px;
}
.m-3 {
  margin: 12px;
}
.mx-3 {
  margin-left: 12px;
  margin-right: 12px;
}
.my-3 {
  margin-top: 12px;
  margin-bottom: 12px;
}
.mt-1 {
  margin-top: 6px;
}
.mt-3 {
  margin-top: 12px;
}
.mr-3 {
  margin-right: 12px;
}
.mb-3 {
  margin-bottom: 12px;
}
.ml-1 {
  margin-left: 6px;
}
.ml-3 {
  margin-left: 12px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-155 {
    margin-left: 110px;
  }
.mt-20 {
  margin-top: 65px;
}
.w-full {
  width: 100%;
}
.mr-100 {
  margin-right: 100px;
}
.w-screen {
  width: 100vw;
}
.w-min {
  width: -webkit-min-content;
  width: min-content;
}
.w-max {
  width: -webkit-max-content;
  width: max-content;
}
.w-fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.w-1104 {
  width: 1104px;
}
.w-10 {
  width: 12%;
}
.w-85 {
  width: 92%;
}
.w-10 {
  width: 10%;
}
.h-10 {
  height: 10%;
}
.h-85 {
  height: 85%;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}
.h-min {
  height: -webkit-min-content;
  height: min-content;
}
.h-max {
  height: -webkit-max-content;
  height: max-content;
}
.h-fit {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-clip {
  overflow: clip;
}
.overflow-visible {
  overflow: visible;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-x-clip {
  overflow-x: clip;
}
.overflow-y-clip {
  overflow-y: clip;
}
.overflow-x-visible {
  overflow-x: visible;
}
.overflow-y-visible {
  overflow-y: visible;
}
.overflow-x-scroll {
  overflow-x: scroll;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.overscroll-auto {
  overscroll-behavior: auto;
}
.overscroll-y-auto {
  overscroll-behavior-y: auto;
}
.overscroll-x-auto {
  overscroll-behavior-x: auto;
}
.overscroll-contain {
  overscroll-behavior: contain;
}
.overscroll-y-contain {
  overscroll-behavior-y: contain;
}
.overscroll-x-contain {
  overscroll-behavior-x: contain;
}
.overscroll-none {
  overscroll-behavior: none;
}
.overscroll-y-none {
  overscroll-behavior-y: none;
}
.overscroll-x-none {
  overscroll-behavior-x: none;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.text-xs {
  font-size: 12px;
}
.text-sm {
  font-size: 14px;
}

/* tailwind  styles */
.mt-5{
  margin-top:20px ;
}
.ml-6{
  margin-left: 24px;
}
.-mt-3{
  margin-top: -12px;
}

/* custom styles */

.revenueheadertext{
  min-width: 25%;
  height: 38px;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: -1px;
  color: #000000;
 }
 .rffilterStyle {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  width: 74%;
}
.revFunctionHeader {
  background-color: white;
  -webkit-margin-before: -36px !important;
          margin-block-start: -36px !important;
  padding-bottom: 5px;
  color: white;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
}


.rfactionButtons>div:nth-child(1).Mui-focused {
  background-color: none !important;
}

.viewdropdown>div.MuiInputBase-input {
  padding: 0px 2px 7px 3px !important;
}

.viewdropdown>div.MuiSelect-select:focus {
  background-color: none !important;
}

.viewdropdown>svg.MuiSelect-icon {
  padding: 0px 2px 7px 2px !important;
}

.saveButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: row;
  min-width: 36%;
  margin-right: 10px;
  margin-top: 10px;
}

.rfactionButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
    min-width: 36%;
    margin-right: -10%;
    margin-top: 15px;
}

.filterMain {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 24px;
}

.rfactionButtons>Button {
  padding: 14px !important;
  margin: 5px !important;
  text-transform: none !important;
  padding: 10px;
}

.imageIcon {
  margin: 10px;
  width: 24px !important;
  height: 28px;
}

.MuiMenuItem-root {
  font-size: 13px !important;
}

.MuiSelect-selectMenu {
  width: 165px !important;
  border-radius: 3px;
  margin-top: 5px !important;
  padding: 5px !important;
}

.MuiFormControl-root {
  margin-right: 10px !important;
}

.MuiSvgIcon-root {
  padding-top: 3px !important;
  margin-top: 0px !important;
  padding-right: 5px;
}

.MuiSelect-select {
  /* width: 120px !important; */
  border-radius: 3px !important;
  /* border: 1px solid grey !important; */
  /* background: #ffcc00 !important;  */
}

.dropdownfields {
  display: flex;
  flex-direction: column;
  width: 176px;
  margin-top: 5px;
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
}

.MuiTypography-gutterBottom {
  margin-bottom: 0 !important;
}

.MuiMenuItem-root {
  font-family: customFontFamily !important;
  height: 28px;
  border-bottom: 1px solid lightgray;
}

.MuiInputBase-root {
  font-family: customFontFamily !important;
}

.MuiTypography-body1 {
  font-family: customFontFamily !important;
}

.muiDatePicker {
  margin-top: 0px !important;
  border: 1px solid #808285 !important;
  border-radius: 3px !important;
  padding-left: 5px !important;
  width: 165px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #808285 !important;
  border-radius: 3px !important;
  border-width: 1px !important;
}

.viewdropdown {
  width: 90px;
  height: 34px;
  margin: 0 0 0 18px;
  padding: 1px;
  border-radius: 5px;
  background-color: #ffcc00;
}

.rfactionButtons>div.MuiFormControl-root {
  margin-top: 7px !important;
}

.secondaryButton {
  border: solid 1px;
  border-radius: 5px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 12px;
  margin: 6px;
  padding-top: 2px;
  background-color: transparent !important;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.MuiButton-label {
  text-transform: none !important;
}

.searchbar {
  margin-right: 48%;
  margin-top: 8px;
}

.MuiDialog-paperWidthSm {
  max-width: 675px !important;
}

.imageSpacing {
  padding-right: 15px !important;
  min-width: 24px !important;
}

.MuiListItem-root.Mui-selected {
  background-color: transparent !important;
}

.actiobarPopOver>div.MuiPopover-paper {
  border-top-right-radius: 0px !important;
  box-shadow: -5px 10px 20px 0px rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff !important;
  overflow-y: hidden !important;
  margin-top: 0.7px !important;
}

div.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.MuiDivider-root {
  background-color: #f3f2f3;
}
.programsfilterMargin {
  margin:0px 10px 0px 10px;
}
.revFunctiontables{
  background-color: white;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 23px;
}  
/* styles to dropdown */
.dropdownfield{
  /* width: 42%; */
  height: 34px;
  margin: 1px 29px 29.5px 3px;
  padding: 5.3px 15px 5.3px 15.1px;
  border-radius: 4px;
  border: solid 1px #979797 !important;
}

.bottomMarginRemoved{
  margin-bottom: 0px;
}

.revFunctiontables>div>div>div.MuiSelect-select:focus{
  background-color: rgba(0,0,0,0)!important;
}
/* .revFunctiontables>div.MuiFormControl-root {
  margin-right: -8%!important;
} */
.headerDivider {
height: 34px;
border: solid 1px #979797;
}
.rfInputTabs{
display: flex;
margin-left: -1%;
width: 80%;
}
 .primaryButton {
  border: solid 1px;
  border-radius: 5px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 12px;
  margin: 6px;
  padding-top: 2px;
  background-color: #ffcc00;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.revFunctionHeader .rfInputTabs {
  width: 70%;
}
/* .MuiTableContainer-root {
width: auto !important;
} */
.MuiPagination-root .MuiPagination-ul{
/* display: none; */
}



/* cursor pointer for clickable options */
.cursorpoint{
  cursor:pointer;
}

.rfidlink{
  color:#006bbd;
}
.rfheaders{
  flex-wrap: wrap;
}
.display-grid{
  display: grid;
}
.rfleftmargin{
  margin-left: 3%;
}
.typelable{
  margin: 0 20px 6px 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.selecterfid{
  height: 24px;
  margin: 1px 31px 24px 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434244;
}
.rfdescriptionwidth>div:nth-child(2)>div:nth-child(1){
  width: 405px;
  height: 56px;
}
.rfcodestyle{
  margin-left: 17px;
  font-weight: bold;
}
.flexDirectionColumn{
  flex-direction: column;
}
.flexDirectionRow{
  flex-direction: row;
}
@media screen and (max-width: 3840px) {
  #grid-theme-wrapper{
    height: 60vh;
    /* border: 1px solid blue; */
  }  
  .margin #grid-theme-wrapper {
    height: 90vh;
    /* border: 1px solid green; */
  }  
  .planinputsmargin #grid-theme-wrapper {
    height: 83vh;
    /* border: 1px solid blue; */
  } 
  .planassumptionbgcolor #grid-theme-wrapper {
    height: 85vh;
    /* border: 1px solid green; */
  }
  .planinputsmargin .planassumptionbgcolor #grid-theme-wrapper {
    height: 80vh;
    /* border: 1px solid green; */
  }
  .adminpage #grid-theme-wrapper {
    height: 85vh;
    /* border: 1px solid blue; */
  }
  .revenue-adjust #grid-theme-wrapper{
    height: 88vh;
  }
}

@media screen and (max-width: 2560px) {
  #grid-theme-wrapper{
    height: 60vh;
    /* border: 1px solid blue; */
  }  
  .margin #grid-theme-wrapper {
    height: 85vh;
    /* border: 1px solid green; */
  }  
  .planinputsmargin #grid-theme-wrapper {
    height: 73vh;
    /* border: 1px solid blue; */
  } 
  .planassumptionbgcolor #grid-theme-wrapper {
    height: 77vh;
    /* border: 1px solid green; */
  }
  .planinputsmargin .planassumptionbgcolor #grid-theme-wrapper {
    height: 70vh;
    /* border: 1px solid green; */
  }
  .adminpage #grid-theme-wrapper {
    height: 80vh;
    /* border: 1px solid blue; */
  }
  .revenue-adjust #grid-theme-wrapper{
    height: 78vh;
  }
}

@media screen and (max-width: 1920px) {
  #grid-theme-wrapper{
    height: 60vh;
    /* border: 1px solid blue; */
  }  
  .margin #grid-theme-wrapper {
    height: 70vh;
    /* border: 1px solid green; */
  }  
  .planinputsmargin #grid-theme-wrapper {
    height: 60vh;
    /* border: 1px solid blue; */
  } 
  .planassumptionbgcolor #grid-theme-wrapper {
    height: 70vh;
    /* border: 1px solid green; */
  }
  .planinputsmargin .planassumptionbgcolor #grid-theme-wrapper {
    height: 60vh;
    /* border: 1px solid green; */
  }
  .adminpage #grid-theme-wrapper {
    height: 70vh;
    /* border: 1px solid blue; */
  }
  .revenue-adjust #grid-theme-wrapper{
    height: 70vh;
  }
}

@media screen and (max-width: 1600px) {
  #grid-theme-wrapper{
    height: 60vh;
    /* border: 1px solid blue; */
  }  
  .margin #grid-theme-wrapper {
    height: 70vh;
    /* border: 1px solid green; */
  }  
  .planinputsmargin #grid-theme-wrapper {
    height: 55vh;
    /* border: 1px solid blue; */
  } 
  .planassumptionbgcolor #grid-theme-wrapper {
    height: 67vh;
    /* border: 1px solid green; */
  }
  .planinputsmargin .planassumptionbgcolor #grid-theme-wrapper {
    height: 55vh;
    /* border: 1px solid green; */
  }
  .adminpage #grid-theme-wrapper {
    height: 65vh;
    /* border: 1px solid blue; */
  }
  .revenue-adjust #grid-theme-wrapper{
    height: 65vh;
  }
}
  
@media screen and (max-width: 1366px) {
  #grid-theme-wrapper{
    height: 55vh;
    /* border: 1px solid blue; */
  }  
  .margin #grid-theme-wrapper {
    height: 55vh;
    /* border: 1px solid green; */
  }  
  .planinputsmargin #grid-theme-wrapper {
    height: 35vh;
    /* border: 1px solid green; */
  }
  .planassumptionbgcolor #grid-theme-wrapper {
    height: 60vh;
    /* border: 1px solid green; */
  }
  .planinputsmargin .planassumptionbgcolor #grid-theme-wrapper {
    height: 35vh;
    /* border: 1px solid green; */
  }
  .adminpage #grid-theme-wrapper {
    height: 60vh;
    /* border: 1px solid green; */
  }
  .revenue-adjust #grid-theme-wrapper{
    height: 55vh;
    /* border: 1px solid green; */
  }
  /* .revenue-adjust #grid-theme-wrapper>div>div>div{
    height: 450px !important;
  } */
  
}

@media screen and (max-width: 1280px) {
  #grid-theme-wrapper{
    height: 55vh;
    /* border: 1px solid blue; */
  }
  .margin #grid-theme-wrapper {
    height: 55vh;
    /* border: 1px solid green; */
  }  
  .planinputsmargin #grid-theme-wrapper {
    height: 30vh;
    /* border: 1px solid green; */
  }
  .planassumptionbgcolor #grid-theme-wrapper {
    height: 55vh;
    /* border: 1px solid green; */
  }
  .planinputsmargin .planassumptionbgcolor #grid-theme-wrapper {
    height: 30vh;
    /* border: 1px solid green; */
  }
  .adminpage #grid-theme-wrapper {
    height: 55vh;
    /* border: 1px solid blue; */
  }
  .revenue-adjust #grid-theme-wrapper{
    height: 48vh;
  }

}
@media screen and (max-width: 1152px) {
  #grid-theme-wrapper{
    height: 55vh;
    /* border: 1px solid blue; */
  }  
  .planinputsmargin #grid-theme-wrapper {
    height: 33vh;
    /* border: 1px solid blue; */
  }
  .adminpage #grid-theme-wrapper {
    height: 60vh;
    /* border: 1px solid blue; */
  }
  .revenue-adjust #grid-theme-wrapper{
    height: 33vh;
  }
}
@media screen and (max-width: 1024px) {
  #grid-theme-wrapper{
    height: 55vh;
    /* border: 1px solid blue; */
  }  
  .planinputsmargin #grid-theme-wrapper {
    height: 33vh;
    /* border: 1px solid blue; */
  }
  .adminpage #grid-theme-wrapper {
    height: 50vh;
    /* border: 1px solid blue; */
  }
  .revenue-adjust #grid-theme-wrapper{
    height: 33vh;
  }
}

.revenue-adjust #grid-theme-wrapper>div>div {
  height: 90% !important;
  /* border: 1px solid red; */
}
.revenue-adjust #grid-theme-wrapper>div>div>div {
    height: 100% !important;
}
