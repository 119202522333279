.textfieldStyles>div>div>div.MuiInputBase-root {
    min-width: 112px !important;
    background: #f6f5f6 !important;
    min-height: 34px !important;
    width:98%;
}

.textfieldStyles>div>div>div.MuiInputBase-root>.MuiInput-input {
    border: solid 1px lightgray !important;
    background-color: #ffffff !important;
    border-radius: 5px !important;
}

.textfieldStyles>div>div>div.MuiInputBase-root>.MuiInput-input:focus {
    outline: none;
    border-bottom: 1.5px solid #4c97d0 !important;
    /* -webkit-box-shadow: inset 0 1px 5px 4px #e2f4ff !important; */
    /* box-shadow: inset 0 1px 5px 4px #e2f4ff !important; */
    text-transform: uppercase !important;
    width: 100% !important;
    padding-right: 0px !important;
}
.textfieldStyles{
    margin: 0 2% 0 2% !important;
}