.applygreybackground {
    background-color: #f3f2f3;
    width: 70px;
    margin-left: 6px;
    border-radius: 3px;
    padding-right: 26px;
    display: flex;
    flex-direction: column;
}

.cellComponent {
    display: flex;
    flex-direction: column;
    min-width: 99px;
}

.rowStyle {
    display: flex;
    /* height: 110px; */
}
.filteredRecord{
    background-color:#ffffe0;
}
.selectedForFilter{
    background-color:#d0f3fc;
    font-weight: bold;
}