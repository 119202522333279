.rangeinput{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.andtext{
    height: 19.5px;
    margin: 8px 11px 8.5px 6px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #434244;
}
.rangemargin{
    margin-left: 8px;
}
.rangeinput>div>div.MuiFormControl-root.MuiTextField-root{
    width: 165px !important;
    margin-top: 0px !important;
}
.rangeinput>span.displayflx{
    display: flex;
    margin-top: -5px;
}