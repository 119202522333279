.adjustmentsorttext {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  text-transform: capitalize !important;
}

.adjustmentvalues {
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #808285;
  margin-top: 10px;
}

.collapsedAdjustmentValues {
  min-width: 99px;
  text-align: right;
  font-size: 13px;
  color: #808285;
}

.adjustmentValuesWithMargin {
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #808285;
}

.adjustmentvaluesgreen {
  margin-top: 10px;
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #0d8746;
  margin-top: 10px;
}

.adjustmentvaluesred {
  margin-top: 10px;
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #e90133;
}

.adjustmentvaluesblue {
  min-width: 90px;
  text-align: right;
  font-size: 13px;
  color: #006bbd;
  margin-top: 8px;
}

.adjustmenttotalFinal {
  background-color: #f3f2f3;
  border-radius: 3px;
  min-width: 80px;
  font-size: 13px;
  padding-right: 10px;
  padding-left: 5px;
  margin-left: 7px;
  z-index: 1;
  color: #434244;
}

.adjustmenttotal {
  background-color: #f3f2f3;
  border-radius: 3px;
  min-width: 80px;
  font-size: 13px;
  padding-right: 10px;
  padding-left: 5px;
  margin-left: 7px;
  z-index: 1;
  color: #808285;
}

.collapseAdjustmentTotal {
  background-color: #f3f2f3;
  width: 89px;
  font-size: 13px;
  color: #808285;
  border-radius: 3px;
  padding-right: 10px;
  margin-left: 2px;
}

.marginOverall {
  margin-left: 70px;
  margin-right: -111px !important;
  display: flex;
  overflow: auto;
  width:898px;
  height: 20px;
}
.acivitymarginOverall{
  margin-left: 98px;
  margin-right: -111px !important;
  display: flex;
  overflow: auto;
  width:898px;
  height: 20px;
}
.readOnlymarginOverall{
  margin-left: 31px;
  margin-right: -172px !important;
  display: flex;
  overflow: auto;
  width: 1000px;
  height: 20px;
}

.noMarginOverall {
  margin-left: 70px;
  margin-right: -111px !important;
  display: flex;
  overflow: auto;
  width:898px;
  height: 20px;
}
.readOnlynoMarginOverall{
  margin-right: -111px !important;
  display: flex;
  overflow: auto;
  width: 1000px;
  height: 20px;
  margin-left: 3px;
}

.rowFlex {
  display: flex;
}
.allBurowFlex{
  display: flex;
}

.noMarginOverall::-webkit-scrollbar {
  display: none;
}
.readOnlynoMarginOverall::-webkit-scrollbar {
  display: none;
}

.marginOverall::-webkit-scrollbar {
  display: none;
}
.acivitymarginOverall::-webkit-scrollbar {
  display: none;
}

.readOnlymarginOverall::-webkit-scrollbar {
  display: none;
}

.marginNegative {
  margin-left: -30px;
}

.greencolor {
  color: #0d8746;
}

.redcolor {
  color: #e90133;
}

.adjustmentvaluesTotal {
  display: flex;
}

.boldStyle {
  font-weight: bold;
}

.adjustment_value_total {
  min-width: 90px;
  text-align: right;
  font-size: 13px;
}

.applymargintotal {
  margin-right: 10px;
}

.adjustmentValuesMargin {
  margin-top: -36px;
  display: inline-flex;
  width: 900px;
  text-align: right;
  position: absolute;
  height: 115px;
}

.adjustmentValuesOverallMargin {
  margin-top: -30px;
  display: inline-flex;
  width: 900px;
  text-align: right;
  position: absolute;
  height: 98px;
}

.collapsedAdjustmentValuesOverallMargin {
  display: inline-flex;
  width: 900px;
  text-align: right;
  position: absolute;
  height: 98px;
  margin-top: 2px;
  margin-left: 41px;
}

.adjustmentBelowMargin {
  display: inline-flex;
  width: 900px;
  text-align: right;
}

.collapsedAdjustmentBelowMargin {
  display: inline-flex;
  width: 900px;
  text-align: right;
}

.adjustmentBelowOverallMargin {
  display: inline-flex;
  width: 900px;
  text-align: right;
  height: 28px;
}

.adjustmentsorttextleftMargin {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  margin-left: 10px;
  text-transform: capitalize !important;
}

.adjustmentsort {
  border-bottom: solid 2px #f3f2f3;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 100;
}

.coreBUadjustmentsort{
  border-bottom: solid 2px #f3f2f3;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 100;
}

.alignAdjustments {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding-right: 10px;
  width: 1174px;
}
.alignAllBuAdjustments{
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding-right: 10px;
  width: 1183px;
}

.adjustmentApplyMargin {
  display: flex;
  flex-direction: row;
}

.adjustmentMinWidth {
  justify-content: flex-end;
  display: flex;
  width: 84px;
  margin-left: 12px;
  border-radius: 3px;
}
.adjustmentAllBuMinWidth{
  justify-content: flex-end;
  display: flex;
  padding-right: 18px;
  width: 84px;
  margin-left: 19px;
  border-radius: 3px;
  padding-right: 8px;
}

.applyMonthMargin {
  display: inline-flex;
  width: 898px;
  overflow: scroll;
  margin-right: 5%;
}

.applyMonthMargin::-webkit-scrollbar {
  height: 10px;
  width: 0px;
}

.applyMonthMargin::-webkit-scrollbar-thumb {
  background: #808285;
  border-radius: 5px;
}

.applyAllBuMonthMargin {
  display: inline-flex;
  width: 85%;
  overflow: scroll;
}

.applyAllBuMonthMargin::-webkit-scrollbar {
  height: 10px;
  width: 0px;
}

.applyAllBuMonthMargin::-webkit-scrollbar-thumb {
  background: #808285;
  border-radius: 5px;
}

.adjustmentValuesMargin::-webkit-scrollbar {
  display: none;
}

.adjustmentValuesOverallMargin::-webkit-scrollbar {
  display: none;
}

.collapsedAdjustmentValuesOverallMargin::-webkit-scrollbar {
  display: none;
}

.adjustmentBelowMargin::-webkit-scrollbar {
  display: none;
}

.adjustmentBelowOverallMargin::-webkit-scrollbar {
  display: none;
}

.labelAlignment {
  display: flex;
  width: 14%;
  margin-right: 33px;
}

.allBuLabelAlignment {
  display: flex;
  width: 14%;
  margin-left: 17px;
}

.monthYearWidth {
  display: flex;
  min-width: 99px;
  justify-content: flex-end;
}

.headerExpandIcon {
  width: 20px;
}

.adjustmentdataalignment {
  width: 1065px;
  margin-top: 15px;
  margin-bottom: 30px;
}
.applyFreezMonthColor{
  color:#800080 !important;
}