.plandetails{
    display: flex;
    flex-wrap: wrap;
    margin-left: 28px;
}
.plandetailsmargin{
  margin-left: -28px;
}
.detailslable{
  margin: 10px 0px 0px 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.planidwidth{
  display: grid;
}
.descriptionwidth{
  display: grid;
  width: 50%;
}
.zonewidth{
  display: grid;
  width: 21%;
  word-break: break-word;
}
.poolwidth{
  display: grid;
  width: 21%;
  margin-left: 9px;
  word-break: break-word;
}
.savewidth{
  padding-right: 29px;
  font-size: 15px;
  color: #808285;
}
.styleflex{
  display: flex;
  justify-content: space-between;
}
.selectedid{
  height: 24px;
  margin: 1px 31px 24px 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434244;
}
.descriptionwidth>div>div>div.MuiInputBase-root{
    min-height: 54px !important;
    height: 65px !important;

}
.descriptionwidth>div>div>div.makeStyles-root-14 .MuiInputBase-root{
  min-height: 67px !important;
}
.descriptionwidth>div>div>div>fieldset.MuiOutlinedInput-notchedOutline{
    border: none !important;
}
.descriptionwidth>div>div>div.MuiInputBase-root{
  background: none !important;
}
.descriptionwidth>div>div>div>textarea.MuiInputBase-inputMultiline{
  height: 43px !important;
  font-size: 16px !important;
  font-family: customFontFamily !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #434244 !important;
  padding: 10px 20px 10px 20px !important;
  border-radius: 4px !important;
  border: solid 1.4px #808285 !important ;
  background-color: #ffffff !important;
  padding-left: 8px !important;
}
.descriptionwidth>div>div>div>textarea.MuiInputBase-inputMultiline:focus{
  border: solid 2px #808285 !important ;
}
.descriptionwidth>div:nth-child(2)>div.MuiTextField-root{
  margin-top: 10px;
  margin-left: -18px;
  width: 103%;
}
.descriptionwidth>div:nth-child(2).makeStyles-root-15 {
  margin-top: 1px;
  margin-left: 1px;
  margin-right: 1%;
}
.zonerevenuedropdown>div.MuiSelect-select.MuiSelect-select{
  width: 270px !important;
  height: 54px !important;
  border-radius: 4px !important;
  border: solid 1.4px #808285 !important;
  background-color: #ffffff !important;
  width: 100% !important;
}
.zonewidth>div.MuiFormControl-root{
    margin-top: 3px;
}

@media (min-width: 600px){
  .revenueoptions{
    min-height: 34px !important;
    display: block !important;
}
}
.revenuename{
  margin: 0 15px 3px 0;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #434244;
}
.revenuedesc{
  width: 100%;
  height: 20px;
  margin: 3px 15px 0 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #808285;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.zonename{
  width: 203px;
  height: 18px;
  margin: -9px 6px 5px 0;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #434244;
}
.zonedesc{
  width: 203px;
  height: 20px;
  margin: 3px 15px 0 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #808285;

}

.rfstyles{
  border: 1px solid grey;
  padding: 16px 15px 16px 15px;
  border-radius: 4px;
  display: block;
  width: 95%;
  height: 29px;
  margin: 0px 0px 5px 2px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #434244;
  white-space: nowrap;
  background-color: #e6e5e6;
}
.flexContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
}
.descriptionNewStyle {
  width: 100%;
}

.descriptionNewStyle>textarea.MuiInputBase-input {  
  font-size: 16px !important;
}