.toplevelcontainer{
    display: flex;
    flex: 95;
    flex-direction: column;
    height: 100vh;
}

.toplevelcontainer-sidebarminimized{
    display: flex;
    flex: 97;
    flex-direction: column;
    height: 100vh;
}

.toplevelcontainerwrapper{
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 100vh;
}

.sidebarcontainer-sidebarminimized{
    display: flex;
    flex:3;
    height: 100%;
    justify-content: flex-start;
    flex-direction: column;
}

.sidebarcontainer{
    display: flex;
    flex:5;
    height: 100%;
    justify-content: flex-start;
    flex-direction: column;
}

.row {
    width: 100%;
    height: 100%;
    /* position: absolute; */
    display: flex;
    flex-direction: row;
    background-color: #f3f2f3;
    overflow-y: scroll;
    box-shadow: 10px 0px 5px #e6e7eb inset;
}

footer {
    position: inherit;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #e6e7eb;
    margin: 0 0 0;
    padding: 12px 28px;
    display: flex;
    justify-content: space-between;
}

footer > div {
    height: 20px;
    margin: 0 0 0 0;
    font-family: Ride;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #58595b;
    padding-right:3%;
}

.progressWrapper {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
}

.navigationAlignment {
    display: flex;
    width: 100%;
}

.menutoggle{
    height: 49px;/* This + padding on both top & bottom=64px, The size of topbar  */
    background-color: #f3f2f3;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.menuiconwrapper{
    display: block;
    width: fit-content;
}
.menutoggle-bigline{
    width: 22px;
    height: 5px;
    background-color: #58595b;    
    margin: 5px;
    border-radius: 50px;
}

.menutoggle-smallline{
    width: 15px;
    height: 5px;
    background-color: #58595b;    
    margin: 5px;
    border-radius: 50px;
}
