.upgradepathsort{
    border-bottom: solid 2px #f3f2f3;
    background-color: #ffffff;
    padding: 0px 10px 4px 32px;
}

.upgradepathsortFixed {
    width: 1104px !important
}
.pathgroupsort {
    min-width: 164px;
    display: inline-block;
}
.costmargin {
    min-width: 134px;
    display: inline-block;
    margin: 3px -42px 4px 130px;
}
.ultimatemargin {
    min-width: 139px !important;
    display: inline-block;
    margin: 3px -4px 4px 7px;
}
.pathsorticon {
    width: 9px;
    height: 13px !important;
    margin: 3px 0 -3px 10px;
    object-fit: contain;
}
.pathattributemargin {
    min-width: 262px;
    display: inline-block;
    margin: 3px 82px 4px 83px;
}
.pathsorttext {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: normal;
    color: #000000;
}
.pathattheader {
    margin-right: -28px;
}