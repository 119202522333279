.dateEditorStyle {
    margin-left: 1px;
    margin-right: 1px;
    width: 200;
}

.MuiInput-underline
{
  border:none !important;
  border-bottom: 0px solid !important;
}

.MuiIconButton-root {
    padding-top: 2px !important;
    padding-bottom: 5px !important;
}

.MuiInputBase-input {
    padding: 8px 22px 8px 3px !important;
    font-size: 13px !important;
    margin: 0 0 0 -3px !important;
}

.MuiInputBase-root { 
    margin-top: 0px !important;
    font-size: 13px !important;
}

.MuiSvgIcon-root {
    width: 0.8em !important;
    margin-top: -4px;
}

.MuiInput-underline:before {
    border-bottom: 0px !important;
    left: -1px !important;
}

.MuiInput-underline:after {
    border-bottom: 0px !important;
}

.MuiInputAdornment-positionEnd {
    margin-left: -32px !important;
    margin-right: -29px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.inputdatepicker>div>div>button>span:nth-child(1).MuiIconButton-label{
    width:1px !important
}
