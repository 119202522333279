 .dropdownfields {
      display: flex;
      flex-direction: column;
      width: 176px;
      margin-top: 5px;
} 

.MuiSelect-select.MuiSelect-select {
    padding-left: 8px !important;
}

.dropdownStyle {
    /* border: 1px solid #808285 !important; */
    border-radius: 3px;
    height: 30px;
    padding-bottom: 5px;
     width: 167px;
           min-width: 120px;
}
.customdropdown {
    display: flex;
    flex-direction: column;
    width: 176px;
    margin-left: 10px;
  } 
.customdropdown>div>div>div>div.dropdown-heading-value{
  font-size: 12px;
}
.customdropdown>div>div>div.dropdown-content{
  font-size: 13px !important;
  color: gray;
  font-family: customFontFamily !important;
}
.customdropdown>p{
  width: 254px;
  height: 18px;
  margin: 0 26px 5px 0;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808285;
}
.customdropdown>div>div.dropdown-container{
  border-color:#808285 !important;
}