
.usDivisionDropdown {
    width: 135px;
    border: 1.5px solid #000000 !important;
    border-radius: 3px;
    height: 34px;
    padding-bottom: 5px;

}

.usDivisionDropdown-inverted {
    width: 135px;
    border: 1.5px solid white !important;
    border-radius: 3px;
    height: 34px;
    padding-bottom: 5px;
}

.profileDropdown {
    height: 34px;
    margin-bottom: 5px;
    min-width: 120px;
    width:120px;


}
.usDivisionDropdown>div.MuiInputBase-input{
    width: 93.7px !important;
    height: 20px;
    font-size: 14px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000000;
}
.usDivisionDropdown-inverted>div.MuiInputBase-input{
    width: 93.7px !important;
    height: 20px;
    font-size: 14px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
}
.usDivisionDropdown-inverted>svg{
    height: 22px;
    font-weight: 600 !important;
    font-size: 31px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
}
.usDivisionDropdown>svg{
    height: 22px;
    font-weight: 600 !important;
    font-size: 31px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000000;
}
.usericonstyle{
  width: 23px;
  height: 28px;
  object-fit: contain;
  margin-bottom: 10px;
}
.notificationstyle{
width: 27px;
height: 28px;
margin: 4px -7px 4px -15px;
padding: 0 4px 8px 19px;
object-fit: contain;
}
.usermenudropdown>div>div.MuiInput-input{
width: 154.4px !important;
height: 20px;
margin: 10px -39.4px 4px 4px;
font-size: 14px !important;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #000000;
}
.usermenudropdown-inverted>div>div.MuiInput-input{
    width: 154.4px !important;
    height: 20px;
    margin: 10px -39.4px 4px 4px;
    font-size: 14px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}
.usermenudropdown{
 margin-top: -1px;
}
.notificationmessage{
    position: absolute;
    z-index: 99;
    bottom: 0px;
    left: 30%;
    width: 40%;
    display: flex;
    flex-direction: column-reverse;
}
.notificationmessage>.MuiAlert-root{
    margin-bottom: 1.5%;
}
.newarchitecturebutton{
    color: #fff;
    cursor:pointer;
    height:34px;
    margin-left: 12px;
    border-radius: 4px;
    background-color: #434244;
    border: none;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.72;
    letter-spacing: normal;
    font-family: customFontFamily;

}

.toplevelcontainerwrapper:has([intransactionsmode="true"]) :is(.sidebarcontainer-sidebarminimized,.TopBarHeader,.planflexitems,.filterStyle,.chipsSection,.MuiTabs-root,.revFunctiontables){
    pointer-events: none;
    opacity: 0.6; 
}