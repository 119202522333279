.adminpage{
    margin-left: 5px;
    /* margin-top: 20px; */
    /* margin-right: 40px; */
    height: 100%;
    /* border: 1px solid red; */
}
.headertext{
font-size: 30px;
font-weight: 300;
font-stretch: normal;
font-style: normal;
line-height: 1.06;
letter-spacing: -1px;
color: #000000;
}

.adminpage #grid-theme-wrapper>div>div {
    height: 90% !important;
    /* border: 1px solid red; */
}
.adminpage #grid-theme-wrapper>div>div>div {
    height: 100% !important;
}