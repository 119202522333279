.copyPlanDialogBox>div.MuiDialog-container {
    height: 95% !important;
    width: 95% !important;
}

.copyPlanDialogBox>div>div.MuiDialog-paperScrollPaper {
    max-height: none;
    height: 95%;
    width: 600px;
    padding: 12px 6px 12px 6px;
    border-radius: 5px;
    background-color: #ffffff;
}
.copyPlandialogtitle{
    border-bottom:2px ridge;
    padding: 10px 24px !important;
}
.copyPlandialogtitle>h2.MuiTypography-h6 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}

.copyPlandialogtitle>h2.MuiTypography-h6>span {
    font-size: 18px;
    font-family: customFontFamily !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.73;
    letter-spacing: normal;
    color: #434244;
}

.copyPlandialogtitle>h2.MuiTypography-h6>span:nth-child(2) {
    font-weight: normal !important;
    color: red;
}

.copyPlanCloseIcon {
    width: 16px;
    height: 16px;
}

.copyPlanMainTabsFlexContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: space-around;
    height: 190px;
} 

.copyPlanAssumptionTabsFlexContainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: space-around;
    height: 355px;
    width: 100%;
    margin-top: 2%;
}

.copyPlanAssumptionTabTitle{
    position:relative;
    inset:0 0 1% -23%;
    font-weight: bolder;
    font-size: 15px;
}

.copyPlanDialogContent{
    background-color: lightyellow;
}

.copytoMainactionbuttons>button.MuiButton-root{
    text-transform: capitalize !important;
}
.copytoMainactionbuttons>button:nth-child(1){
  padding: 14px !important;
  margin: 5px !important;
  border-radius: 4px;
  border: solid 1.5px #808285;
  background-color: #ffffff;
}
.copytoMainactionbuttons>button:nth-child(2){
    padding: 14px !important;
    margin: 5px !important;
    border: none;
    border-radius: 4px;
    background-color: #ffcc00;
    line-height: 0.75;
    font-family: customFontFamily;
    font-size: 0.875rem;
  }
.copytoMainactionbuttons>button:nth-child(2):not(:disabled){
    cursor: pointer;
    font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #434244;
  font-weight: 400;
}

.copytoMainactionbuttons>button>span.MuiButton-label{
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: center;
  color: #434244;
} 
.copyPlanDialogContent input[type='checkbox']{
    accent-color: rgb(255, 204, 0);   
}
.copyPlanDialogContent_normal{
    background: #ffcc00;
    color: #000000;
    border-radius: 5px;
    width: 57%;
}

.copyPlanDialogContent_normal:hover{
    background: #000000;
    color: #ffffff;
    border-radius: 5px;
}
.copyPlanDialogContent_disabled{
    background: #f1ecd8;
    color: #000000;
    border-radius: 5px;
    width: 57%;
}

.copyPlanDialogContent_disabled:hover{
    background: #f1ecd8;
    color: #000000;
    border-radius: 5px;
}
