.datePickerContainer>.muiDatePicker {
    margin: 0px !important;
    border: none !important;
    border-radius: 3px !important;
    padding-left: 5px !important;
    /* width: 160px !important; */
}

.calendarIcon {
    width: 22px;
    height: 20px;
    padding-right: 5px;
}

.dropdownFilter {
    width: 160px !important;
    min-width: 160px !important;
}

.datepickerBar {
    display: flex;
    flex-direction: row;
    margin-top: 10px !important;
}

.datesFields {
    margin-left: 10px !important;
}

.dropdownStyle {
    /* border: 1px solid #808285 !important; */
    border-radius: 3px;
    height: 30px;
    /* width: 167px;
    min-width: 120px; */
}

.datePickerContainer{
    display: inline-flex;
    border: 1px solid #808285 !important;
    width: 165px;
    border-radius: 3px !important;
    height: 30px;
}
.datePickerContainer .MuiSvgIcon-root{
    color: #aaa;
}
.datePickerContainer .MuiSvgIcon-root:hover{
    cursor: pointer;
}