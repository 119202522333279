

  .mainActionBar {
    background-color: white;
    margin-block-start: -36px !important;
    padding-top: 5px ;
    padding-bottom: 5px ;
    padding-right: 10px;
    color: white;
    min-width: 1104px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
   }
   .actionButtons>div:nth-child(1).Mui-focused{
     background-color: none !important;
   }
   .viewdropdown>div.MuiInputBase-input {
     padding: 0px 2px 7px 3px !important;
   }
   .viewdropdown>div.MuiSelect-select:focus {
    background-color: none !important;
  } 

   .viewdropdown>svg.MuiSelect-icon{
    padding: 0px 2px 7px 2px !important;
   }

   .saveButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
   }

   .actionButtons {
     display: flex;
     flex-wrap: wrap;
     justify-content: flex-end;
     flex-direction: row;
   }

    .filterMain {
     display: flex;
     flex-wrap: wrap;
     flex-direction: row;
     margin-top: 24px;
   }

   .actionButtons >Button{
     padding: 14px !important; 
     margin: 5px !important; 
     text-transform: none !important;
     padding: 10px;
   }

    .MuiTypography-gutterBottom {
    margin-bottom: 0 !important;
}

.MuiMenuItem-root {
  font-family: customFontFamily !important;
  height: 28px;
  border-bottom: 1px solid lightgray;
}

.MuiInputBase-root {
    font-family: customFontFamily !important;
}

.MuiTypography-body1 {
   font-family: customFontFamily !important;
}

.muiDatePicker {
    margin-top: 0px !important;
    border: 1px solid #808285 !important;
    border-radius: 3px !important;
    padding-left: 5px !important;
    width: 165px !important;
}

.MuiOutlinedInput-notchedOutline {
    /* border-color: #808285 !important;
    border-radius: 3px !important;
    border-width: 1px !important; */
}

.filterStyle {
 min-width: 63%;
 display: flex;
 flex-direction: row;
}

.viewdropdown{
  width: 90px;
  height: 34px;
  margin: 0 0 0 18px;
  padding: 1px;
  border-radius: 5px;
  background-color: #ffcc00;
}
.actionButtons>div.MuiFormControl-root{
  margin-top: 7px !important;
}

.primaryButtonParameters {
  border: solid 1px;
    border-radius: 5px;
    background-color: #ffcc00;
    height: 30px;
    margin: 6px;
    padding-top: 2px;
}

.secondaryButtonParameters {
  border: solid 1px gray;
    border-radius: 5px;
    height: 31px;
    margin: 8px;
    padding-top: 2px;
}

.MuiButton-label {
  text-transform: none !important;
}
.searchbar{
  margin-right: 48%;
  margin-top: 8px;
}

.MuiButton-text {
  padding: 0px 8px !important;
}