.mainHeader {
    background-color: white;
    margin-block-start: -36px !important;
    padding-bottom: 5px;
    color: white;
    min-width: 1104px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}

.actionButtons>div:nth-child(1).Mui-focused {
    background-color: none !important;
}

.viewdropdown>div.MuiInputBase-input {
    padding: 0px 2px 7px 3px !important;
}

.viewdropdown>div.MuiSelect-select:focus {
    background-color: none !important;
}

.viewdropdown>svg.MuiSelect-icon {
    padding: 0px 2px 7px 2px !important;
}

.saveButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
    min-width: 36%;
    margin-right: 10px;
    margin-top: 10px;
}

.actionButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
    min-width: 36%;
    margin-right: 10px;
    margin-top: 15px;
}

.filterMain {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 24px;
}

.actionButtons>Button {
    padding: 14px !important;
    margin: 5px !important;
    text-transform: none !important;
    padding: 10px;
}

.imageIcon {
    margin: 10px;
    width: 24px !important;
    height: 28px;
}

.MuiMenuItem-root {
    font-size: 13px !important;
}

.MuiSelect-selectMenu {
    width: 165px !important;
    border-radius: 3px;
    margin-top: 5px !important;
    padding: 5px !important;
}

.MuiFormControl-root {
    margin-right: 10px !important;
}

.MuiSvgIcon-root {
    padding-top: 3px !important;
    margin-top: 0px !important;
    padding-right: 5px;
}

.MuiSelect-select {
    /* width: 120px !important; */
    border-radius: 3px !important;
    /* border: 1px solid grey !important; */
    /* background: #ffcc00 !important;  */
}

.dropdownfields {
    display: flex;
    flex-direction: column;
    width: 176px;
    margin-top: 5px;
}

.flexContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
}

.MuiTypography-gutterBottom {
    margin-bottom: 0 !important;
}

.MuiMenuItem-root {
    font-family: customFontFamily !important;
    height: 28px;
    border-bottom: 1px solid lightgray;
}

.MuiInputBase-root {
    font-family: customFontFamily !important;
}

.MuiTypography-body1 {
    font-family: customFontFamily !important;
}

.muiDatePicker {
    margin-top: 0px !important;
    border: 1px solid #808285 !important;
    border-radius: 3px !important;
    padding-left: 5px !important;
    width: 165px !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #808285 !important;
    border-radius: 3px !important;
    border-width: 1px !important;
}

.filterStyle {
    /* min-width: 63%;
    display: flex;
    flex-direction: row; */
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}

.viewdropdown {
    width: 90px;
    height: 34px;
    margin: 0 0 0 18px;
    padding: 1px;
    border-radius: 5px;
    background-color: #ffcc00;
}

.actionButtons>div.MuiFormControl-root {
    margin-top: 7px !important;
}

.primaryButton {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding-top: 2px;
    background-color: #ffcc00;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.secondaryButton {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding-top: 2px;
    background-color: transparent !important;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.MuiButton-label {
    text-transform: none !important;
}

.searchbar {
    margin-right: 0% !important;
    margin-top: 8px;
}

.MuiDialog-paperWidthSm {
    max-width: 675px !important;
}

.imageSpacing {
    padding-right: 15px !important;
    min-width: 24px !important;
}

.MuiListItem-root.Mui-selected {
    background-color: transparent !important;
}

.actiobarPopOver>div.MuiPopover-paper {
    border-top-right-radius: 0px !important;
    box-shadow: -5px 10px 20px 0px rgba(0, 0, 0, 0.1) !important;
    background-color: #ffffff !important;
    overflow-y: hidden !important;
    margin-top: 0.7px !important;
}

div.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.MuiDivider-root {
    background-color: #f3f2f3;
}