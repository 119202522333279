.newadjustmentaccordions {
  overflow: hidden;
  text-align: left;
}

.newadjustmentaccordions>div.MuiPaper-root{
  background: none !important;
}

.adjustmentaccordiondetails.MuiAccordionDetails-root {
  display: flex !important;
  flex-direction: row;
  padding: 8px 0px 16px;
}

.salesAccordiondetails {
  display: flex;
  flex-direction: column;
  /* margin-top: -15px;
  margin-bottom: -15px; */
  margin-left: 23px;
}


.salesOverallAccordiondetails {
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  margin-left: 6px;
  /* border: 1px solid black; */
}

.salesAdjustmentOverallAccordionContent {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  /* border: 1px solid red; */
}

.adjustmentAccordiondetailsTitle.MuiAccordionDetails-root {
  display: flex !important;
  flex-direction: row;
  padding: 5px 0px 16px;
}

.salesOverallAccordiondetailsTitle.MuiAccordionDetails-root {
  display: flex !important;
  flex-direction: row;
  padding: 16px 0px 16px;
}

.salesOverallAccordiondetailsTitle {
  width : 200px;
}

.salesOverallInnerAccordiondetailsTitle.MuiAccordionDetails-root {
  display: flex !important;
  flex-direction: row;
  padding: 16px 0px 16px;
}

.salesOverallInnerAccordiondetailsTitle {
  width : 179px;
}

.salesAccordiondetailsTotal.MuiAccordionDetails-root {
  display: flex !important;
  flex-direction: column !important;
  padding: 8px 0px 16px !important;
}

.salesAccordiondetailsTotal {
  display: flex !important;
  flex-direction: column;
  padding: 8px 0px 16px;
  margin-left: 5px;
}

.adjustmentAccordiondetailsContent.MuiAccordionDetails-root {
  display: flex;
  padding: 16px 11px 16px;
}

.adjustmentAccordiondetailsContent::-webkit-scrollbar {
  display: none;
}


.salesadjustmentcontent {
    display: flex;
    flex-direction: row;
    overflow: auto;
    width: 850px;
    /* margin-top: -30px; */
    margin-top: -10px;
    /* border: 1px solid green; */
}


.salesadjustmentcontent::-webkit-scrollbar {
  display: none;
}

.typeicons {
  display: flex;
}

.typeiconstyle {
  height: 14px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  color: #434244;
  margin: 1px 6px 1px 1px;
}


.adjustmentaccordions>div.MuiAccordion-root {
  display: flex;
}

.adjustmentexpand>div.MuiAccordionSummary-expandIcon {
  transform: rotate(270deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.adjustmentexpand>div.MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg);
}

.adjustmentaccordions {
  display: inline-flex;
}

.adjustmentaccordions::-webkit-scrollbar {
  display: none;
}

.adjustmentexpand>div.MuiAccordionSummary-content {
  order: 0;
  min-width: 60px;
  margin: 6px 11px !important;
}

.applyMonthMargin>div:nth-child(1) {
  min-width: 100px;
}

.verticalAdjustmentAccordion {
  width: 8%;
  min-width: 8%;
  margin-left: 18px;
}

.adjustmentsview {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  padding: 0px 0px 0px 0px;
}

.adjustmentAlignment {
  /* display: flex; */
  font-size: 13px;
  flex-direction: column;
}

.accordionContent {
  display: flex;
}

.alignTitleContent {
  display: flex;
}

.alignTitleInSales {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  margin-left: 10px;
  text-align: left;
}

.alignTotal {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  margin-left: 10px;
  text-align: left;
  background-color: #f3f2f3;
  border-radius: 3px;
}

.allBUAccordionTotal {
  display: flex !important;
  flex-direction: column !important;
  padding-right: 20px;
  font-size: 13px;
  width: 80px;
}

.alignContent {
  display: flex;
  text-align: left;
  flex-direction: column;
  margin-top: -20px;
  margin-left: 1.5%;
}

.alignColumnContentForSalesAdjustment {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-left: 4%;
}

.totalCurrentValue {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  width: 80px;
  text-align: right;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 10px;
  color: #808285;
  
}

.salesOverallDivider {
    width: 100% !important;
    height: 1px;
    background: red;
    margin-left: 43px !important;
    margin-top: 0px !important;
    margin-bottom: 7px !important;
}

.allBuChildDivider {
    width: 1121px !important;
    height: 1px;
    background: red;
    margin-left: 43px !important;
    margin-top: 7px !important;
    margin-bottom: 7px !important;
}

.allBuOverallDivider>div.MuiDivider-root {
  background-color: #f3f2f3 !important;
}

.subDivider {
  width: 1000px !important;
  height: 1px;
  margin-top: 20px !important;
  margin-left: -100px !important;
}

.subDivider>div.MuiDivider-root {
  background-color: #f3f2f3 !important;
}

.salesAdjustmentHeading {
    min-width: 90px;
    width: 175px;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    color: #434244;
    display: inline-flex;
    margin-top: 3px;
}

.salesAdjustmentInnerheading{
    min-width: 90px;
    width: 152px;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    color: #434244;
    display: inline-flex;
    margin-top: 3px;
}



.salesAndFinanceAllBuMinWidth{
  justify-content: flex-end;
  display: flex;
  /* padding-right: 0px; */
  width: 99px;
  min-width: 99px;
  /* padding-left: 19px; */
  border-radius: 3px;
  padding-right: 3px;
}

.salesBuContent {
  font-size: 13px;
  margin-top: 8px;
  margin-left: 20px;
  color:#808285;
  font-style: italic;
  flex-direction: row;
  width: 150px;
}

.salesBuTitle {
  width: 200px;
  min-width: 200px;
  font-size: 13px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}


.salesActivityediticon{
  /* margin: -14px 6px 34px 6px; */
  padding: 9px 0 14px;
  /* box-shadow: -5px 10px 20px 0 rgb(0 0 0 / 10%); */
  background-color: #fff;
}

.salesActivityediticonDisabled{
  opacity: 0.3;
}

.salesAdjustmentsSaveBtn{
  border: solid 1px #fc0;
  border-radius: 5px;
  height: 29px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 12px;
  margin:0px 0px 0px 5px ;
  background-color: #ffcc00;
  box-shadow: 0 0 50px 0 rgb(0 0 0 / 10%);
  /* width: 47px; */
  /* if width is applied to save button it will scroll */
}

.editiconmargin{
  /* border: 1px solid blue; */
}

@media only screen and (width: 1600px) {

  .applySalesAndFinanceMonthMargin{
    width: 1150px;
  }
  .readOnlynoMarginOverall{
    width: 1150px;
  }
  .salesadjustmentcontent{
    width: 1150px;
  }
}

@media only screen and (width: 1920px) {
  .applySalesAndFinanceMonthMargin{
    width: 1480px;
  }
  .readOnlynoMarginOverall{
    width: 1480px;
  }
  .salesadjustmentcontent{
    width: 1480px;
  }
}

@media only screen and (width: 2400px) {
  .applySalesAndFinanceMonthMargin{
    width: 1950px;
  }
  .readOnlynoMarginOverall{
    width: 1950px;
  }
  .salesadjustmentcontent{
    width: 1950px;
  }
}

