.fromccgroup{
    width: 110px;
    height: 13px;
    margin: 2px 36px 3px 16px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #808285;
    text-transform: capitalize !important;
}
.toccgroupstyle{
    width: 110px;
    height: 13px;
    margin: 2px 0 3px 21px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #808285;
    text-transform: capitalize !important;
}
.textalignment{
    text-overflow: ellipsis;
    overflow: hidden;
}