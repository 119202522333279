.planassumptiontabs{
    display: flex;
}
.planassumptionbgcolor{
    background-color:#ffffff;
    /* height: 650px; */
    min-width: 1000px;
    width: 1110px;
}
.planassumptiontabs>div>div:nth-child(3)>div>button{
    margin: 0 0 5px 12px !important;
}

.planassumptiontabs>div>div:nth-child(3)>div>button:active{
    border-radius: 4px !important;
    background-color: #ffed9b !important;
    color: #000000;
}

.planassumptiontabpanel{
    min-width: 942px;
    margin-top: -35px;
}
.planassumptiontabpanel>div>p.MuiTypography-body1{
    width:917px;
}
.planassumptiontabs>div.MuiTabs-vertical{
    min-width: 18%;
    /* height: 528px; */
    height: fit-content;
    margin-top: -91px;
    margin-left: -7px;
}

@keyframes copyinprogress_sub_active {
    0%    {background-image: linear-gradient(to right, gold ,gold ,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b)}
    10%   {background-image: linear-gradient(to right, #ffed9b,gold ,gold ,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b)}
    20%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,gold ,gold ,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b)}
    30%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,gold ,gold ,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b)}
    40%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,gold ,gold ,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b)}
    50%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,gold ,gold ,#ffed9b,#ffed9b,#ffed9b,#ffed9b)}
    60%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,gold ,gold ,#ffed9b,#ffed9b,#ffed9b)}
    70%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,gold ,gold ,#ffed9b,#ffed9b)}
    80%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,gold ,gold ,#ffed9b)}
    90%   {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,gold ,gold )}
    100%  {background-image: linear-gradient(to right, #ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,#ffed9b,gold )}
  }
  @keyframes copyinprogress_sub {
    0%    {background-image: linear-gradient(to right, gold ,gold ,white,white,white,white,white,white,white,white,white)}
    10%   {background-image: linear-gradient(to right, white,gold ,gold ,white,white,white,white,white,white,white,white)}
    20%   {background-image: linear-gradient(to right, white,white,gold ,gold ,white,white,white,white,white,white,white)}
    30%   {background-image: linear-gradient(to right, white,white,white,gold ,gold ,white,white,white,white,white,white)}
    40%   {background-image: linear-gradient(to right, white,white,white,white,gold ,gold ,white,white,white,white,white)}
    50%   {background-image: linear-gradient(to right, white,white,white,white,white,gold ,gold ,white,white,white,white)}
    60%   {background-image: linear-gradient(to right, white,white,white,white,white,white,gold ,gold ,white,white,white)}
    70%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,gold ,gold ,white,white)}
    80%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,gold ,gold ,white)}
    90%   {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,white,gold ,gold )}
    100%  {background-image: linear-gradient(to right, white,white,white,white,white,white,white,white,white,white,gold )}
  }


  .planassumptionactionButtons {
    min-width: 50%;
}

.planassumptionmainHeader {
    background-color: transparent;
    width: auto;
}

.planassumptionbgcolor {
    /* height: 750px; */
    min-width: inherit;
    width: auto;
}

.planassumptiontabpanel>div>p.MuiTypography-body1 {
    width: auto;
}

.planassumptiontabpanel {
    min-width: 82%;
    width: 100%;
}

/* .margin {
    margin-right: 8px
} */

.paddingtarget .MuiBox-root {
    padding-right: 0px !important;
}
.constraintactions {
    min-width: auto !important;
}
.mainHeader {
    min-width: auto !important;
}
.mainActionBar {
    min-width: auto !important;
}
.upgradeactions {
    min-width: auto !important;
}
.planassumptionfilterStyle {
    /* min-width: 55%; */
    margin-left: 46px;
}
.planassumptionactionButtons {
    min-width: 30%;
}

/* */
@media screen and (max-width: 4000px) {
    #grid-theme-wrapper>div>div{
        /* height: 1500px !important; */
        height: initial !important;
    }
    /* .margin {
        height: 1800px !important;
    } */
    /* .planassumptionbgcolor {
        height: 1400px;
    } */
    .planassumptionfilterStyle {
        margin-left: 500px !important;
    }
}

@media screen and (max-width: 2600px) {
    #grid-theme-wrapper>div>div{
        /* height: 900px !important; */
        height: initial !important;
    }
    /* .margin {
        height: 1230px !important;
    } */
    /* .planassumptionbgcolor {
        height: 1050px;
    } */
    .constraints-adjust #grid-theme-wrapper>div>div>div{
        height: 800px !important;
    }
    .planassumptionfilterStyle {
        margin-left: 12% !important;
    }
    
}


@media screen and (max-width: 1500px) {
    #grid-theme-wrapper>div>div{
        /* height: 600px !important; */
        height: initial !important;
    }
    .constraints-adjust #grid-theme-wrapper>div>div>div{
        height: 590px !important;
    }
    /* .margin {
        height: 920px !important;
    } */
    /* .planassumptionbgcolor {
        height: 750px;
    } */
    .planassumptionfilterStyle {
        margin-left: 50px !important;
    }
    
}


@media (min-width: 600px) {
    .MuiTab-root {
        min-width: 160px;
        max-width: 100% !important;
    }
}