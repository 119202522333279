 .constraintdropdown {
      display: flex;
      flex-direction: column;
      width: 176px;
      margin-left: 10px;
    } 

.MuiSelect-select.MuiSelect-select {
    padding-left: 8px !important;
}
.constraintdropdown>div>div>div>div.dropdown-heading-value{
    font-size: 12px;
}
.constraintdropdown>div>div>div.dropdown-content{
    font-size: 13px !important;
    color: gray;
    font-family: customFontFamily !important;
}

/* .rmsc .options{
    color: gray !important;
    font-size: 13px !important;
} */
.dropdown-container:focus-within{
    border-color:#808285 !important;
    box-shadow: #808285 0 0 0 0px !important;
}
.constraintdropdown>p{
    width: 254px;
    height: 18px;
    margin: 0 26px 5px 0;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #808285;
}
.dropdownFilter>p{
    width: 254px;
    height: 18px;
    margin: 0 26px 5px 0;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #808285;
}
.constraintdropdown>div>div.dropdown-container{
    border-color:#808285 !important;
}

.go2642161244 {
    height: 30px !important;
}