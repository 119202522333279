.hotcoldicon{
    width: 15px;
    height: 14px;
    margin: 7px -10px -1px 0px;
}
.pathtempstyle{
  width: 30px;
  height: 13px;
  margin: 2px 60px 3px 15px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  color: #434244;
}