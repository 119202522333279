.constraintsort{
    border-bottom: solid 2px #f3f2f3;
    background-color: #ffffff;
    padding: 0px 10px 4px 32px;
}

.fixedHeader {
    width: 1104px !important;
}
.groupsort{
    min-width: 164px;
    display: inline-block;
}
.attheader{
    margin-right: -28px;
}
.datemargin{
    min-width: 173px;
    display: inline-block;
    margin: 3px -35px 4px 30px;
}
.minmargin{
    min-width: 94px !important;
    display: inline-block;
    margin: 3px 16px 4px 30px;
}
.maxmargin{
    min-width: 105px;
    display: inline-block;
    margin: 3px 25px 4px -35px;
}
.attributemargin{
    min-width: 262px;
    display: inline-block;
    margin: 3px 76px 4px -38px;
}
.norecords{
    text-align: center;
    font-size: 12px;
    margin-top: 32px;
}
.applyFreezMonthColor{
    color:#ffcc00;
  }