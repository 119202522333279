.deleteIconBlur{
    padding-right: 8px;
    padding-top: 10px;  
    opacity: 0.3;
}
.deleteIcon{
    padding-right: 8px;
    padding-top: 10px;  
    opacity: 1;
}
