.flexContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
  }

.errorMain {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 24px;
}

.errorMainForRange {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 24px;
    width: 100%;
}

.errorMargin {
    margin:-1px 10px 0px 10px;
  }