.startRunDialogBox>div.MuiDialog-container {
    height: 95% !important;
    width: 95% !important;
}

.startRunDialogBox>div>div.MuiDialog-paperScrollPaper {
    max-height: 660px;
    height: 95%;
    width: 600px;
    padding: 12px 6px 12px 6px;
    border-radius: 5px;
    background-color: #ffffff;
}
.startRundialogtitle{
    border-bottom:2px ridge;
    padding: 10px 24px !important;
}
.startRundialogtitle>h2.MuiTypography-h6 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}

.startRundialogtitle>h2.MuiTypography-h6>span {
    /* width: 121px;
    height: 16px; */
    font-size: 18px;
    font-family: customFontFamily !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.73;
    letter-spacing: normal;
    color: #434244;
}

.startRundialogtitle>h2.MuiTypography-h6>span:nth-child(2) {
    font-weight: normal !important;
    color: red;
}

.startRunCloseIcon {
    width: 16px;
    height: 16px;
}

.startRunMainTabsFlexContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: space-around;
    height: 190px;
} 

.startRunPlanAssumptionTabsFlexContainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: space-around;
    height: 355px;
    width: 100%;
    margin-top: 2%;
}

.planAssumptionTabTitle{
position:relative;
inset:0 0 1% -23%;
font-weight: bolder;
font-size: 15px;
}

.startRunDialogContent{
    background-color: lightyellow;
}
.validStartRunDisplayName {
    border-radius: 4px;
    color: white;
    background-color: black;
    text-transform: none;
    text-align: center;
    height: fit-content;
    width: 50%;
    font-size: 16px;
}

.inValidStartRunDisplayName {
    border-radius: 4px;
    color: white;
    background-color: red;
    font-size: 14;
    text-transform: none;
    text-align: center;
    height: fit-content;
    width: 50%;
    font-size: 16px;
}

.startRunActionbuttons{
    border-top:2px ridge;
    padding: 0px 24px !important;
}
.startRunActionbuttons>button.cancelbutton {
    padding: 14px !important;
    margin: 5px !important;
    border-radius: 4px;
    border: solid 1.5px #808285;
    background-color: #ffffff;
    height: 30px !important;
}

.startRunActionbuttons>span>button.okbutton {
    padding: 14px !important;
    margin: 5px !important;
    border: none;
    background-color: #ffcc00;
    height: 30px !important;
}

.startRunActionbuttons>span>button.buttonblur {
    padding: 14px !important;
    margin: 5px !important;
    border: none;
    opacity: 0.7;
    height: 30px !important;
    background-color: #ffcc00;
}

.startRunActionbuttons>button>span.MuiButton-label,
.startRunActionbuttons>span>button>span.MuiButton-label {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    text-align: center;
    color: #434244;
}