.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input {
    border: 1px solid lightgray;
    border-radius: 3px;
    padding-right: 21px !important;
    width: 98px !important;
    background: white;
    min-width: 90px;
    height: 12px;
    margin-top: 1px !important;
    margin-right: 2px !important;
    color: black !important;
    font-size: 13px !important;
}

.MuiAutocomplete-option {
    font-size: 13px !important;
    color: black !important;
    padding: 5px !important
}

.MuiAutocomplete-popupIndicatorOpen {
    padding-left: 8px !important;
    padding-right: 8px !important;
    padding-top: 3px !important;
}

.MuiAutocomplete-popupIndicator {
    padding: 2px;
    margin-right: -2px;
    position: relative;
    display: flex;
}
.upgradeAutocomplete{
    width: 218px;
}
.upgradeAutocomplete>div>div>div>div.MuiAutocomplete-endAdornment{
    top: calc(50% - 16px);
    right: 58px;
}