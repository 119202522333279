.selectedplan{
    margin: 3px 9px 3px 0;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.72;
    letter-spacing: normal;
    color: #434244;
  }
  .popupopen{
    display: flex;
    flex-wrap: wrap;
    margin-right: 17px;
    padding: 7px 14px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background-color: #ffffff;
    text-align: left;
  }
  @media screen and (max-width: 500px) {  
      .popupopen { 
          width: 100px; 
          display: inline-block;
      } 
  }
  .popupclose{
    min-width: 223px;
    display: flex;
    flex-wrap: wrap;
    margin-right: 17px;
    padding: 7px 14px;
    border-radius: 4px;
    background-color: #ffffff;
    text-align: left;
  }
  @media screen and (max-width: 500px) {  
      .popupclose { 
          width: 90px; 
      } 
  }
  .planwidth{
    min-width: 194px;
  }
  .newtext{
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.57;
    letter-spacing: normal;
    text-align: center;
    color: #434244;
    border-radius: 4px;
    background-color: #ffde57;
    padding-bottom: 1px;
    padding-left: 3px;
    padding-right: 3px;
}
.chevrondown{
    width: 18px;
}
.popover>div.MuiPopover-paper {  
    top: 50px !important;
    left: 289px !important;
    width: auto;
    display: block;
    height: auto;
    padding: 13px 0 20px;
    border-top-left-radius: 0px !important;
    box-shadow: 2px 2px 4px 0 rgb(67 66 68 / 30%) !important;
    background-color: #ffffff !important;
    overflow-y: hidden !important;
}
 
 @media screen and (max-width: 900px) {
    .popover>div.MuiPopover-paper {
        left: 26px !important;
    }
 }
 .plansdropdown{
    display: inline-block;
    width: 590px;
 }
 .planlist{
    height: 16px;
    /* font-family: Ride; */
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    list-style-type: none;
    padding-left: 0px;
}
.planliststyle{
    display: flex;
    flex-wrap: wrap;
    border-bottom: 2px solid #f3f2f3;
    padding-left: 11px;
    text-align: center;
    padding-top: 7px;
}
.planliststyle>span:nth-child(1){
  width: 1px;
  height: 16px;
  margin-left: 1%;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  cursor: pointer;
}
.planliststyle>span:nth-child(1){
    color: #006bbd;
  }
.planliststyle>span:nth-child(2){
  width: 124px;
  margin-left: 10%;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #434244;
  text-align: left;
}
.planliststyle>span:nth-child(2){
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.planliststyle>span:nth-child(3){
    width: 10%;
    margin-left: 5%;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #434244;
}
.planliststyle>span:nth-child(4){
    width: 23%;
    margin-left: 1%;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: normal;
    color: #434244;
}
.statusstyle{
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.0;
    letter-spacing: normal;
    color: #434244;
    text-transform: capitalize;
    margin: -5px 0px 4px 0px;
}
.plannew{
    background-color: #ffcc00;
    padding: 5px 0px 5px 0px;
    border-radius: 12px;
    min-height: 16px;
    height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
}
.planready{
    background-color: #e6e7eb;
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
}
.planrunning{
    background-color: #ece4f4;
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
}
.plancomplete{
    background-color: #e5fdf1;
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
}
.planpublish{
    background-color:#e2f4ff;
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
}
.planfail{
    background-color: #fee6d8;
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
}
.copylink{
    padding-bottom: 0px !important;
    width: 18px;
    margin: 0px 14px 10px 6px;
    object-fit: contain;
    padding-top: 2px !important;
}
.copyimg{
    width: 15px;
    margin: 0px 0px 0px 21px;
}
.planlinks{
    position: relative;
    padding-left: 14px;
    margin-top: 15px;
    margin-bottom: -14px;
    display: flex;
    flex-wrap: wrap;
}
.planlinks>a:nth-child(1){
    width: 70%;
}
.planlinks>a:nth-child(2){
    margin-left: 11%;
}
.linksimg{
    width: 12px;
}
.linkname{
    height: 16px;
    margin: 0 0 4px 6px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;
    color: #434244;
}

.iconwidth{
    margin-left: 11px;
}

/* default topbar style */
@media (min-width: 0px) and (orientation: landscape){
.MuiToolbar-regular {
    min-height: 64px !important;
 }
}
.MuiToolbar-regular {
    min-height: 64px !important;
}
.planadjustmentcomplete{
    padding: 7px 0px 7px 0px;
    background-color: #f0fde5;
    border-radius: 12px;
    min-height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
  }
  .completeOptimal {
    background-color: #e5fdf1;
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
  }
  .pdSupOptm {
    background-color: #ff980e73;
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
  }
  .runUnknown {
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
    background-color:  #eb200b54;
  }
  .EditingAdjustments{
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
    background-color:  #e0908854;
  }
  .AdjustmentsModified{
    padding: 7px 0px 7px 0px;
    border-radius: 12px;
    min-height: 16px;
    min-width: 60px;
    width: 84px;
    margin: -2px 0px 0px 10px;
    background-color:  #8d8bd054;
  }