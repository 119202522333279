.upgradeactionbar{
    margin-top: -41px;
  }

  .fixedUpgradeActionBar {
    width: 1104px;
  }
.upgradebuttons{
    background-color: white;
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .upgradebtnstyel{
    height: 38px !important;
    width: 274px;
    margin-left: 11px;
  }
.upgradeactions{
    background-color: white;
    margin-block-start: 0px !important;
    padding-top: 5px ;
    padding-bottom: 5px ;
    color: white;
    min-width: 1104px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}
.upgradebtnstyel>button.MuiButton-root.btnactive{
  border-radius: 4px !important;
  background-color: #ffed9b !important;
}
.upgradebtnstyel>button.MuiButton-root:hover{
    background-color:#ffffff !important;
  }
.upgradebtnstyel>button.MuiButton-root.btnactive:hover{
    background-color: #ffed9b !important;
  }
  .upgradebtnstyel>button>span.MuiButton-label{
    width: 172px;
    height: 27px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .upgradefilterStyle {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
}
.upgradesaveButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
   }
.upgradeprimaryButton {
    border: solid 1px;
    border-radius: 5px;
    background-color: #ffcc00;
    height: 33px;
    margin: 6px;
    padding-top: 2px;
  }
  
.upgradesecondaryButton {
    border: solid 1px gray;
    border-radius: 5px;
    height: 31px;
    margin: 8px;
    padding-top: 2px;
  }