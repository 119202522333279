.buContent {
    font-size: 13px;
    margin-top: 8px;
    margin-left: 20px;
    color:#808285;
    font-style: italic;
    flex-direction: row;
    width: 150px;
}

.buContentValues {
    font-size: 13px;
    margin-top: 10px;
    margin-left: 20px;
    color:#808285;
    font-style: italic;
}

.adjustmentcontents{
    height: 37px;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
  }

  .adjustmentpanel{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    font-size: 12px;
    width: 100%;
    padding-left: 9px;
    margin-top: 7px;
  }

  .adjustmentcontentpanel{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    font-size: 12px;
    min-width: 96%;
  }

  .buAccordionContent {
    overflow: scroll;
    display: inline-flex;
    width: 800px;
    /* margin-left: 125px; */
    /* margin-top: -25px; */
}

.buAccordionContent::-webkit-scrollbar {
    display: none;
  }

  .allBuTitle {
    width: 108px;
    min-width: 108px;
    font-size: 13px;
    margin-left: 10px;
  }
