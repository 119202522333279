.allplansheader{
    width: 193px;
    height: 38px;
    margin: 30px 816px 30px 28px;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: -1px;
    color: #000000;
    margin-top: -16px;
    padding-top: 8px;
}
.allplansmargin{
    margin-left: 5px;
    margin-top: 20px;
    margin-right: 40px;
}
.flexitems{
  display: flex !important;
  justify-content: space-between;
  margin-right: -25px;
}

.planAddButton{
    width: 67px;
    border: solid 1px  #ffcc00;
    border-radius: 5px;
    background-color: #ffcc00;
    height: 33px;
}
.addlabel{
    width: 27px;
    height: 14px;
    margin: 0 0 1px 6px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #434244;
    cursor: pointer;
  }
  .planAddButton>button.MuiButton-text{
    padding: 11px 11px !important;
  }
  .planAddButton>button>span{
    margin-top: -3px !important;
  }
  .addblur{
    filter: blur(0.4px);
  }