.ag-react-container>button.hotstyle{
    width: 75px !important;
    height: 35px !important;
    margin: -3px 5px 0 0;
    padding: 15px 0 14px;
    /* border-radius: 5px; */
    background-color: #eb7000 !important;
    color: #ffffff !important;
}
.ag-react-container>button.coldstyle{
    width: 75px !important;
    height: 35px !important;
    margin: -3px 5px 0 0;
    padding: 15px 0 14px;
    /* border-radius: 5px; */
    background-color:#006bbd !important;
    color: #ffffff !important;
}
.ag-react-container>button.emptyvaluestyle{
    width: 75px !important;
    height: 36px !important;
    border-radius: 5px !important;
    margin: -1px 1px 2px 0px;
    padding: -1px 0px 0px 0px;
    text-align: center;
    padding-top: -1px;
    line-height: 32px;
    background-color: #80828594 !important;
    color: #ffffff !important;
}
.ag-cell-focus{
    border: none !important;
}
.ag-react-container>button.MuiToggleButton-root{
    width: 75px !important;
    height: 36px !important;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #434244;
    text-transform: capitalize;
}
.TogglebtnvalidRow, .TogglebtnerrorCell {
    background-color: transparent !important;
}

.TogglebtnvalidRow::after, .TogglebtnerrorCell::after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 0;
    border-color: transparent #E90133 transparent transparent;
} 
.TogglebtnerrorCell::after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 0;
    border-color: transparent #E90133 transparent transparent;
}

 .TogglebtnvalidRow::before, .TogglebtnerrorCell::before {
    content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    display: block;
    border: 1.5px solid #E90133;
    border-radius: 3px;
} 
.TogglebtnerrorCell::before {
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: block;
    border: 1.5px solid #E90133;
    border-radius: 3px;
} 
.TogglebtnvalidRow::after{
    border-right-color: #006bbd;
}
.TogglebtnvalidRow::before{
    width: 73px !important;
    height: 34px !important;
    margin: -3px 10px -5px -3px;
    padding: 15px 0 14px;
    border-radius: 5px;
    border-color: #006bbd;
}
.ag-react-container>button.hotstyle::after {
    content: "";
    position: absolute;
    top: 0px;
    right: -1px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width:  0 11px 11px 0;
    border-color: transparent #E90133 transparent transparent;
} 

 .ag-react-container>button.hotstyle::before {
    content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    display: block;
    border: 1.5px solid #E90133;
    border-radius: 3px;
    border-top-right-radius: 0px !important;
} 
.ag-react-container>button.hotstyle::after{
    border-right-color: #ffb775;
}
.ag-react-container>button.hotstyle::before{
    width: 72px !important;
    margin: -3px 10px -5px -4px;
    padding: 15px 0 14px;
    border-radius: 5px;
    border-color: #ffb775;
}
.ag-react-container>button.coldstyle::after {
    content: "";
    position: absolute;
    top: 0px;
    right: -1px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width:  0 11px 11px 0;
    border-color: transparent #E90133 transparent transparent;
} 

 .ag-react-container>button.coldstyle::before {
    content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    display: block;
    border: 1.5px solid #E90133;
    border-radius: 3px;
} 
.ag-react-container>button.coldstyle::after{
    border-right-color: #bbe2fa;
}
.ag-react-container>button.coldstyle::before{
    width: 72px !important;
    margin: -3px 10px -5px -4px;
    padding: 15px 0 14px;
    border-radius: 5px;
    border-color: #bbe2fa;
}
.ag-react-container>button.emptyvaluestyle::after {
    content: "";
    position: absolute;
    top: 0px;
    right: -1px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width:  0 11px 11px 0;
    border-color: transparent #E90133 transparent transparent;
} 

 .ag-react-container>button.emptyvaluestyle::before {
    content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    display: block;
    border: 1.5px solid #E90133;
    border-radius: 3px;
} 
.ag-react-container>button.emptyvaluestyle::after{
    border-right-color: #ffb775;
}
.ag-react-container>button.emptyvaluestyle::before{
    width: 72px !important;
    margin: -3px 10px -5px -4px;
    padding: 15px 0 14px;
    border-radius: 5px;
    border-top-right-radius: 0px !important;
    border-color: #ffb775;
}