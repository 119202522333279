.mainAdjustmentHeader {
    background-color: white;
    margin-block-start: -36px !important;
    padding-bottom: 5px;
    color: white;
    width: 1183px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
}
.AllBumainAdjustmentHeader{
    background-color: white;
    margin-block-start: -36px !important;
    padding-bottom: 5px;
    color: white;
    width: 1193px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
}

.adjustmentsActionButtons>div:nth-child(1).Mui-focused {
    background-color: none !important;
}

.adjustmentsActionButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
}

.adjustmentsActionButtons>Button {
    padding: 14px !important;
    margin: 5px !important;
    text-transform: none !important;
    padding: 10px;
}

.adjustmentsFilterStyle {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: 90%;
}

.adjustmentsActionButtons>div.MuiFormControl-root {
    margin-top: 0px !important;
}
.adjFilterIcon{
    cursor: pointer;
}

.expandAllCollapseAllButton{
    border: solid 0px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 12px;
    margin: 6px;
    padding: 3px;
    padding-top: 5px;
    padding-right: 5px;
    background-color: #ffcc00;
    color: black;
    cursor: pointer;
}

.expandAllCollapseAllButton img{
    width: 16px;/* Original size is 16X16 */
    height: 16px;
    padding: 3px;
}

.errorMessage{
    color: red;
    background-color: #ffcccc;
    flex-basis: 100%;
    align-content: center;
    text-align: center;
    border-radius: 5px;
}