.pathcontenticons{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: solid 2px #f3f2f3 !important;
    height: 41px;
}
.pathcontentpanel{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    width: 82%;
    margin: 13px 10px 13px 20px;
}
.pathdeleteicon{
    margin: 9px 10px 10px 20px;
    cursor: pointer;
    min-width: 6px;
}
.patharrowicon{
    margin: 9px 10px 10px 20px;
    cursor: pointer;
    min-width: 10px;
}
.tocc{
    min-width: 30px;
    width:19%;
    text-align: left !important;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #434244;
    display: inline-block;
    margin-left: 12px;
}
.pathcost{
    margin-right: 2%;
    min-width: 72px;
    text-align: right !important;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: right;
    color: #434244;
}
.pathchoice{
    margin-right: 39%;
    min-width: 72px;
    text-align: right !important;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: right;
    color: #434244;
}
.toccgroup{
    width: 12%;
    height: 13px;
    margin: 0 -8px 0 -54px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #808285;
    text-transform: capitalize !important;
    word-break: break-all;
}
.anchoestyle{
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}
.toccstyle{
    min-width: 30px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #434244;
}
/* .upgradecontentdialog>div.MuiDialog-root.dialogbox>div.MuiBackdrop-root{
    background-color: rgba(0, 0, 0, 0.2) !important;
} */
 .MuiBackdrop-root{
    background-color: rgba(0, 0, 0, 0.2) !important;
} 
.MuiPaper-elevation24 {
    box-shadow: -2px 11px 15px -7px rgb(0 0 0 / 16%), 0px 24px 38px 3px rgb(0 0 0 / 0%), 0px 9px 46px 8px rgb(0 0 0 / 0%) !important;
}