.constraintactionbar{
  margin-top: -36px;
}

.fixedConstraintActionBar {
  width: 1104px !important;
}
.constrainttypes{
  background-color: white;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.constraintactions {
    background-color: white;
    margin-block-start: 0px !important;
    padding-top: 20px ;
    padding-bottom: 5px ;
    color: white;
    min-width: 1104px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
   }
   .conactionButtons>div:nth-child(1).Mui-focused{
     background-color: none !important;
   }
   .viewdropdown>div.MuiInputBase-input {
     padding: 0px 2px 7px 3px !important;
   }
   .viewdropdown>div.MuiSelect-select:focus {
    background-color: none !important;
  }
  
   .viewdropdown>svg.MuiSelect-icon{
    padding: 0px 2px 7px 2px !important;
   }
   .constraintfilterStyle {
    /* width: 175px; */
    display: flex;
    flex-direction: row;
    margin-top: 8px;
   }
   .constraintfilterStyle>div.MuiFormControl-root>div.viewdropdown{
     width: 121px !important;
     height: 32px;
   }
   .constraintfilterStyle>div.MuiFormControl-root>div.viewdropdown>div.MuiInputBase-input{
    padding: 0px 2px 7px 20px !important;
    height: 14px;
    font-size: 13px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #434244;
   }
   .conactionButtons {
     display: flex;
     flex-wrap: wrap;
     justify-content: flex-end;
     flex-direction: row;
     margin-top: 8px;
   }

   .conactionButtons >Button{
     padding: 14px !important; 
     margin: 5px !important; 
     text-transform: none !important;
     padding: 10px;
   }

   .imageIcon {
     margin: 10px;
      width: 24px !important;
  height: 28px;
   }

 .MuiMenuItem-root {
    font-size: 13px !important;
  }

    .MuiSelect-selectMenu {
      width: 165px !important;
      border-radius: 3px;
      margin-top: 5px !important;
      padding: 5px !important;
    }

    .MuiFormControl-root {
       margin-right: 10px !important;
    }

    .MuiSvgIcon-root {
      padding-top: 3px !important;
      margin-top: 0px !important;
      padding-right: 5px;
      
    }
.MuiTypography-body1 {
   font-family: customFontFamily !important;
}
.MuiOutlinedInput-notchedOutline {
    border-color: #808285 !important;
    border-radius: 3px !important;
    border-width: 1px !important;
}
.typebuttons{
  padding-left: 11px;
}
.activefontstyle{
  color: #000000 !important;
  font-weight: bold !important;
}
.types{
  /* min-width: 86px; */
  /* width: 57px; */
  height: 19px;
  margin: 4px -6px 4px -14px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #434244;
  font-family: customFontFamily !important;
  text-align: left;
  align-items: center;
  padding-right: 20px;
    padding-left: 20px;
    padding-top: 4px;
}
.typecount{
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #434244;
  font-family: customFontFamily !important;
  padding-right: 10px;
}
.constraintsearchbar{
  margin-right: 48%;
  margin-top: 8px;
  width: 100px;
  /* margin-left: -102px; */
}
.btnactive{
  border-radius: 4px !important;
  background-color: #ffed9b !important;
}
.typebuttons>button.MuiButton-root:hover{
  background-color:#ffffff !important;
}
.MuiTouchRipple-root{
  display: none !important;
}

.typebuttons>button.MuiButton-root.btnactive:hover{
  background-color: #ffed9b !important;
}
.typebuttons>button.MuiButton-root{
  height: 31px !important;
  flex-wrap: wrap;
  min-width: 80px;
  align-items: baseline;
}

.dividerStyle {
  width: 100%;
  margin-top: 15px;
}

.dialogClass {
 
    max-width: 770px !important;
  
}
.constraintfilterStyle>div>div.MuiInputBase-root::before{
  background-image: url(../../assets/images/icon_filter.svg);
  background-repeat: no-repeat;
  padding: 1px 0px 9px 10px;
}

/* filterstyles */
.presetdropdown{
  margin-left: -17px;
  width: 420px;
  height: 51px;
}
.presetdropdown>div>div.MuiInputBase-root{
  width: 156px;
  height: 29px;
  border: solid 1.5px #cccdd0 !important;
  border-radius: 5px !important;
  background-color: #ffffff;
}
.presets{
  width: 291px;
  height: 18px;
  margin: 0 30px 5px 0;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808285;
}
.botomborder{
  width: 648px;
  margin: -6px -45px -3px -26px;
  border-bottom: solid 1px #e4e4e5;
  position: static;
}

.cancelButton {
  border: solid 1px #808285;
  border-radius: 5px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 12px;
  margin: 6px;
  padding-top: 2px;
  background-color: transparent !important;
}

.filterPrimaryButton {
  border: solid 1px transparent;
  border-radius: 5px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 12px;
  background-color: #ffcc00;
  /* box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1); */
}

.filterSecondaryButton {
  border: solid 1px transparent;
  border-radius: 5px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 12px;
  background-color: #ffffff !important;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2) !important;
}

.filterCustomButton {
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  /* font-size: 12px; */
}

.filterPopOver>div.MuiPopover-paper {
  width: 200px;
  border-top-left-radius: 0px !important;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff !important;
  overflow-y: hidden !important;
  /* padding: 8px 16px 26px 10px; */
  border-radius: 5px;
  margin-top: 0.7px !important;
  /* border: solid 1px #eeeeee; */
}

.filterMargin {
  margin-left: 10px;
  margin-right: 10px;
}
div.is-disabled
{
  pointer-events: none;
  opacity: 0.7;
}
.moveConstraintsaveButtons{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: row;
  min-width: 99%;
  margin-right: 10px;
  margin-top: 10px;
}
.moveConstraintactionButtons>div:nth-child(1).Mui-focused {
  background-color: none !important;
}
.moveConstraintactionButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: row;
  min-width: 99%;
  margin-right: 10px;
  margin-top: 15px;
}
.moveConstraintactionButtons>Button {
  padding: 14px !important;
  margin: 5px !important;
  text-transform: none !important;
  padding: 10px;
}
.moveConstraintactionButtons>div.MuiFormControl-root {
  margin-top: 7px !important;
}