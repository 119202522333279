.newadjustmentaccordions {
  overflow: scroll;
  text-align: left;
}

.newadjustmentaccordions>div.MuiPaper-root{
  background: none !important;
}

.adjustmentaccordiondetails.MuiAccordionDetails-root {
  display: flex !important;
  flex-direction: row;
  padding: 8px 0px 16px;
}

.adjustmentAccordiondetails {
  display: flex;
  flex-direction: row;
  margin-top: -15px;
  margin-bottom: -15px;
  margin-left: 6px;
}


.adjustmentOverallAccordiondetails {
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  margin-left: 6px;
}

.adjustmentOverallAccordionContent {
  display: flex;
  flex-direction: row;
}

.adjustmentAccordiondetailsTitle.MuiAccordionDetails-root {
  display: flex !important;
  flex-direction: row;
  padding: 5px 0px 16px;
}

.adjustmentOverallAccordiondetailsTitle.MuiAccordionDetails-root {
  display: flex !important;
  flex-direction: row;
  padding: 8px 0px 16px;
}

.adjustmentOverallAccordiondetailsTitle {
  width : 140px;
}

.adjustmentAccordiondetailsTotal.MuiAccordionDetails-root {
  display: flex !important;
  flex-direction: column !important;
  padding: 8px 0px 16px !important;
}

.adjustmentAccordiondetailsTotal {
  display: flex !important;
  flex-direction: column;
  padding: 8px 0px 16px;
  margin-left: 5px;
}

.adjustmentAccordiondetailsContent.MuiAccordionDetails-root {
  display: flex;
  padding: 16px 11px 16px;
}

.adjustmentAccordiondetailsContent::-webkit-scrollbar {
  display: none;
}


.adjustmentcontent {
    display: flex;
    flex-direction: row;
    overflow: auto;
    width: 1000px;
    margin-top: -30px;
}


.adjustmentcontent::-webkit-scrollbar {
  display: none;
}

.typeicons {
  display: flex;
}

.typeiconstyle {
  height: 14px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  color: #434244;
  margin: 1px 6px 1px 1px;
}


.adjustmentaccordions>div.MuiAccordion-root {
  display: flex;
}

.adjustmentexpand>div.MuiAccordionSummary-expandIcon {
  transform: rotate(270deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.adjustmentexpand>div.MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg);
}

.adjustmentaccordions {
  display: inline-flex;
}

.adjustmentaccordions::-webkit-scrollbar {
  display: none;
}

.adjustmentexpand>div.MuiAccordionSummary-content {
  order: 0;
  min-width: 60px;
  margin: 6px 11px !important;
}

.applyMonthMargin>div:nth-child(1) {
  min-width: 100px;
}

.verticalAdjustmentAccordion {
  width: 8%;
  min-width: 8%;
  margin-left: 18px;
}

.adjustmentsview {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  padding: 0px 0px 0px 0px;
}

.adjustmentAlignment {
  /* display: flex; */
  font-size: 13px;
  flex-direction: column;
}

.accordionContent {
  display: flex;
}

.alignTitleContent {
  display: flex;
}

.alignTitle {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  margin-left: 10px;
  text-align: left;
}

.alignTotal {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  margin-left: 10px;
  text-align: left;
  background-color: #f3f2f3;
  border-radius: 3px;
}

.allBUAccordionTotal {
  display: flex !important;
  flex-direction: column !important;
  padding-right: 20px;
  font-size: 13px;
  width: 80px;
}

.alignContent {
  display: flex;
  text-align: left;
  flex-direction: column;
  margin-top: -20px;
  margin-left: 1.5%;
}

.alignColumnContent {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-left: 4%;
}

.totalCurrentValue {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  width: 80px;
  text-align: right;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 10px;
  color: #808285;
  
}

.allBuOverallDivider {
    width: 1147px !important;
    height: 1px;
    background: red;
    margin-left: 43px !important;
    margin-top: 0px !important;
    margin-bottom: 7px !important;
}

.allBuChildDivider {
    width: 1121px !important;
    height: 1px;
    background: red;
    margin-left: 43px !important;
    margin-top: 7px !important;
    margin-bottom: 7px !important;
}

.allBuOverallDivider>div.MuiDivider-root {
  background-color: #f3f2f3 !important;
}

.subDivider {
  width: 1000px !important;
  height: 1px;
  margin-top: 20px !important;
  margin-left: -100px !important;
}

.subDivider>div.MuiDivider-root {
  background-color: #f3f2f3 !important;
}

.allBuAdjustmentheading {
    min-width: 90px;
    width: 70px;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    color: #434244;
    display: inline-flex;
    margin-top: 3px;
}