.dialogbox>div.MuiDialog-container{ 
  height: 68% !important;
}
.dialogbox>div>div.MuiDialog-paperScrollPaper {
  max-height: none;
  width: 482px;
  height: 180px;
  width: 514px;
  height: 255px;
  padding: 12px 6px 12px 6px;
  border-radius: 5px;
  background-color: #ffffff;
}
.modelmfroem_dialogbox>div.MuiDialog-container{ 
height: 68% !important;
}
.modelmfroem_dialogbox>div>div.MuiDialog-paperScrollPaper {
max-height: none;
width: 482px;
height: 180px;
width: 514px;
height: 320px;
padding: 12px 6px 12px 6px;
border-radius: 5px;
background-color: #ffffff;
}
.dialogtitle>h2.MuiTypography-h6{
width: 100%; 
display: flex;
flex-wrap: wrap;
flex-direction: row;
justify-content: space-between;
}
.dialogtitle>h2.MuiTypography-h6>span:nth-child(1){
/* width: 121px;
height: 16px; */
margin: 0 175px 0 0;
font-size: 18px;
font-family: customFontFamily;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: 0.73;
letter-spacing: normal;
color: #434244;
}
.dialogtitle>h2.MuiTypography-h6>span:nth-child(2)>img.closeicon{
width: 16px;
height: 16px;
}
.dialogtitle>h2.MuiTypography-h6>span:nth-child(2){
margin-top: -6px;
}
.dialogcontent.MuiTypography-body1{
width: 356px;
height: 13px;
margin-top: 11px;
margin-bottom: 18px;
font-size: 16px !important;
font-weight: normal !important;
font-stretch: normal !important;
font-style: normal;
line-height: 1.3 !important;
letter-spacing: normal !important;
color: #434244 !important;
font-family: customFontFamily;
}
.actionbuttons>span>button.MuiButton-root{
  text-transform: capitalize !important;
}
.actionbuttons>button.cancelbutton{
padding: 14px !important;
margin: 5px !important;
border-radius: 4px;
border: solid 1.5px #808285;
background-color: #ffffff;
}
.actionbuttons>span>button.okbutton{
  padding: 14px !important;
  margin: 5px !important;
  /* border: solid 1.5px; */
  border-radius: 4px;
  background-color: #ffcc00;
}

.actionbuttons>span>button.buttonblur{
  padding: 14px !important;
  margin: 5px !important;
  /* border: solid 1.5px; */
  border-radius: 4px;
  background-color: #ffcc00;
  opacity: 0.7;
}

.actionbuttons>span>button>span.MuiButton-label{
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 0.75;
letter-spacing: normal;
text-align: center;
color: #434244;
}
.generateRfTextbox>div>div>input.MuiInputBase-input{
height: 2.1876em !important;
font-size: 18px !important;
padding-left: 8px !important;
}
