.adjustmentheader{
    width: 193px;
    height: 38px;
    margin: 30px 555px 30px 28px;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: -1px;
    color: #000000;
    margin-top: -16px;
    padding-top: 8px;
}
.adjustmentrun{
    display: flex;
}
.adjustmentmargin{
    margin-left: 17px;
    margin-top: 20px;
}
.adjustmentflexitems{
    display: flex !important;
    justify-content: space-between;
    margin: 10px;
}
.adjustmentrun>button.MuiButton-text{
    width: 180px;
    height: 25px;
    margin: 0 42px 0 0px;
    padding: 14px 2px 15px 2px !important;
    border-radius: 4px;
    background-color: #ffcc00;
    font-stretch: normal;
    font-style: normal;
    /* color: #434244; */
    font-size: 14px;
}
.runblur{
    filter: blur(0.4px);
}

/* // */

/* .mainAdjustmentHeader, .alignAdjustments {
    width: auto !important; 
}
.adjustmentmargin {
    margin-left: 0px;
}
.adjustmentrun {
    width: 25%;
}
.adjustmentsFilterStyle {
    min-width: 85%;
    justify-content: flex-start;
}
.AllBumainAdjustmentHeader {
    width: 92%;
}
.alignAllBuAdjustments {
    width: 91%;
    padding-right: 12px;
}

@media screen and (max-width: 1500px) {
    .adjustmentmargin {
        height: 750px;
    }
} */