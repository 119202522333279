body {
  margin: 0;
  font-family: customFontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: customFontFamily;
  src: local('customFontFamily'), url('./assets/customfont/Ride-Light.ttf');
}

@font-face {
  font-family: customFontFamilyRegular;
  src: local('customFontFamilyRegular'), url('./assets/customfont/Ride-Bold.ttf');
}
div{
  font-family: customFontFamily;
}
span{
  font-family: customFontFamily;
}
label{
  font-family: customFontFamily;
}
