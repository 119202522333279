/* tailwind  styles */
.mt-5{
  margin-top:20px ;
}
.ml-6{
  margin-left: 24px;
}
.-mt-3{
  margin-top: -12px;
}

/* custom styles */

.revenueheadertext{
  min-width: 25%;
  height: 38px;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: -1px;
  color: #000000;
 }
 .rffilterStyle {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  width: 74%;
}
.revFunctionHeader {
  background-color: white;
  margin-block-start: -36px !important;
  padding-bottom: 5px;
  color: white;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
}


.rfactionButtons>div:nth-child(1).Mui-focused {
  background-color: none !important;
}

.viewdropdown>div.MuiInputBase-input {
  padding: 0px 2px 7px 3px !important;
}

.viewdropdown>div.MuiSelect-select:focus {
  background-color: none !important;
}

.viewdropdown>svg.MuiSelect-icon {
  padding: 0px 2px 7px 2px !important;
}

.saveButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: row;
  min-width: 36%;
  margin-right: 10px;
  margin-top: 10px;
}

.rfactionButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
    min-width: 36%;
    margin-right: -10%;
    margin-top: 15px;
}

.filterMain {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 24px;
}

.rfactionButtons>Button {
  padding: 14px !important;
  margin: 5px !important;
  text-transform: none !important;
  padding: 10px;
}

.imageIcon {
  margin: 10px;
  width: 24px !important;
  height: 28px;
}

.MuiMenuItem-root {
  font-size: 13px !important;
}

.MuiSelect-selectMenu {
  width: 165px !important;
  border-radius: 3px;
  margin-top: 5px !important;
  padding: 5px !important;
}

.MuiFormControl-root {
  margin-right: 10px !important;
}

.MuiSvgIcon-root {
  padding-top: 3px !important;
  margin-top: 0px !important;
  padding-right: 5px;
}

.MuiSelect-select {
  /* width: 120px !important; */
  border-radius: 3px !important;
  /* border: 1px solid grey !important; */
  /* background: #ffcc00 !important;  */
}

.dropdownfields {
  display: flex;
  flex-direction: column;
  width: 176px;
  margin-top: 5px;
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
}

.MuiTypography-gutterBottom {
  margin-bottom: 0 !important;
}

.MuiMenuItem-root {
  font-family: customFontFamily !important;
  height: 28px;
  border-bottom: 1px solid lightgray;
}

.MuiInputBase-root {
  font-family: customFontFamily !important;
}

.MuiTypography-body1 {
  font-family: customFontFamily !important;
}

.muiDatePicker {
  margin-top: 0px !important;
  border: 1px solid #808285 !important;
  border-radius: 3px !important;
  padding-left: 5px !important;
  width: 165px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #808285 !important;
  border-radius: 3px !important;
  border-width: 1px !important;
}

.viewdropdown {
  width: 90px;
  height: 34px;
  margin: 0 0 0 18px;
  padding: 1px;
  border-radius: 5px;
  background-color: #ffcc00;
}

.rfactionButtons>div.MuiFormControl-root {
  margin-top: 7px !important;
}

.secondaryButton {
  border: solid 1px;
  border-radius: 5px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 12px;
  margin: 6px;
  padding-top: 2px;
  background-color: transparent !important;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.MuiButton-label {
  text-transform: none !important;
}

.searchbar {
  margin-right: 48%;
  margin-top: 8px;
}

.MuiDialog-paperWidthSm {
  max-width: 675px !important;
}

.imageSpacing {
  padding-right: 15px !important;
  min-width: 24px !important;
}

.MuiListItem-root.Mui-selected {
  background-color: transparent !important;
}

.actiobarPopOver>div.MuiPopover-paper {
  border-top-right-radius: 0px !important;
  box-shadow: -5px 10px 20px 0px rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff !important;
  overflow-y: hidden !important;
  margin-top: 0.7px !important;
}

div.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.MuiDivider-root {
  background-color: #f3f2f3;
}
.programsfilterMargin {
  margin:0px 10px 0px 10px;
}
.revFunctiontables{
  background-color: white;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 23px;
}  
/* styles to dropdown */
.dropdownfield{
  /* width: 42%; */
  height: 34px;
  margin: 1px 29px 29.5px 3px;
  padding: 5.3px 15px 5.3px 15.1px;
  border-radius: 4px;
  border: solid 1px #979797 !important;
}

.bottomMarginRemoved{
  margin-bottom: 0px;
}

.revFunctiontables>div>div>div.MuiSelect-select:focus{
  background-color: rgba(0,0,0,0)!important;
}
/* .revFunctiontables>div.MuiFormControl-root {
  margin-right: -8%!important;
} */
.headerDivider {
height: 34px;
border: solid 1px #979797;
}
.rfInputTabs{
display: flex;
margin-left: -1%;
width: 80%;
}
 .primaryButton {
  border: solid 1px;
  border-radius: 5px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 12px;
  margin: 6px;
  padding-top: 2px;
  background-color: #ffcc00;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.revFunctionHeader .rfInputTabs {
  width: 70%;
}
/* .MuiTableContainer-root {
width: auto !important;
} */
.MuiPagination-root .MuiPagination-ul{
/* display: none; */
}



/* cursor pointer for clickable options */
.cursorpoint{
  cursor:pointer;
}

.rfidlink{
  color:#006bbd;
}
.rfheaders{
  flex-wrap: wrap;
}
.display-grid{
  display: grid;
}
.rfleftmargin{
  margin-left: 3%;
}
.typelable{
  margin: 0 20px 6px 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.selecterfid{
  height: 24px;
  margin: 1px 31px 24px 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434244;
}
.rfdescriptionwidth>div:nth-child(2)>div:nth-child(1){
  width: 405px;
  height: 56px;
}
.rfcodestyle{
  margin-left: 17px;
  font-weight: bold;
}
.flexDirectionColumn{
  flex-direction: column;
}
.flexDirectionRow{
  flex-direction: row;
}
@media screen and (max-width: 3840px) {
  #grid-theme-wrapper{
    height: 60vh;
    /* border: 1px solid blue; */
  }  
  .margin #grid-theme-wrapper {
    height: 90vh;
    /* border: 1px solid green; */
  }  
  .planinputsmargin #grid-theme-wrapper {
    height: 83vh;
    /* border: 1px solid blue; */
  } 
  .planassumptionbgcolor #grid-theme-wrapper {
    height: 85vh;
    /* border: 1px solid green; */
  }
  .planinputsmargin .planassumptionbgcolor #grid-theme-wrapper {
    height: 80vh;
    /* border: 1px solid green; */
  }
  .adminpage #grid-theme-wrapper {
    height: 85vh;
    /* border: 1px solid blue; */
  }
  .revenue-adjust #grid-theme-wrapper{
    height: 88vh;
  }
}

@media screen and (max-width: 2560px) {
  #grid-theme-wrapper{
    height: 60vh;
    /* border: 1px solid blue; */
  }  
  .margin #grid-theme-wrapper {
    height: 85vh;
    /* border: 1px solid green; */
  }  
  .planinputsmargin #grid-theme-wrapper {
    height: 73vh;
    /* border: 1px solid blue; */
  } 
  .planassumptionbgcolor #grid-theme-wrapper {
    height: 77vh;
    /* border: 1px solid green; */
  }
  .planinputsmargin .planassumptionbgcolor #grid-theme-wrapper {
    height: 70vh;
    /* border: 1px solid green; */
  }
  .adminpage #grid-theme-wrapper {
    height: 80vh;
    /* border: 1px solid blue; */
  }
  .revenue-adjust #grid-theme-wrapper{
    height: 78vh;
  }
}

@media screen and (max-width: 1920px) {
  #grid-theme-wrapper{
    height: 60vh;
    /* border: 1px solid blue; */
  }  
  .margin #grid-theme-wrapper {
    height: 70vh;
    /* border: 1px solid green; */
  }  
  .planinputsmargin #grid-theme-wrapper {
    height: 60vh;
    /* border: 1px solid blue; */
  } 
  .planassumptionbgcolor #grid-theme-wrapper {
    height: 70vh;
    /* border: 1px solid green; */
  }
  .planinputsmargin .planassumptionbgcolor #grid-theme-wrapper {
    height: 60vh;
    /* border: 1px solid green; */
  }
  .adminpage #grid-theme-wrapper {
    height: 70vh;
    /* border: 1px solid blue; */
  }
  .revenue-adjust #grid-theme-wrapper{
    height: 70vh;
  }
}

@media screen and (max-width: 1600px) {
  #grid-theme-wrapper{
    height: 60vh;
    /* border: 1px solid blue; */
  }  
  .margin #grid-theme-wrapper {
    height: 70vh;
    /* border: 1px solid green; */
  }  
  .planinputsmargin #grid-theme-wrapper {
    height: 55vh;
    /* border: 1px solid blue; */
  } 
  .planassumptionbgcolor #grid-theme-wrapper {
    height: 67vh;
    /* border: 1px solid green; */
  }
  .planinputsmargin .planassumptionbgcolor #grid-theme-wrapper {
    height: 55vh;
    /* border: 1px solid green; */
  }
  .adminpage #grid-theme-wrapper {
    height: 65vh;
    /* border: 1px solid blue; */
  }
  .revenue-adjust #grid-theme-wrapper{
    height: 65vh;
  }
}
  
@media screen and (max-width: 1366px) {
  #grid-theme-wrapper{
    height: 55vh;
    /* border: 1px solid blue; */
  }  
  .margin #grid-theme-wrapper {
    height: 55vh;
    /* border: 1px solid green; */
  }  
  .planinputsmargin #grid-theme-wrapper {
    height: 35vh;
    /* border: 1px solid green; */
  }
  .planassumptionbgcolor #grid-theme-wrapper {
    height: 60vh;
    /* border: 1px solid green; */
  }
  .planinputsmargin .planassumptionbgcolor #grid-theme-wrapper {
    height: 35vh;
    /* border: 1px solid green; */
  }
  .adminpage #grid-theme-wrapper {
    height: 60vh;
    /* border: 1px solid green; */
  }
  .revenue-adjust #grid-theme-wrapper{
    height: 55vh;
    /* border: 1px solid green; */
  }
  /* .revenue-adjust #grid-theme-wrapper>div>div>div{
    height: 450px !important;
  } */
  
}

@media screen and (max-width: 1280px) {
  #grid-theme-wrapper{
    height: 55vh;
    /* border: 1px solid blue; */
  }
  .margin #grid-theme-wrapper {
    height: 55vh;
    /* border: 1px solid green; */
  }  
  .planinputsmargin #grid-theme-wrapper {
    height: 30vh;
    /* border: 1px solid green; */
  }
  .planassumptionbgcolor #grid-theme-wrapper {
    height: 55vh;
    /* border: 1px solid green; */
  }
  .planinputsmargin .planassumptionbgcolor #grid-theme-wrapper {
    height: 30vh;
    /* border: 1px solid green; */
  }
  .adminpage #grid-theme-wrapper {
    height: 55vh;
    /* border: 1px solid blue; */
  }
  .revenue-adjust #grid-theme-wrapper{
    height: 48vh;
  }

}
@media screen and (max-width: 1152px) {
  #grid-theme-wrapper{
    height: 55vh;
    /* border: 1px solid blue; */
  }  
  .planinputsmargin #grid-theme-wrapper {
    height: 33vh;
    /* border: 1px solid blue; */
  }
  .adminpage #grid-theme-wrapper {
    height: 60vh;
    /* border: 1px solid blue; */
  }
  .revenue-adjust #grid-theme-wrapper{
    height: 33vh;
  }
}
@media screen and (max-width: 1024px) {
  #grid-theme-wrapper{
    height: 55vh;
    /* border: 1px solid blue; */
  }  
  .planinputsmargin #grid-theme-wrapper {
    height: 33vh;
    /* border: 1px solid blue; */
  }
  .adminpage #grid-theme-wrapper {
    height: 50vh;
    /* border: 1px solid blue; */
  }
  .revenue-adjust #grid-theme-wrapper{
    height: 33vh;
  }
}

.revenue-adjust #grid-theme-wrapper>div>div {
  height: 90% !important;
  /* border: 1px solid red; */
}
.revenue-adjust #grid-theme-wrapper>div>div>div {
    height: 100% !important;
}