.muiDatePicker {
    margin-top: 0px !important;
    border: 1px solid #808285 !important;;
    border-radius: 3px !important;
    padding-left: 5px !important;
    width: 160px !important;
}

.calendarIcon {
    width: 22px;
    height: 20px;
    padding-right: 5px;
}

.rangeoption {
    width: 167px !important;
    min-width: 167px !important;
}
.pickermarging{
    margin-left: 8px;
}

.dropdownStyle {
    /* border: 1px solid #cccdd0 !important; */
    border-radius: 3px;
    height: 30px;
    min-width: 134px !important;
    width: 158px;
}
.dropdownStyle>div>div.MuiSelect-select.MuiSelect-select{
    padding: 1px 2px 1px 8px !important;
    height: 18px;
    font-size: 14px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #434244;
}
.andtext{
    height: 19.5px;
    margin: 8px 11px 8.5px 6px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #434244;
}
.MuiDialogContent-root{
    overflow-x: hidden !important;
}
.rangeoption>p{
    width: 254px;
    height: 18px;
    margin: 0 26px 5px 0;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #808285;
}
