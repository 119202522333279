.metadatatabs{
    display: flex;
}
.metadatabgcolor{
    background-color:#ffffff;
    /* height: 650px; */
    min-width: 1000px;
    width: 1110px;
}
.metadatatabs>div>div:nth-child(3)>div>button{
    margin: 0 0 5px 12px !important;
}

.metadatatabs>div>div:nth-child(3)>div>button:active{
    border-radius: 4px !important;
    background-color: #ffed9b !important;
    color: #000000;
}

.metadatatabpanel{
    min-width: 942px;
    margin-top: -35px;
}
.metadatatabpanel>div>p.MuiTypography-body1{
    width:917px;
}
.metadatatabs>div.MuiTabs-vertical{
    min-width: 18%;
    /* height: 528px; */
    height: fit-content;
    margin-top: -91px;
    margin-left: -7px;
}

/* */
.metadatabgcolor {
    background-color: #ffffff;
    /* height: 650px; */
    min-width: inherit;
    width: auto;
}
.metadatatabpanel {
    min-width: 82%;
    margin-top: -35px;
    width: 100%;
}
.metadatatabpanel>div>p.MuiTypography-body1 {
    width: auto;
}
.metadatamainHeader {
    min-width: auto !important;
    width: auto !important;
}
.adminpage {
    margin-right: 7px;
  }
  .metadataactionButtons {
    min-width: 50% !important;
  }

.adminpage #grid-theme-wrapper>div>div {
    height: 100% !important;
    /* border: 1px solid red; */
}
.adminpage #grid-theme-wrapper>div>div>div {
    height: 100% !important;
}

@media screen and (max-width: 4000px) {
    /* #grid-theme-wrapper>div>div>div{
        height: 1300px !important;
    } */
    /* .adminpage {
        height: 1600px !important;
    } */
    /* .metadatabgcolor {
        height: 1400px;
    } */
}

@media screen and (max-width: 2600px) {
    /* #grid-theme-wrapper>div>div>div{
        height: 950px !important;
    } */
    /* .adminpage {
        height: 1200px !important;
    } */
    /* .metadatabgcolor {
        height: 1050px;
    } */

}
@media screen and (max-width: 1500px) {
    /* #grid-theme-wrapper>div>div>div{
        height: 650px !important;
    } */
    /* .adminpage {
        height: 920px !important;
    } */
    /* .metadatabgcolor {
        height: 730px;
    } */
}